import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PostHogProvider } from "posthog-js/react";
import store from "./store";
import { getPostHog } from "./posthog";
import App from "./App";
import "./styles/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PostHogProvider client={getPostHog()}>
      <Provider store={store}>
        <App />
      </Provider>
    </PostHogProvider>
  </React.StrictMode>
);
