import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button} from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import WaveSurfer from 'wavesurfer.js';

let updatedStartTime = 0;

const useWavesurfer = (containerRef, options, startTime, dragSelection) => {
    let isWaveSurferInitialized = false;
    const [wavesurfer, setWavesurfer] = useState(null)
    useEffect(() => {
        if (!containerRef.current || isWaveSurferInitialized) return
        const ws = WaveSurfer.create({
            ...options,
            container: containerRef.current,
        })	
        isWaveSurferInitialized = true;
        ws.on('ready', () => {
            ws.setTime(startTime)
            updatedStartTime = startTime;
        })
        setWavesurfer(ws)
        /* return () => {
            ws.destroy()
        } */
    }, [])
    return wavesurfer
}

const WaveSurferPlayer = React.forwardRef(({ onReadyStatus, onCurrentTimeUpdate, onPlayStatusUpdate, ...props }, ref) => {
    const {t} = useTranslation();
    const [currentTime, setCurrentTime] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const containerRef = useRef()
    const wavesurfer = useWavesurfer(containerRef, props, props.startTime, props.dragSelection)
    const onPlayClick = useCallback(() => {
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const play = useCallback(() => {
        wavesurfer.play();
    }, [wavesurfer]);
    const pause = useCallback(() => {
        wavesurfer.pause();
    }, [wavesurfer]);
    const setTime = useCallback(() => {
        wavesurfer.setTime();
    }, [wavesurfer]);
    useEffect(() => {
        if (!wavesurfer) return
        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
            wavesurfer.on('ready', () => setIsReady(true)),
        ]
        if (wavesurfer) {
            ref.current = {
                play: () => wavesurfer.play(),
                pause: () => wavesurfer.pause(),
                setTime: (time) => wavesurfer.setTime(time),
            };
        }
        wavesurfer.on('timeupdate', (time) => {
            setCurrentTime(time);
            onCurrentTimeUpdate(time);
        });
        wavesurfer.on('play', () => {
            onPlayStatusUpdate(true);
        });
        wavesurfer.on('pause', () => {
            onPlayStatusUpdate(false);
        });
        wavesurfer.on('ready', () => {
            setIsReady(true);
            onReadyStatus(true);
        })
        return () => {
            subscriptions.forEach((unsub) => unsub())
        }
    }, [wavesurfer, ref, updatedStartTime])


    return (
        <div className={isReady ? "wavesurferplayer p-relative wsp-ready" : "wavesurferplayer p-relative wsp-not-ready"}>
            <div ref={containerRef} style={{ minHeight: (props.height)+'px'}} className='sound-wave'/>
            {isReady === true ? null :
                <>
                    <div className='d-flex justify-content-center wsp-loading-container'>
                        <div className='wsp-sound-icon'>
                            <div className='wsp-sound-wave'>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
});

export default WaveSurferPlayer;
//export default React.memo(WaveSurferPlayer);

