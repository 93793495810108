import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js'

let updatedStartTime = 0;
let trackDuration = 0;
const useWavesurfer = (containerRef, options, startTime, dragSelection) => {
    let isWaveSurferInitialized = false;
    const [wavesurfer, setWavesurfer] = useState(null)
    useEffect(() => {
        if (!containerRef.current || isWaveSurferInitialized) return
        const ws = WaveSurfer.create({
            ...options,
            container: containerRef.current,
        })
        isWaveSurferInitialized = true;
        const wsRegions = ws.registerPlugin(RegionsPlugin.create())
        ws.on('decode', () => {
            wsRegions.addRegion({
                start: startTime,
                end: ws.getDuration() > 30 ? startTime + 30 : ws.getDuration(),
                content: '',
                color: 'rgba(255, 255, 255, 0.1)',
                resize: false,
                drag: dragSelection ? true : false,
            })
        })
        ws.once('decode', () => {
            //const minPxPerSec = ws.getDuration() > 300 ? 2 : 1
            const minPxPerSec = 1
            ws.zoom(minPxPerSec)
        })
        ws.on('ready', () => {
            ws.setTime(startTime)
            updatedStartTime = startTime;
            trackDuration = ws.getDuration();
            
        })
        wsRegions.on('region-updated', (region) => {
            ws.setTime(region.start)
            startTime = region.start;
            updatedStartTime = startTime;
        })
        ws.on('timeupdate', (currentTime) => {
            if (currentTime >= startTime + 30) {
                ws.pause()
                ws.setTime(startTime)
            }
        })
        setWavesurfer(ws)
        /* return () => {
            ws.destroy()
        } */
    }, [])
    return wavesurfer
}

const WaveSurferPlayer = React.forwardRef(({ onCurrentTimeUpdate, onPlayStatusUpdate, onRegionStartUpdate, onTrackDuration, ...props }, ref) => {
    const { t } = useTranslation();
    const [currentTime, setCurrentTime] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const containerRef = useRef()
    const wavesurfer = useWavesurfer(containerRef, props, props.startTime, props.dragSelection)
    const onPlayClick = useCallback(() => {
        wavesurfer.isPlaying() ? wavesurfer.pause() : wavesurfer.play()
    }, [wavesurfer])
    const play = useCallback(() => {
        wavesurfer.play();
    }, [wavesurfer]);
    const pause = useCallback(() => {
        wavesurfer.pause();
    }, [wavesurfer]);
    const setTime = useCallback(() => {
        wavesurfer.setTime();
    }, [wavesurfer]);
    useEffect(() => {
        if (!wavesurfer) return
        const subscriptions = [
            wavesurfer.on('play', () => setIsPlaying(true)),
            wavesurfer.on('pause', () => setIsPlaying(false)),
            wavesurfer.on('timeupdate', (currentTime) => setCurrentTime(currentTime)),
        ]
        if (wavesurfer) {
            ref.current = {
                play: () => wavesurfer.play(),
                pause: () => wavesurfer.pause(),
                setTime: (time) => wavesurfer.setTime(time),
            };
        }
        wavesurfer.on('timeupdate', (time) => {
            setCurrentTime(time);
            onCurrentTimeUpdate(time);
            onRegionStartUpdate(updatedStartTime);
        });
        wavesurfer.on('play', () => {
            onPlayStatusUpdate(true);
        });
        wavesurfer.on('pause', () => {
            onPlayStatusUpdate(false);
        });
        wavesurfer.on('finish', () => {
            wavesurfer.play();
        });
        wavesurfer.on('ready', () => {
            setIsReady(true);
            onTrackDuration(wavesurfer.getDuration());
        })
        return () => {
            subscriptions.forEach((unsub) => unsub())
        }
    }, [wavesurfer, ref, updatedStartTime])

    const loadingText = t('LOADING');
    const trackText = t('TRACKS');
    const renderText = text => text.split('').map((letter, index) => (
        <span key={index} className="wsp-blink">{letter}</span>
    ));

    return (
        <div className={isReady ? "wavesurferplayer wsp-ready" : "wavesurferplayer wsp-not-ready"}>
            <div ref={containerRef} style={{ minHeight: (props.height + 20) + 'px' }} className='sound-wave' />
            <div className='d-flex justify-content-center sound-play-pause'>
                <Button variant="link" onClick={onPlayClick} >
                    {isPlaying ? <img src='/icons/pause-circle.svg' alt='Mastering' className='icon icon-mastering' /> : <img src='/icons/play-circle.svg' alt='Mastering' className='icon icon-mastering' />}
                </Button>
            </div>
            <div className='d-flex justify-content-center sound-text'>
                <p className='text-center mb-0 no-wrap'>{t('Playing cropped {{currentTime}}s to {{updatedStartTime}}s', { currentTime: currentTime.toFixed(1), updatedStartTime: trackDuration > 30 ? (updatedStartTime + 30).toFixed(1) : trackDuration.toFixed(1) })}</p>
            </div>
            {isReady === true ? null :
                <>
                    <div className='d-flex justify-content-center wsp-loading-container'>
                        <div className='wsp-sound-icon'>
                            <div className='wsp-sound-wave'>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                                <i className='wsp-bar'></i>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center wsp-strow-container'>
                        <div className="loadingAnimation">
                            {renderText(loadingText)}
                        </div>
                        <div className="loadingAnimation la-mini">
                            {renderText(trackText)}
                        </div>
                    </div>
                </>
            }
        </div>
    )
});

export default WaveSurferPlayer;
//export default React.memo(WaveSurferPlayer);

