import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    MAIL_CONFIRMATION_REQUEST,
    MAIL_CONFIRMATION_SUCCESS,
    MAIL_CONFIRMATION_FAIL,

    RESEND_MAIL_CONFIRMATION_REQUEST,
    RESEND_MAIL_CONFIRMATION_SUCCESS,
    RESEND_MAIL_CONFIRMATION_FAIL,

    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_FORGOT_PASSWORD_FAIL,

    PASSWORD_CONFIRM_RESET_REQUEST,
    PASSWORD_CONFIRM_RESET_SUCCESS,
    PASSWORD_CONFIRM_RESET_FAIL,

    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    CHECK_TOKEN_VALID_REQUEST,
    CHECK_TOKEN_VALID_SUCCESS,
    CHECK_TOKEN_VALID_FAIL,
    CHECK_TOKEN_VALID_RESET,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,

    UPDATE_USER_DETAILS_REQUEST,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAIL,
    UPDATE_USER_DETAILS_RESET,

    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,
    UPDATE_USER_PASSWORD_RESET,

    USER_DELETE_ACCOUNT_REQUEST,
    USER_DELETE_ACCOUNT_SUCCESS,
    USER_DELETE_ACCOUNT_FAIL,
    USER_DELETE_ACCOUNT_RESET,

    CREATE_CHECKOUT_SESSION_REQUEST,
    CREATE_CHECKOUT_SESSION_SUCCESS,
    CREATE_CHECKOUT_SESSION_FAIL,
    CREATE_CHECKOUT_SESSION_RESET,

    UPLOAD_MUSIC_REQUEST,
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAIL,
    UPLOAD_MUSIC_RESET,

    UPLOAD_TRACK_NEW_REQUEST,
    UPLOAD_TRACK_NEW_SUCCESS,
    UPLOAD_TRACK_NEW_FAIL,
    UPLOAD_TRACK_NEW_RESET,

    S3_UPLOAD_REQUEST,
    S3_UPLOAD_SUCCESS,
    S3_UPLOAD_FAIL,
    S3_UPLOAD_RESET,

    RECEIVER_UPLOAD_NEW_REQUEST,
    RECEIVER_UPLOAD_NEW_SUCCESS,
    RECEIVER_UPLOAD_NEW_FAIL,
    RECEIVER_UPLOAD_NEW_RESET,

    CELERY_STATUS_REQUEST,
    CELERY_STATUS_SUCCESS,
    CELERY_STATUS_FAIL,
    CELERY_STATUS_RESET,

    MUSICIAN_TRACK_UPDATE_REQUEST,
    MUSICIAN_TRACK_UPDATE_SUCCESS,
    MUSICIAN_TRACK_UPDATE_FAIL,
    MUSICIAN_TRACK_UPDATE_RESET,

    CROP_TRACK_REQUEST,
    CROP_TRACK_SUCCESS,
    CROP_TRACK_FAIL,
    CROP_TRACK_RESET,

    MASTER30_TRACK_REQUEST,
    MASTER30_TRACK_SUCCESS,
    MASTER30_TRACK_FAIL,
    MASTER30_TRACK_RESET,

    MASTER30_STATUS_REQUEST,
    MASTER30_STATUS_SUCCESS,
    MASTER30_STATUS_FAIL,
    MASTER30_STATUS_RESET,
    
    MASTERMAIN_TRACK_REQUEST,
    MASTERMAIN_TRACK_SUCCESS,
    MASTERMAIN_TRACK_FAIL,
    MASTERMAIN_TRACK_RESET,

    MAINMASTER_STATUS_REQUEST,
    MAINMASTER_STATUS_SUCCESS,
    MAINMASTER_STATUS_FAIL,
    MAINMASTER_STATUS_RESET,

    MUSICIAN_TRACK_LIST_REQUEST,
    MUSICIAN_TRACK_LIST_SUCCESS,
    MUSICIAN_TRACK_LIST_FAIL,
    MUSICIAN_TRACK_LIST_RESET,

    MUSICIAN_TRACK_DETAILS_REQUEST,
    MUSICIAN_TRACK_DETAILS_SUCCESS,
    MUSICIAN_TRACK_DETAILS_FAIL,
    MUSICIAN_TRACK_DETAILS_RESET,

    MUSICIAN_TRACK_QUICK_REQUEST,
    MUSICIAN_TRACK_QUICK_SUCCESS,
    MUSICIAN_TRACK_QUICK_FAIL,
    MUSICIAN_TRACK_QUICK_RESET,

    ORIGINAL_TRACK_DELETE_REQUEST,
    ORIGINAL_TRACK_DELETE_SUCCESS,
    ORIGINAL_TRACK_DELETE_FAIL,
    ORIGINAL_TRACK_DELETE_RESET,

    MASTERED_TRACK_DELETE_REQUEST,
    MASTERED_TRACK_DELETE_SUCCESS,
    MASTERED_TRACK_DELETE_FAIL,
    MASTERED_TRACK_DELETE_RESET,

} from '../constants/index'


// Register
export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                userInfo: {},
                error: ""
            }
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                userInfo: action.payload,
                error: ""
            }
        case USER_REGISTER_FAIL:
            return {
                loading: false,
                success: false,
                userInfo: {},
                error: action.payload
            }
        default:
            return state
    }
}

// Mail Confirmation reducer
export const mailConfirmationReducer = (state = {}, action) => {
    switch (action.type) {
        case MAIL_CONFIRMATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                message: "",
                error: ""
            }
        case MAIL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload,
                error: ""
            }
        case MAIL_CONFIRMATION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                error: action.payload
            }
        default:
            return state
    }
}

// Resend Mail Confirmation reducer
export const resendMailConfirmationReducer = (state = {}, action) => {
    switch (action.type) {
        case RESEND_MAIL_CONFIRMATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                message: "",
                error: ""
            }
        case RESEND_MAIL_CONFIRMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload,
                error: ""
            }
        case RESEND_MAIL_CONFIRMATION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                error: action.payload
            }
        default:
            return state
    }
}

// Forgot Password reducer
export const userForgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                message: "",
                error: ""
            }
        case USER_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload,
                error: ""
            }
        case USER_FORGOT_PASSWORD_FAIL:
            return {
                loading: false,
                success: false,
                message: "",
                error: action.payload
            }
        default:
            return state
    }
}

// Password Confirm Reset reducer
export const passwordConfirmResetReducer = (state = {}, action) => {
    switch (action.type) {
        case PASSWORD_CONFIRM_RESET_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                message: "",
                error: ""
            }
        case PASSWORD_CONFIRM_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                message: action.payload,
                error: ""
            }
        case PASSWORD_CONFIRM_RESET_FAIL:
            return {
                loading: false,
                success: false,
                message: "",
                error: action.payload
            }
        default:
            return state
    }
}

// Login reducer
export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfo: action.payload
            }
        case USER_LOGIN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

// check token validation reducer
export const checkTokenValidationReducer = (state = {}, action) => {
    switch(action.type) {
        case CHECK_TOKEN_VALID_REQUEST:
            return {
                ...state, 
                loading: true,
                valid: false,
                error: ""
            }
        case CHECK_TOKEN_VALID_SUCCESS:
            return {
                ...state, 
                loading: false,
                valid: true,
                error: ""
            }
        case CHECK_TOKEN_VALID_FAIL:
            return {
                ...state,
                loading: false,
                valid: false,
                error: action.payload
            }
        case CHECK_TOKEN_VALID_RESET:
            return {
                ...state,
                loading: false,
                valid: false,
                error: ""
            }
        default:
            return state
    }
}

// user details reducer
export const userDetailsReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_DETAILS_REQUEST:
            return {
                ...state, 
                loading: true,
                user: {},
                error: ""
            }
        case USER_DETAILS_SUCCESS:
            return {
                ...state, 
                loading: false,
                user: action.payload,
                error: ""
            }
        case USER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                user: {},
                error: action.payload
            }
        default:
            return state
    }
}

// user details update reducer
export const userDetailsUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_USER_DETAILS_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                user: {},
                error: ""
            }
        case UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                user: action.payload,
                error: ""
            }
        case UPDATE_USER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                user: {},
                error: action.payload
            }
        case UPDATE_USER_DETAILS_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                user: {},
                error: ""
            }
        default:
            return state
    }
}

// user password update reducer
export const userPasswordUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case UPDATE_USER_PASSWORD_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                error: ""
            }
        case UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                error: ""
            }
        case UPDATE_USER_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case UPDATE_USER_PASSWORD_RESET:
            return {
                ...state,
                loading: false,
                success: false,
                error: ""
            }
        default:
            return state
    }
}

// user delete account reducer
export const userDeleteAccountReducer = (state = {}, action) => {
    switch(action.type) {
        case USER_DELETE_ACCOUNT_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                error: ""
            }
        case USER_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                error: ""
            }
        case USER_DELETE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case USER_DELETE_ACCOUNT_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                error: ""
            }
        default:
            return state
    }
}

// create checkout session reducer
export const createCheckoutSessionReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_CHECKOUT_SESSION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                session: {},
                error: ""
            }
        case CREATE_CHECKOUT_SESSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                session: action.payload,
                error: ""
            }
        case CREATE_CHECKOUT_SESSION_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                session: {},
                error: action.payload
            }
        case CREATE_CHECKOUT_SESSION_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                session: {},
                error: ""
            }
        default:
            return state
    }
}

// upload music reducer
export const uploadMusicReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_MUSIC_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case UPLOAD_MUSIC_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case UPLOAD_MUSIC_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case UPLOAD_MUSIC_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: action.payload
            }
        default:
            return state
    }
}

// upload track new reducer
export const uploadTrackNewReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_TRACK_NEW_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case UPLOAD_TRACK_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case UPLOAD_TRACK_NEW_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case UPLOAD_TRACK_NEW_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: action.payload
            }
        default:
            return state
    }
}

// s3 upload reducer
export const s3UploadReducer = (state = {}, action) => {
    switch (action.type) {
        case S3_UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                data: {},
                error: ""
            }
        case S3_UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: ""
            }
        case S3_UPLOAD_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                data: {},
                error: "error"
            }
        case S3_UPLOAD_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                data: {},
                error: action.payload
            }
        default:
            return state
    }
}

// receiver upload new reducer
export const receiverUploadNewReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVER_UPLOAD_NEW_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                data: {},
                error: ""
            }
        case RECEIVER_UPLOAD_NEW_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: ""
            }
        case RECEIVER_UPLOAD_NEW_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                data: {},
                error: action.payload
            }
        case RECEIVER_UPLOAD_NEW_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                data: {},
                error: action.payload
            }
        default:
            return state
    }
}

// celery status reducer
export const celeryStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case CELERY_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                status: {},
                error: ""
            }
        case CELERY_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                status: action.payload,
                /* status: {
                    "status": "completed"
                    }, */ //cfggeçici
                error: ""
            }
        case CELERY_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                status: {},
                error: action.payload
            }
        case CELERY_STATUS_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                status: {},
                error: ""
            }
        default:
            return state
    }
}

// musician track update reducer
export const musicianTrackUpdateReducer = (state = {}, action) => {
    switch(action.type) {
        case MUSICIAN_TRACK_UPDATE_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                error: ""
            }
        case MUSICIAN_TRACK_UPDATE_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                error: ""
            }
        case MUSICIAN_TRACK_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case MUSICIAN_TRACK_UPDATE_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                error: ""
            }
        default:
            return state
    }
}

// select 30 seconds of music reducer
export const cropTrackReducer = (state = {}, action) => {
    switch (action.type) {
        case CROP_TRACK_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case CROP_TRACK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case CROP_TRACK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case CROP_TRACK_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: action.payload
            }
        default:
            return state
    }
}

// master-30 reducer
export const master30TrackReducer = (state = {}, action) => {
    switch (action.type) {
        case MASTER30_TRACK_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case MASTER30_TRACK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                music: action.payload,
                error: ""
                /* music: {
                    "message": "Track processed successfully",
                    "track_id": 29,
                    "track_url": "https://diktator-demo.s3.amazonaws.com/deneme1%40deneme1.net/30_seconds/sample-15s.mp3_30s_b6697254-25d4-4cc7-ad48-a862466b6014.mp3"
                },
                error: {
                    "message": "Track processed successfully",
                    "track_id": 29,
                    "track_url": "https://diktator-demo.s3.amazonaws.com/deneme1%40deneme1.net/30_seconds/sample-15s.mp3_30s_b6697254-25d4-4cc7-ad48-a862466b6014.mp3"
                }  // cfggeçici */
            }
        case MASTER30_TRACK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case MASTER30_TRACK_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: ""
            }
        default:
            return state
    }
}

// master-30 status reducer
export const master30StatusReducer = (state = {}, action) => {
    switch (action.type) {
        case MASTER30_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                status: {},
                error: ""
            }
        case MASTER30_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                status: action.payload,
                /* status: {
                    "status": "processed"
                    }, */ //cfggeçici
                error: ""
            }
        case MASTER30_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                status: {},
                error: action.payload
            }
        case MASTER30_STATUS_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                status: {},
                error: ""
            }
        default:
            return state
    }
}

// master-main reducer
export const masterMainTrackReducer = (state = {}, action) => {
    switch (action.type) {
        case MASTERMAIN_TRACK_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case MASTERMAIN_TRACK_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case MASTERMAIN_TRACK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case MASTERMAIN_TRACK_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: ""
            }
        default:
            return state
    }
}

// master-main status reducer
export const masterMainStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case MAINMASTER_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                status: {},
                error: ""
            }
        case MAINMASTER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                status: action.payload,
                /* status: {
                    "status": "processed"
                    }, */ //cfggeçici
                error: ""
            }
        case MAINMASTER_STATUS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                status: {},
                error: action.payload
            }
        case MAINMASTER_STATUS_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                status: {},
                error: ""
            }
        default:
            return state
    }
}

// musician track list reducer
export const musicianTrackListReducer = (state = {musics: []}, action) => {
    switch(action.type) {
        case MUSICIAN_TRACK_LIST_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                musics: [],
                error: ""
            }
        case MUSICIAN_TRACK_LIST_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                musics: action.payload,
                error: ""
            }
        case MUSICIAN_TRACK_LIST_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                musics: [],
                error: action.payload
            }
        case MUSICIAN_TRACK_LIST_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                musics: [],
                error: ""
            }
        default:
            return state
    }
}

// musician track details reducer
export const musicianTrackDetailsReducer = (state = {music: {}}, action) => {
    switch(action.type) {
        case MUSICIAN_TRACK_DETAILS_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case MUSICIAN_TRACK_DETAILS_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case MUSICIAN_TRACK_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case MUSICIAN_TRACK_DETAILS_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: ""
            }
        default:
            return state
    }
}

// musician track quick reducer
export const musicianTrackQuickReducer = (state = {music: {}}, action) => {
    switch(action.type) {
        case MUSICIAN_TRACK_QUICK_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                music: {},
                error: ""
            }
        case MUSICIAN_TRACK_QUICK_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                music: action.payload,
                error: ""
            }
        case MUSICIAN_TRACK_QUICK_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                music: {},
                error: action.payload
            }
        case MUSICIAN_TRACK_QUICK_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                music: {},
                error: ""
            }
        default:
            return state
    }
}

// original track delete reducer
export const originalTrackDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case ORIGINAL_TRACK_DELETE_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                error: ""
            }
        case ORIGINAL_TRACK_DELETE_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                error: ""
            }
        case ORIGINAL_TRACK_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case ORIGINAL_TRACK_DELETE_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                error: ""
            }
        default:
            return state
    }
}

// mastered track delete reducer
export const masteredTrackDeleteReducer = (state = {}, action) => {
    switch(action.type) {
        case MASTERED_TRACK_DELETE_REQUEST:
            return {
                ...state, 
                loading: true,
                success: false,
                error: ""
            }
        case MASTERED_TRACK_DELETE_SUCCESS:
            return {
                ...state, 
                loading: false,
                success: true,
                error: ""
            }
        case MASTERED_TRACK_DELETE_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        case MASTERED_TRACK_DELETE_RESET:
            return {
                ...state,
                loading: undefined,
                success: undefined,
                error: ""
            }
        default:
            return state
    }
}
