import { useTranslation } from "react-i18next";

function PriceCard({ 
    plan, 
    title, 
    price, 
    pricePer,
    creditsIncluded, 
    description, 
    savePercent, 
    bestValue, 
    isSelected, 
    onSelect, 
    quantitySelector, 
    quantity, 
    onIncrement, 
    onDecrement 
}) {

    const { t } = useTranslation();

    return (
        <div className={`price-card ${isSelected ? 'selected' : ''}`} onClick={() => onSelect(plan)}>
            { bestValue ?
                <div className="selected-tag">{t('BEST DEAL')}</div>
                : isSelected ?
                    <div className="selected-tag">{t('SELECTED')}</div>
                    : null
            }
            <div className="price-card-content">
                <div className="price-card-header">{title}</div>
                <div className="price-card-price">${price} <span>/ {pricePer}</span></div>
                <ul className="price-card-features">
                    <li>{creditsIncluded}</li>
                    <li>{description}</li>
                </ul>
            </div>
                {quantitySelector ?
                    <div className="price-card-bottom pcb-btn">
                        <div className="quantity-selector">
                            <button onClick={onDecrement} disabled={quantity <= 1}>-</button>
                            <span>{quantity} {quantity > 0 ? t('Credits') : t('Credit')}</span>
                            <button onClick={onIncrement} disabled={quantity >= 99}>+</button>
                        </div>
                    </div>
                    : savePercent > 0 ?
                        <div className="price-card-bottom">
                            <div className="save-percent">{t('SAVE')} {savePercent}%</div>
                        </div>
                        : null
            }
        </div>
    );
}

export default PriceCard;