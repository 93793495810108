import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { userDetails, logout, checkTokenValidation, musicianTrackList, musicianTrackDetails, originalTrackDelete, masteredTrackDelete } from '../actions/userActions'
import { Card, Button, Row, Col, Accordion, Dropdown, Spinner, Modal } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import WaveSurferPlayer from '../components/WaveSurferMiniPlayer';
import { MUSICIAN_TRACK_DETAILS_RESET, MUSICIAN_TRACK_LIST_RESET, ORIGINAL_TRACK_DELETE_RESET, MASTERED_TRACK_DELETE_RESET, MASTER30_STATUS_RESET , MAINMASTER_STATUS_RESET} from '../constants'
import useAuth from '../hooks/useAuth';
import { use } from 'i18next';

function MyTracksPage() {

    useEffect(() => {
        document.title = "My Tracks - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userAccDetails, userAccError } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);



    const [data, setData] = useState([]);
    const resetComponentState = () => {
        setData([]);
        localStorage.removeItem('Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA');
        dispatch({ type: MUSICIAN_TRACK_LIST_RESET });
        dispatch({ type: MUSICIAN_TRACK_DETAILS_RESET });
        dispatch({ type: ORIGINAL_TRACK_DELETE_RESET });
        dispatch({ type: MASTERED_TRACK_DELETE_RESET });
        dispatch({ type: MASTER30_STATUS_RESET });
        dispatch({ type: MAINMASTER_STATUS_RESET });
        setShowNotification(false);
    };
    useEffect(() => {
        resetComponentState();
        return resetComponentState;
    }, []);



    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);



    const [DeleteOriginalModal, setDeleteOriginalModal] = useState(false);
    const handleDeleteOriginalModalClose = () => setDeleteOriginalModal(false);
    const handleDeleteOriginalModalShow = () => setDeleteOriginalModal(true);
    const [DeleteMasteredModal, setDeleteMasteredModal] = useState(false);
    const handleDeleteMasteredModalClose = () => setDeleteMasteredModal(false);
    const handleMasteredDeleteModalShow = () => setDeleteMasteredModal(true);



    const [activeKey, setActiveKey] = useState(null);



    // musician track list reducer
    const musicianTrackListReducer = useSelector(state => state.musicianTrackListReducer)
    const { musics: musicsList, loading: musicsListLoading, success: musicsListSuccess, error: musicsListError } = musicianTrackListReducer
    const [readyForMucisList, setReadyForMucisList] = useState(false);
    useEffect(() => {
        if (userAccDetails?.total_uploads > 0 && !musicsListLoading && !musicsListSuccess) {
            setReadyForMucisList(true);
        }
    }, [userAccDetails])
    useEffect(() => {
        if (readyForMucisList) {
            dispatch(musicianTrackList())
        }
    }, [readyForMucisList])
    useEffect(() => {
        if (musicsListSuccess && musicsList.length > 0) {
            setActiveKey(musicsList[0].id)
        } else if (musicsListError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(musicsListError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [musicsListSuccess, musicsListError])



    // musician track details reducer
    const musicianTrackDetailsReducer = useSelector(state => state.musicianTrackDetailsReducer)
    const { music: musicDetails, loading: musicDetailsLoading, success: musicDetailsSuccess, error: musicDetailsError } = musicianTrackDetailsReducer
    const [musicianTrackDetailsFirstTime, setMusicianTrackDetailsFirstTime] = useState(true);
    useEffect(() => {
        if (musicsListSuccess && musicsList.length > 0 && activeKey !== null && musicianTrackDetailsFirstTime) {
            setMusicianTrackDetailsFirstTime(false);
        } else if (musicDetailsError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(musicDetailsError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [musicsListSuccess, musicDetailsError, activeKey])
    useEffect(() => {
        if (musicsListSuccess && musicsList.length > 0 && activeKey !== null && !musicianTrackDetailsFirstTime) {
            dispatch(musicianTrackDetails(activeKey))
        }
    }, [musicianTrackDetailsFirstTime])


    const handleAccordionClick = (key) => {
        if (key !== activeKey) {
            setOriginalFilePreviewUrl(null);
            setMasteredFilePreviewUrl(null);
            setActiveKey(key);
            setMusicianTrackDetailsFirstTime(true);
            return;
        }
    };



    const startTime = 0.001;
    const [currentTime, setCurrentTime] = useState(0);



    const [playStatus1, setPlayStatus1] = useState(false);
    const [readyStatus1, setReadyStatus1] = useState(false);



    const waveSurferPlayerRef1 = useRef([null]);
    const handleCurrentTimeUpdate1 = (time) => {
        setCurrentTime(time);
    };
    const handlePlayStatusUpdate1 = (status) => {
        setPlayStatus1(status);
    };
    const handleReadyStatus1 = (status) => {
        setTimeout(() => {
            setReadyStatus1(status);
        }, 500);
    };
    const handlePlayClick1 = () => {
        waveSurferPlayerRef1.current.setTime(currentTime);
        waveSurferPlayerRef1.current.play();
        // if waveSurferPlayerRef2 is exist, pause it
        if (waveSurferPlayerRef2.current[0] !== null) {
            waveSurferPlayerRef2.current.pause();
        }

    };
    const handlePauseClick1 = () => {
        waveSurferPlayerRef1.current.pause();
    };

    const [playStatus2, setPlayStatus2] = useState(false);
    const [readyStatus2, setReadyStatus2] = useState(false);
    const waveSurferPlayerRef2 = useRef([null]);
    const handleCurrentTimeUpdate2 = (time) => {
        setCurrentTime(time);
    };
    const handlePlayStatusUpdate2 = (status) => {
        setPlayStatus2(status);
    };
    const handleReadyStatus2 = (status) => {
        setTimeout(() => {
            setReadyStatus2(status);
        }, 500);
    };
    const handlePlayClick2 = () => {
        waveSurferPlayerRef2.current.setTime(currentTime);
        waveSurferPlayerRef2.current.play();
        waveSurferPlayerRef1.current.pause();
    };
    const handlePauseClick2 = () => {
        waveSurferPlayerRef2.current.pause();
    };



    useEffect(() => {
        setReadyStatus1(false);
        setReadyStatus2(false);
    }, [activeKey])



    const [collectedData, setCollectedData] = useState();
    useEffect(() => {
        if (musicDetails?.musicianfiles_30 !== undefined && musicDetails?.masteredfiles_30 !== undefined) {
            const newData = {
                source_track_id: musicDetails?.musicianfile.id,
            };
            setCollectedData(newData);
        }
    }, [musicDetails]);
    const RedirectToMasteringPage = () => {
        if (collectedData) {
            navigate("/mastering", { state: collectedData, replace: false })
        }
    }



    /* const downloadFile = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = fileName;

            // Yeni sekmede açmak için
            downloadLink.target = '_blank';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('An Error occured:', error);
        }
    }; */
    const downloadFile = (url, fileName) => {
        try {
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = fileName;

            // Yeni sekmede açmak için
            downloadLink.target = '_blank';
            downloadLink.rel = 'noopener noreferrer';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('An Error occured:', error);
        }
    };


    const originalTrackDeleteReducer = useSelector(state => state.originalTrackDeleteReducer)
    const { loading: originalTrackDeleteLoading, success: originalTrackDeleteSuccess, error: originalTrackDeleteError } = originalTrackDeleteReducer
    const [deleteOriginalFileId, setDeleteOriginalFileId] = useState(null);
    const deleteOriginalFileStart = (id) => {
        handleDeleteOriginalModalShow();
        setDeleteOriginalFileId(id);
    }
    const deleteOriginalFile = (id) => {
        dispatch(originalTrackDelete(deleteOriginalFileId))
    }
    useEffect(() => {
        if (originalTrackDeleteLoading) {
            handleDeleteOriginalModalClose();
            setNotificationTitle(t('Deleting'));
            setNotificationTime(t('Wait a moment'));
            setNotificationDesc(t('Deleting your track'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (originalTrackDeleteSuccess) {
            setNotificationTitle(t('SUCCESS'));
            setNotificationTime(t('Now'));
            setNotificationDesc(t('Your track has been deleted'));
            setNotificationType("success");
            setShowNotification(true);
            dispatch(musicianTrackList())
        } else if (originalTrackDeleteError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(originalTrackDeleteError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [originalTrackDeleteLoading, originalTrackDeleteSuccess, originalTrackDeleteError])

    const masteredTrackDeleteReducer = useSelector(state => state.masteredTrackDeleteReducer)
    const { loading: masteredTrackDeleteLoading, success: masteredTrackDeleteSuccess, error: masteredTrackDeleteError } = masteredTrackDeleteReducer
    const [deleteMasteredFileId, setDeleteMasteredFileId] = useState(null);
    const deleteMasteredFileStart = (id) => {
        handleMasteredDeleteModalShow();
        setDeleteMasteredFileId(id);
    }
    const deleteMasteredFile = (id) => {
        dispatch(masteredTrackDelete(deleteMasteredFileId))
    }
    useEffect(() => {
        if (masteredTrackDeleteLoading) {
            handleDeleteMasteredModalClose();
            setNotificationTitle(t('Deleting'));
            setNotificationTime(t('Wait a moment'));
            setNotificationDesc(t('Deleting your track'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (masteredTrackDeleteSuccess) {
            setNotificationTitle(t('SUCCESS'));
            setNotificationTime(t('Now'));
            setNotificationDesc(t('Your track has been deleted'));
            setNotificationType("success");
            setShowNotification(true);
            dispatch(musicianTrackList())
        } else if (masteredTrackDeleteError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(masteredTrackDeleteError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [masteredTrackDeleteLoading, masteredTrackDeleteSuccess, masteredTrackDeleteError])

    const [originalFilePreviewUrl, setOriginalFilePreviewUrl] = useState(null);
    const [masteredFilePreviewUrl, setMasteredFilePreviewUrl] = useState(null);
    useEffect(() => {
        setOriginalFilePreviewUrl(null);
        setMasteredFilePreviewUrl(null);
        if (musicDetailsSuccess) {
            setOriginalFilePreviewUrl(musicDetails?.musicianfiles_30[0].presigned_url);
            // setMasteredFilePreviewUrl with masteredfiles_30 where id is equal to musicDetails?.masteredfiles[0].mastered_track_30
            if (musicDetails?.masteredfiles.length > 0) {
                if (musicDetails?.masteredfiles[0].mastered_track_30 !== null) {
                    const masteredFile = musicDetails?.masteredfiles_30.find((item) => item.id === musicDetails?.masteredfiles[0].mastered_track_30);
                    setMasteredFilePreviewUrl(masteredFile.presigned_url);
                }
            }
        }
    }, [musicDetails])


    const [renewButtonClicked, setRenewButtonClicked] = useState(false);
    const renewTrackInfo = () => {
        if (musicsListSuccess && musicsList.length > 0 && activeKey !== null) {
            dispatch(musicianTrackDetails(activeKey))
        }
        setRenewButtonClicked(true);
        setTimeout(() => {
            setRenewButtonClicked(false);
        }, 5000);
    }


    const [barWidth, setBarWidth] = useState(4);
    const [barGap, setBarGap] = useState(4);
    const updateBarProps = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 767) {
            setBarWidth(1);
            setBarGap(2);
        } else {
            setBarWidth(4);
            setBarGap(4);
        }
    };
    useEffect(() => {
        updateBarProps();
        window.addEventListener('resize', updateBarProps);
        return () => {
            window.removeEventListener('resize', updateBarProps);
        };
    }, []);


    return (
        <div className='music-card'>

            <Row className='justify-content-center mt-5 mb-1 m-mt-2'>
                <Col className='d-none d-sm-block'>
                    <div className='d-flex justify-content-between'>
                        <p className='align-self-center m-0 px-3 m-px-0'>{t('My tracks')}</p>
                        <div className='button-group'>
                            <Button variant="link" onClick={() => navigate("/upload-track", { replace: true })}>
                                <img src='/icons/upload.svg' alt='Mastering' className='icon' />
                                {t('Upload New Track')}
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Card className='border'>
                <Card.Header>
                    <div className='d-flex justify-content-between card-header-inner'>
                        <div className='w-30 chi-left'>{t('Title')}</div>
                        <div className='w-60 px-2 chi-center'>{t('Play')}</div>
                        <div className='w-10 text-center chi-right'>{t('Action')}</div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {musicsListLoading === true ?
                        <div className='d-flex justify-content-center py-5 my-5'>
                            <div className='relativeLoading d-flex justify-content-center align-items-stretch'>
                                <span className="align-self-center">
                                    <Spinner animation="border" />
                                </span>
                            </div>
                        </div>
                        : activeKey !== null ?
                            <Accordion defaultActiveKey={activeKey}>
                                {musicsList && musicsList.map((music) => (
                                    <Accordion.Item key={music.id} eventKey={music.id} onClick={() => handleAccordionClick(music.id)}>
                                        <Accordion.Header>
                                            <div className='w-100 d-flex justify-content-between accordion-header-inner'>
                                                <div className='w-90 ahi-left'>{music.track_name}</div>
                                                <div className='w-10 ahi-right'>
                                                    {
                                                        music.is_mastered === true ?
                                                            <span className="badge badge-light w-100">{t('Mastered')}</span>
                                                            : music.mastering_state === "-1" ?
                                                                <span className="badge badge-dark w-100">{t('Uploaded')}</span>
                                                                : music.mastering_state === "0" ?
                                                                    <span className="badge badge-dark w-100">{t('Cropped')}</span>
                                                                    : music.mastering_state === "1" ?
                                                                        <span className="badge badge-dark w-100">{t('Continue')}</span>
                                                                        : music.mastering_state === "2" ?
                                                                            <span className="badge badge-light w-100">{t('Mastering')}</span>
                                                                            : music.mastering_state === "3" ?
                                                                                <span className="badge badge-light w-100">{t('Mastered')}</span>
                                                                                : null
                                                    }
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body className='p-relative'>
                                            {activeKey === music.id && musicDetailsLoading && (
                                                <>
                                                    <div className='d-flex justify-content-center py-5 my-2'>
                                                        <div className='relativeLoading d-flex justify-content-center align-items-stretch'>
                                                            <span className="align-self-center">
                                                                <Spinner animation="border" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {activeKey === music.id && musicDetailsSuccess && (
                                                <>
                                                    <div className='revision-list w-100 d-flex align-items-center justify-content-between'>
                                                        <div className='w-30 rl-left'>{t('Original File')}</div>
                                                        <div className='w-60 rl-center'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div className='d-flex flex-column w-10 rlc-left'>
                                                                    {
                                                                        readyStatus1 === false ?
                                                                            <img src='/icons/mastering-preloader.svg' alt='Mastering' className='icon preloader' />
                                                                            :
                                                                            playStatus1 ?
                                                                                <img onClick={handlePauseClick1} src='/icons/pause-circle.svg' alt='Mastering' className='icon pointer' />
                                                                                :
                                                                                <img onClick={handlePlayClick1} src='/icons/play-circle.svg' alt='Mastering' className='icon pointer' />
                                                                    }
                                                                </div>
                                                                <div className='w-90 rlc-right'>
                                                                    {originalFilePreviewUrl && <WaveSurferPlayer
                                                                        ref={waveSurferPlayerRef1}
                                                                        height={24}
                                                                        waveColor="rgb(100, 100, 100)"
                                                                        progressColor="rgb(250, 250, 250)"
                                                                        normalize={true}
                                                                        url={originalFilePreviewUrl}
                                                                        barWidth={barWidth}
                                                                        barGap={barGap}
                                                                        barRadius={10}
                                                                        barHeight={0.9}
                                                                        cursorWidth={1}
                                                                        interact={true}
                                                                        hideScrollbar={true}
                                                                        autoScroll={true}
                                                                        autoCenter={true}
                                                                        autoplay={false}
                                                                        startTime={startTime}
                                                                        dragSelection={false}
                                                                        onCurrentTimeUpdate={handleCurrentTimeUpdate1}
                                                                        onPlayStatusUpdate={handlePlayStatusUpdate1}
                                                                        onReadyStatus={handleReadyStatus1}
                                                                    />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-10 text-center rl-right'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle id="music-dots-1" className='dots-btn'>
                                                                    <img src='/icons/dots.svg' alt='Mastering' className='icon' />
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => downloadFile(music.presigned_url, music.track_name + '-Original-File')}>{t('Download')}</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => deleteOriginalFileStart(music.id)}>{t('Delete')}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className='revision-list w-100 d-flex align-items-center justify-content-between'>
                                                        <div className='w-30 rl-left'>{t('Mastered File')}</div>
                                                        {
                                                            musicDetails?.masteredfiles_30.length > 0 && musicDetails?.masteredfiles.length > 0 && musicDetails?.masteredfiles[0].presigned_url ?
                                                                <>
                                                                    <div className='w-60 rl-center'>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <div className='d-flex flex-column w-10 rlc-left'>
                                                                                {
                                                                                    readyStatus2 === false ?
                                                                                        <img src='/icons/mastering-preloader.svg' alt='Mastering' className='icon preloader' />
                                                                                        :
                                                                                        playStatus2 ?
                                                                                            <img onClick={handlePauseClick2} src='/icons/pause-circle.svg' alt='Mastering' className='icon pointer' />
                                                                                            :
                                                                                            <img onClick={handlePlayClick2} src='/icons/play-circle.svg' alt='Mastering' className='icon pointer' />
                                                                                }
                                                                            </div>
                                                                            <div className='w-90 rlc-right'>
                                                                                {masteredFilePreviewUrl && <WaveSurferPlayer
                                                                                    ref={waveSurferPlayerRef2}
                                                                                    height={24}
                                                                                    waveColor="rgb(100, 100, 100)"
                                                                                    progressColor="rgb(250, 250, 250)"
                                                                                    normalize={true}
                                                                                    url={masteredFilePreviewUrl}
                                                                                    barWidth={barWidth}
                                                                                    barGap={barGap}
                                                                                    barRadius={10}
                                                                                    barHeight={0.9}
                                                                                    cursorWidth={1}
                                                                                    interact={true}
                                                                                    hideScrollbar={true}
                                                                                    autoScroll={true}
                                                                                    autoCenter={true}
                                                                                    autoplay={false}
                                                                                    startTime={startTime}
                                                                                    dragSelection={false}
                                                                                    onCurrentTimeUpdate={handleCurrentTimeUpdate2}
                                                                                    onPlayStatusUpdate={handlePlayStatusUpdate2}
                                                                                    onReadyStatus={handleReadyStatus2}
                                                                                />}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-10 text-center rl-right'>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle id="music-dots-2" className='dots-btn'>
                                                                                <img src='/icons/dots.svg' alt='Mastering' className='icon' />
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => downloadFile(musicDetails?.masteredfiles[0].presigned_url, music.track_name + '-Mastered-File')}>{t('Download')}</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => deleteMasteredFileStart(musicDetails?.masteredfiles[0].id)}>{t('Delete')}</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </>
                                                                : musicDetails?.masteredfiles.length > 0 && musicDetails?.masteredfiles[0].presigned_url === null ?
                                                                    <>
                                                                        <div className='w-60 rl-center'>
                                                                            <div className='d-flex justify-content-center'>
                                                                                <span className="mx-2">{t('Waiting for Mastering Proccess')}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-10 text-center rl-right'>
                                                                            <img onClick={renewTrackInfo} src='/icons/reload.svg' alt='Mastering' className={`icon pointer mx-0 ${renewButtonClicked ? 'renew-disabled' : ''}`} />
                                                                        </div>
                                                                    </>
                                                                    : musicDetails?.masteredfiles_30.length > 0 && musicDetails?.masteredfiles_30[0].presigned_url === null ?
                                                                        <>
                                                                            <div className='w-60 rl-center rl-center-text'>
                                                                                <div className='d-flex justify-content-center'>
                                                                                    <span className="mx-2">{t('Waiting for Revision Proccess')}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className='w-10 text-center rl-right'>
                                                                                <img onClick={renewTrackInfo} src='/icons/reload.svg' alt='Mastering' className={`icon pointer mx-0 ${renewButtonClicked ? 'renew-disabled' : ''}`} />
                                                                            </div>
                                                                        </>
                                                                        : musicDetails?.masteredfiles_30.length > 0 ?
                                                                            <>
                                                                                <div className='w-70 rl-center-text'>
                                                                                    <div className='d-flex justify-content-center'>
                                                                                        <span className="text-underline pointer mx-2" onClick={RedirectToMasteringPage}>{t('Continue to Mastering')}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className='w-70 rl-center-text'>
                                                                                    <div className='d-flex justify-content-center'>
                                                                                        {t('There is an unexpected error, please try again later')}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                            : (userAccDetails && !musicsList) || userAccDetails?.total_uploads === 0 || musicsList.length === 0 ?
                                <div className='d-flex justify-content-center py-5 my-5'>
                                    {t('There is no uploaded track yet')}
                                </div>
                                :
                                <div className='d-flex justify-content-center py-5 my-5'>
                                    <div className='relativeLoading d-flex justify-content-center align-items-stretch'>
                                        <span className="align-self-center">
                                            <Spinner animation="border" />
                                        </span>
                                    </div>
                                </div>
                    }
                </Card.Body>
            </Card>

            <Modal
                show={DeleteOriginalModal}
                onHide={handleDeleteOriginalModalClose}
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Delete Selected File')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-5 mb-4 text-center'>
                        <Col>
                            <h3>{t('Are you sure?')}</h3>
                            <p className='mt-3'>{t('We are going to delete your track')}</p>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center my-5'>
                        <div className='px-3'>
                            <Button className="w-100" variant="primary" onClick={deleteOriginalFile}>{t('Delete it')}</Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={DeleteMasteredModal}
                onHide={handleDeleteMasteredModalClose}
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Delete Selected File')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-5 mb-4 text-center'>
                        <Col>
                            <h3>{t('Are you sure?')}</h3>
                            <p className='mt-3'>{t('We are going to delete your track')}</p>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center my-5'>
                        <div className='px-3'>
                            <Button className="w-100" variant="primary" onClick={deleteMasteredFile}>{t('Delete it')}</Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>

        </div>
    );
}

export default MyTracksPage;
