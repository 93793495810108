// mail confirmation page, user come here after clicking on the link in the email, link has token in it, we will send token to backend and check if it is valid, if valid, we will update the user's email_verified field to true
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Button, Spinner, Container } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { mailConfirmation } from '../actions/userActions'
import Message from '../components/Message'
import { use } from 'i18next';

function MailConfirmationPage() {

    useEffect(() => {
        document.title = "Mail Confirmation - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const [message, setMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);

    // reducer
    const mailConfirmationReducer = useSelector(state => state.mailConfirmationReducer)
    const { message: confirmEmailMessage, loading: confirmEmailLoading, success: confirmEmailSuccess, error: confirmEmailError } = mailConfirmationReducer

    const [token, setToken] = useState(null)
    const [mailConfirmationStart, setMailConfirmationStart] = useState(false)
    useEffect(() => {
        setToken(window.location.pathname.split('/')[2])
    }, [])
    useEffect(() => {
        if (token) {
            setMailConfirmationStart(true)
        }
    }, [token])
    const startMailConfirmation = () => {
        dispatch(mailConfirmation(token))
    }
    useEffect(() => {
        if (mailConfirmationStart) {
            startMailConfirmation()
        }
    }, [mailConfirmationStart])

    useEffect(() => {
        if (confirmEmailSuccess) {
            if (confirmEmailMessage?.message === "User is already verified.") {
                setSuccessMessage(t('User is already verified, you can login with your credidentials'))
            } else {
                setSuccessMessage(t('Email confirmed successfully!'))
            }
            setTimeout(() => {
                setNotificationTitle(t('Redirecting'))
                setNotificationTime(t('just now'))
                setNotificationDesc(t('Redirecting in 1 seconds'))
                setNotificationType("success")
                setShowNotification(true)
            }, 500)
            navigate('/upload-track', { replace: true })
            window.location.reload()
        } else if (confirmEmailError) {
            setMessage(confirmEmailError)
        }
    }, [confirmEmailMessage, confirmEmailSuccess, confirmEmailError])

    return (

        <>
            <div className="form-card mt-2 mt-sm-5">

                <div className='d-flex flex-column justify-content-center'>
                    <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                    <div className='fs-5 text-center'>{t('Welcome Musician!')}</div>
                    <h2 className='text-center mb-4'>{t('Confirm Your Mail')}</h2>
                    <div className='fs-6 mb-4 text-center'>{t('Please wait while confirming your email address')}</div>
                    {message && <div className='mb-2 text-center'><Message variant='danger'>{message}</Message></div>}
                    {successMessage && <div className='mb-2 text-center'><Message variant='success'>{successMessage}</Message></div>}
                    <div className='fs-4 mb-3 text-center'>
                        { confirmEmailLoading ? <Button variant='secondary' className='w-100 mt-3'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                            : null
                        }
                    </div>
                </div>

            </div>

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>

    )
}

export default MailConfirmationPage