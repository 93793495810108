import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userDetails, logout, checkTokenValidation, createCheckoutSession } from '../actions/userActions'
import { CREATE_CHECKOUT_SESSION_RESET } from '../constants'
import { Spinner, Button } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useTranslation } from "react-i18next";
import useAuth from '../hooks/useAuth';
import { useQuery } from '@tanstack/react-query'
import { apiInstance } from '../utils';

function CreditsSubscriptionsPage() {

    useEffect(() => {
        document.title = "Credits & Subscriptions - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userAccLoading, userAccDetails, userAccError, userInfo } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);


    useEffect(() => {
        return () => {
            localStorage.removeItem("checkoutSession")
            dispatch({
                type: CREATE_CHECKOUT_SESSION_RESET
            })
        }
    }, []);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);


    // products query
    const { isPending: loadingProducts, error: errorProducts, data: productList } = useQuery({
        queryKey: ['products'],
        queryFn: () => apiInstance.get('/api/products').then(res => res.data?.products),
        initialData: []
    })


    // create checkout session reducer
    const createCheckoutSessionReducer = useSelector(state => state.createCheckoutSessionReducer)
    const { session: checkoutSessionData, loading: loadingCheckoutSession, success: successCheckoutSession, error: errorCheckoutSession } = createCheckoutSessionReducer
    const createCheckoutSessionFunction = (id, quantity) => async (e) => {
        e.preventDefault()
        dispatch(createCheckoutSession(id, quantity))
        setCheckoutSessionCookie(id, quantity)
    }
    useEffect(() => {
        if (loadingCheckoutSession) {
            setNotificationTitle(t('Redirecting to checkout'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your session is being created, Please wait'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (successCheckoutSession) {
            setNotificationTitle(t('Waiting for payment'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your payment process is being started, Please come back after payment'));
            setNotificationType("dark");
            setShowNotification(true);
            window.location.href = checkoutSessionData.payment_url;
        } else if (errorCheckoutSession) {
            setNotificationTitle(t('Error'))
            setNotificationTime(null)
            setNotificationDesc(errorCheckoutSession)
            setNotificationType("danger")
            setShowNotification(true)
        }
    }, [loadingCheckoutSession, successCheckoutSession, errorCheckoutSession])


    // set cookie for checkout session
    const setCheckoutSessionCookie = (id, quantity) => {
        const product = productList.find(product => product.id === id);
        const purchaseData = {
            id: id,
            quantity: quantity,
            price: product.price * quantity,
            name: product.name
        };
        const domain = window.location.hostname === 'localhost' ? 'localhost' : 'diktatorial.com';
        document.cookie = `LastPurchasedPrice=${JSON.stringify(purchaseData?.price)}; path=/; domain=${domain}`;
    };



    // price card overlay effect
    useEffect(() => {
        if (productList && productList?.length > 0) {

            const cardsContainer = document.querySelector(".priceCards");
            const overlay = document.querySelector(".overlay");
            overlay.innerHTML = '';
            const cards = Array.from(document.querySelectorAll(".priceCard"));

            const applyOverlayMask = (e) => {
                const overlayEl = e.currentTarget;
                const x = e.pageX - cardsContainer.offsetLeft;
                const y = e.pageY - cardsContainer.offsetTop;
                overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
            };

            const createOverlayCta = (overlayCard, ctaEl) => {
                const overlayCta = document.createElement("div");
                overlayCta.classList.add("cta");
                overlayCta.textContent = ctaEl.textContent;
                overlayCta.setAttribute("aria-hidden", true);
                if (ctaEl.classList.contains("cta_quantity")) {
                    overlayCta.classList.add("cta_quantity");
                }
                if (ctaEl.classList.contains("cta_subscription")) {
                    overlayCta.classList.add("cta_subscription");
                }
                overlayCard.append(overlayCta);
            };

            const observer = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    const cardIndex = cards.indexOf(entry.target);
                    let width = entry.borderBoxSize[0].inlineSize;
                    let height = entry.borderBoxSize[0].blockSize;
                    if (cardIndex >= 0) {
                        overlay.children[cardIndex].style.width = `${width}px`;
                        overlay.children[cardIndex].style.height = `${height}px`;
                    }
                });
            });

            const initOverlayCard = (cardEl) => {
                const overlayCard = document.createElement("div");
                overlayCard.classList.add("priceCard");
                const targetElement = cardEl.querySelector(".priceCard__cta");
                if (targetElement) {
                    createOverlayCta(overlayCard, targetElement);
                }
                overlay.append(overlayCard);
                observer.observe(cardEl);
            };

            if (productList) {
                if (overlay.children.length === 0) {
                    cards.forEach(initOverlayCard);
                }
            }
            document.body.addEventListener("pointermove", applyOverlayMask);

            return () => {
                document.body.removeEventListener("pointermove", applyOverlayMask);
                cards.forEach(card => {
                    if (card) {
                        observer.unobserve(card)
                    }
                });
            };
        }
    }, [productList, userAccDetails]);


    // products
    const [lifetimeMembership, setLifetimeMembership] = useState(null);
    const [yearlyMembership, setYearlyMembership] = useState(null);
    const [monthlyMembership, setMonthlyMembership] = useState(null);
    const [singleMastering, setSingleMastering] = useState(null);
    const [extraMasteringLifetime, setExtraMasteringLifetime] = useState(null);
    const [extraMasteringYearly, setExtraMasteringYearly] = useState(null);
    const [extraMasteringMonthly, setExtraMasteringMonthly] = useState(null);
    const [extraMasteringEarlyBird, setExtraMasteringEarlyBird] = useState(null);
    useEffect(() => {
        if (productList && productList.length > 0) {
            productList.forEach(product => {
                switch (product.name) {
                    case "Pro Membership Lifetime":
                        setLifetimeMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Annual":
                        setYearlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Monthly":
                        setMonthlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Single Mastering":
                        setSingleMastering({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Lifetime":
                        setExtraMasteringLifetime({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Annual":
                        setExtraMasteringYearly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Monthly":
                        setExtraMasteringMonthly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Early Bird":
                        setExtraMasteringEarlyBird({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    default:
                }
            });
        }
    }, [productList]);


    // formats
    const formatPrice = price => {
        const number = parseFloat(price);
        const rounded = number.toFixed(2);
        return Number(rounded);
    };
    const formatProcent = (price, orjPrice) => {
        const number = parseFloat(price);
        const orjNumber = parseFloat(orjPrice);
        const procent = (100 - (number / orjNumber) * 100).toFixed(0);
        const roundedProcent = Math.round(procent / 5) * 5;
        return roundedProcent;
    };



    // subscription options
    const [paymentPlan, setPaymentPlan] = useState("annual");
    const handleSubscriptionToggle = (plan) => {
        setPaymentPlan(plan);
    };
    const [ProCheck, setProCheck] = useState(false);
    const [accountType, setAccountType] = useState("Free");
    const [isEarlyBird, setIsEarlyBird] = useState(false);
    useEffect(() => {
        const earlyBirdList = [296, 178, 157, 174, 213, 172, 170, 184, 169, 152, 317, 246, 214, 254, 316, 222, 239, 252, 244, 231, 297, 299, 293, 274, 265, 310, 335, 150, 355, 339, 373, 381, 383, 151, 173];
        if (userAccDetails?.status === "Pro Lifetime") {
            setAccountType(t('Pro Lifetime'));
            //setPaymentPlan("lifetime");
        } else if (userAccDetails?.status === "Pro Annual") {
            setAccountType(t('Pro Annual'));
            //setPaymentPlan("lifetime");
        } else if (userAccDetails?.status === "Pro Monthly") {
            setAccountType(t('Pro Monthly'));
        }
        if (userAccDetails?.id && earlyBirdList.includes(userAccDetails?.id)) {
            setIsEarlyBird(true);
        }
    }, [userAccDetails]);


    // update button texts for animation
    const updateOverlaySubscriptionText = (q) => {
        const quantityElements = document.querySelectorAll('.overlay .cta_subscription');
        quantityElements.forEach(element => {
            if (paymentPlan === "lifetime") {
                element.textContent = t('Buy once, use FOREVER');
            } else {
                element.textContent = t('Buy Subscription');
            }
        });
    };
    useEffect(() => {
        updateOverlaySubscriptionText();
    }, [paymentPlan]);
    const updateOverlayQuantityText = (q) => {
        const quantityElements = document.querySelectorAll('.overlay .cta_quantity');
        quantityElements.forEach(element => {
            element.textContent = t('Buy {{quantitynumber}} Credit', { quantitynumber: q });
        });
    };


    // quantity options
    const [quantity, setQuantity] = useState(1);
    const handleIncrement = () => {
        if (quantity >= 99) {
            return;
        }
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
        updateOverlayQuantityText(newQuantity);
    };
    const handleDecrement = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
            updateOverlayQuantityText(newQuantity);
        }
    };


    // redirect to gumroad
    const RedirectToGumroad = () => {
        window.open("https://berkancesur.gumroad.com/l/songsanctuary", "_blank");
    }


    // see all bullets
    const seeAllBulletsSubscription = () => {
        const bullets = document.querySelectorAll('.pcbw-subscription');
        bullets.forEach(bullet => {
            bullet.classList.toggle('show-all');
        });
    };
    const seeAllBulletsCredit = () => {
        const bullets = document.querySelectorAll('.pcbw-credit');
        bullets.forEach(bullet => {
            bullet.classList.toggle('show-all');
        });
    }



    return (
        <>
            {(!userAccDetails || userAccLoading || loadingProducts || loadingCheckoutSession) && <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'><span className="align-self-center"><Spinner animation="border" /></span></div>}

            {productList && productList.length > 0 &&
                <>
                    <div className="mainPriceCard flowPriceCard">
                        <h5 className='mb-0 text-center'>Diktatorial Suite</h5>
                        <h1 className="mainPriceCard__heading">{t('Credits & Subscriptions')}</h1>
                        <p className="mainPriceCard__subheading">{t('PricingSubHeading')}</p>
                        <div className="mainPriceCard__cards priceCards">
                            <div className="priceCards__inner">

                                { /* subscription card */}
                                {(accountType !== "Pro Lifetime" && accountType !== "Pro Annual") &&
                                    <>
                                        <div className="priceCards__card priceCard">
                                            <div className='priceCard__header'>
                                                <div>
                                                    <span className="priceCard__TopHeading">{t('proMemberTopTitle')}</span>
                                                    <h2 className="priceCard__heading">{t('PRO Membership')}</h2>
                                                </div>
                                                {
                                                    userAccDetails?.is_active_subscriber && userAccDetails?.status === "Pro Annual" ?
                                                        <div className="payment-plan-toggle">
                                                            <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "lifetime" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("lifetime")}>{t('LIFETIME')}</Button>
                                                        </div>
                                                        : userAccDetails?.is_active_subscriber && userAccDetails?.status === "Pro Monthly" ?
                                                            <div className="payment-plan-toggle">
                                                                {/* <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "lifetime" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("lifetime")}>{t('LIFETIME')}</Button> */}
                                                                <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "annual" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("annual")}>{t('ANNUAL')}</Button>
                                                            </div>
                                                            :
                                                            <div className="payment-plan-toggle">
                                                                {/* <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "lifetime" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("lifetime")}>{t('LIFETIME')}</Button> */}
                                                                <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "annual" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("annual")}>{t('ANNUAL')}</Button>
                                                                <Button size="sm" variant="primary" className={`toggle-option ${paymentPlan === "monthly" ? "active" : ""}`} onClick={() => handleSubscriptionToggle("monthly")}>{t('MONTHLY')}</Button>
                                                            </div>
                                                }
                                            </div>

                                            {paymentPlan === "lifetime" ?
                                                <>
                                                    <div className="priceCard__price">
                                                        <div className='priceCard__old-price_section'>
                                                            {
                                                                /* (lifetimeMembership?.orj_price !== lifetimeMembership?.price) &&
                                                                <span className='priceCard-price-badges'>{formatProcent(lifetimeMembership?.price, lifetimeMembership?.orj_price)}% {t('OFF')}</span> */
                                                            }
                                                            <span className='priceCard-price-badges'>{t('EARLY BIRD')}</span>
                                                            <span className='priceCard-price-badges'>{t('PAY ONCE')}</span>
                                                            <span className='priceCard-price-badges'>{t('BEST DEAL')}</span>
                                                        </div>
                                                        ${formatPrice(lifetimeMembership?.price)}
                                                        {
                                                            (lifetimeMembership?.orj_price !== lifetimeMembership?.price) ?
                                                                <span className="price__not">${formatPrice(lifetimeMembership?.orj_price)}</span>
                                                                : null
                                                        }
                                                        <div className="price-gift-badge pointer" onClick={RedirectToGumroad}>
                                                            <div className='pgb-text'>
                                                                <div>{t('Go premium and')}</div>
                                                                <div>{t('get a $30 Notion template')} <span className='pgbt-color'>{t('as a bonus!')}</span></div>
                                                            </div>
                                                            <div className='pgb-icon'>
                                                                <img src="/icons/notion-gift.svg" alt="Notion" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="priceCard__desc">{t('LifeTimeTextDescription')}</p>
                                                    <div className='priceCard__bullets-wrapper pcbw-subscription'>
                                                        <ul className="priceCard__bullets flowPriceCard">
                                                            <li><b><span>{t('lifetime_list_ex_1a')}</span> {t('lifetime_list_ex_1')} <span>{t('lifetime_list_ex_1b')}</span></b></li>
                                                            <li><div><span>{t('lifetime_list_ex_2a')}</span> {t('lifetime_list_ex_2')} ({t('lifetime_list_ex_2b')} - <span>{t('lifetime_list_ex_2c')}</span>)</div></li>
                                                            <li><div>{t('lifetime_list_ex_3')} <span>{t('lifetime_list_ex_3b')}</span></div></li>
                                                            <li>{t('lifetime_list_ex_4')}</li>
                                                            <li>{t('lifetime_list_ex_5')}</li>
                                                        </ul>
                                                        <div className="priceCard__bullets-seeall" onClick={seeAllBulletsSubscription}>
                                                            <span>{t('See all features')}</span>
                                                        </div>
                                                        <ul className="priceCard__bullets flowPriceCard pcb-seconds">
                                                            <li>{t('lifetime_list_ex_6')}</li>
                                                            <li>{t('lifetime_list_ex_7')}</li>
                                                            <li>{t('lifetime_list_ex_8')}</li>
                                                            <li>{t('lifetime_list_ex_9')}</li>
                                                            <li>{t('lifetime_list_ex_10')}</li>
                                                        </ul>
                                                        <div className="priceCard__bullets-seeless" onClick={seeAllBulletsSubscription}>
                                                            <span>{t('See less')}</span>
                                                        </div>
                                                    </div>
                                                    <div className="price-gift-badge pgb-mobile pointer" onClick={RedirectToGumroad}>
                                                        <div className='pgb-text'>
                                                            <div>{t('Go premium and')}</div>
                                                            <div>{t('get a $30 Notion template')} <span className='pgbt-color'>{t('as a bonus!')}</span></div>
                                                        </div>
                                                        <div className='pgb-icon'>
                                                            <img src="/icons/notion-gift.svg" alt="Notion" />
                                                        </div>
                                                    </div>
                                                </>
                                                : paymentPlan === "annual" ?
                                                    <>
                                                        <div className="priceCard__price">
                                                            <div className='priceCard__old-price_section'>
                                                                {
                                                                    /* (yearlyMembership?.orj_price !== yearlyMembership?.price) &&
                                                                    <span className='priceCard-price-badges'>{formatProcent(yearlyMembership?.price, yearlyMembership?.orj_price)}% {t('OFF')}</span> */
                                                                }
                                                                <span className='priceCard-price-badges'>{t('EARLY BIRD')}</span>
                                                                <span className='priceCard-price-badges'>{t('BEST DEAL')}</span>
                                                                <span className='priceCard-price-badges pcpb_alternate'>{t('SUBSCRIPTION')}</span>
                                                            </div>
                                                            ${formatPrice(yearlyMembership?.price / 12)} <span className="price__prefix">/ {t('per month')}</span>
                                                            <div className="price-gift-badge pointer" onClick={RedirectToGumroad}>
                                                                <div className='pgb-text'>
                                                                    <div>{t('Go premium and')}</div>
                                                                    <div>{t('get a $30 Notion template')} <span className='pgbt-color'>{t('as a bonus!')}</span></div>
                                                                </div>
                                                                <div className='pgb-icon'>
                                                                    <img src="/icons/notion-gift.svg" alt="Notion" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="priceCard__desc">{t('YearlyTextDescription')}</p>
                                                        <div className='priceCard__bullets-wrapper pcbw-subscription'>
                                                            <ul className="priceCard__bullets flowPriceCard">
                                                                <li><b><span>{t('yearly_list_ex_1a')}</span> {t('yearly_list_ex_1')} <span>{t('yearly_list_ex_1b')}</span></b></li>
                                                                <li><div><span>{t('yearly_list_ex_2a')}</span> {t('yearly_list_ex_2')} {t('yearly_list_ex_2b')}</div></li>
                                                                <li><div><span>{t('yearly_list_ex_3b')}</span> {t('yearly_list_ex_3')}</div></li>
                                                                <li>{t('yearly_list_ex_4')}</li>
                                                                <li>{t('yearly_list_ex_5')}</li>
                                                            </ul>
                                                            <div className="priceCard__bullets-seeall" onClick={seeAllBulletsSubscription}>
                                                                <span>{t('See all features')}</span>
                                                            </div>
                                                            <ul className="priceCard__bullets flowPriceCard pcb-seconds">
                                                                <li>{t('yearly_list_ex_6')}</li>
                                                                <li>{t('yearly_list_ex_7')}</li>
                                                                <li>{t('yearly_list_ex_8')}</li>
                                                                <li>{t('yearly_list_ex_9')}</li>
                                                                <li>{t('yearly_list_ex_10')}</li>
                                                            </ul>
                                                            <div className="priceCard__bullets-seeless" onClick={seeAllBulletsSubscription}>
                                                                <span>{t('See less')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="price-gift-badge pgb-mobile pointer" onClick={RedirectToGumroad}>
                                                            <div className='pgb-text'>
                                                                <div>{t('Go premium and')}</div>
                                                                <div>{t('get a $30 Notion template')} <span className='pgbt-color'>{t('as a bonus!')}</span></div>
                                                            </div>
                                                            <div className='pgb-icon'>
                                                                <img src="/icons/notion-gift.svg" alt="Notion" />
                                                            </div>
                                                        </div>
                                                    </>
                                                    : paymentPlan === "monthly" ?
                                                        <>
                                                            <div className="priceCard__price">
                                                                <div className='priceCard__old-price_section'>
                                                                    {
                                                                        /* (monthlyMembership?.orj_price !== monthlyMembership?.price) &&
                                                                        <span className='priceCard-price-badges'>{formatProcent(monthlyMembership?.price, monthlyMembership?.orj_price)}% {t('OFF')}</span> */
                                                                    }
                                                                    <span className='priceCard-price-badges'>{t('EARLY BIRD')}</span>
                                                                    <span className='priceCard-price-badges pcpb_alternate'>{t('SUBSCRIPTION')}</span>
                                                                </div>
                                                                ${formatPrice(monthlyMembership?.price)} <span className="price__prefix">/ {t('per month')}</span>
                                                            </div>
                                                            <p className="priceCard__desc">{t('MonthlyTextDescription')}</p>
                                                            <div className='priceCard__bullets-wrapper pcbw-subscription'>
                                                                <ul className="priceCard__bullets flowPriceCard">
                                                                    <li><b><span>{t('monthly_list_ex_1a')}</span> {t('monthly_list_ex_1')} <span>{t('monthly_list_ex_1b')}</span></b></li>
                                                                    <li><div><span>{t('monthly_list_ex_2a')}</span> {t('monthly_list_ex_2')} {t('monthly_list_ex_2b')}</div></li>
                                                                    <li><div><span>{t('monthly_list_ex_3b')}</span> {t('monthly_list_ex_3')}</div></li>
                                                                    <li>{t('monthly_list_ex_4')}</li>
                                                                    <li>{t('monthly_list_ex_5')}</li>
                                                                </ul>
                                                                <div className="priceCard__bullets-seeall" onClick={seeAllBulletsSubscription}>
                                                                    <span>{t('See all features')}</span>
                                                                </div>
                                                                <ul className="priceCard__bullets flowPriceCard pcb-seconds">
                                                                    <li>{t('monthly_list_ex_6')}</li>
                                                                    <li>{t('monthly_list_ex_7')}</li>
                                                                    <li>{t('monthly_list_ex_8')}</li>
                                                                    <li>{t('monthly_list_ex_9')}</li>
                                                                    <li>{t('monthly_list_ex_10')}</li>
                                                                </ul>
                                                                <div className="priceCard__bullets-seeless" onClick={seeAllBulletsSubscription}>
                                                                    <span>{t('See less')}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : null}

                                            <div className="priceCard__cta cta cta_subscription pointer"
                                                onClick={createCheckoutSessionFunction(
                                                    paymentPlan === "lifetime" ? lifetimeMembership?.id
                                                        : paymentPlan === "annual" ? yearlyMembership?.id
                                                            : paymentPlan === "monthly" ? monthlyMembership?.id
                                                                : null, 1)}
                                            >
                                                {paymentPlan === "lifetime" ? t('Buy once, use FOREVER') : t('Buy Subscription')}
                                            </div>

                                        </div>
                                    </>
                                }

                                {!userAccDetails?.is_active_subscriber ?

                                    <>
                                        { /* credit card */}
                                        <div className="priceCards__card priceCard">
                                            <div className='priceCard__header'>
                                                {ProCheck ?
                                                    <div>
                                                        <span className="priceCard__TopHeading">{t('extraMasteringTopTitle')}</span>
                                                        <h2 className="priceCard__heading">{t('Extra Mastering')}</h2>
                                                    </div>
                                                    :
                                                    <div>
                                                        <span className="priceCard__TopHeading">{t('singleMasteringTopTitle')}</span>
                                                        <h2 className="priceCard__heading">{t('Single Mastering')}</h2>
                                                    </div>
                                                }
                                                <div className="can-toggle demo-rebrand-2">
                                                    <input id="a" type="checkbox" onChange={() => setProCheck(!ProCheck)} checked={ProCheck} />
                                                    <label htmlFor="a">
                                                        <div className="can-toggle__switch" data-checked={t('PRO')} data-unchecked={t('REGULAR')}></div>
                                                    </label>
                                                </div>
                                            </div>

                                            {ProCheck ?
                                                <>
                                                    <div className="priceCard__price">
                                                        <div className='priceCard__old-price_section'>
                                                            {
                                                                (extraMasteringLifetime?.orj_price !== extraMasteringLifetime?.price) ?
                                                                    <span className='priceCard-price-badges'>{formatProcent(extraMasteringLifetime?.price, extraMasteringLifetime?.orj_price)}% {t('OFF')}</span>
                                                                    : <span className='priceCard-price-badges'>{formatProcent(extraMasteringLifetime?.price, singleMastering?.price)}% {t('OFF')}</span>
                                                            }
                                                            <span className='priceCard-price-badges pcpb_alternate'>
                                                                {t('1 CREDIT')}
                                                            </span>
                                                        </div>
                                                        ${formatPrice(extraMasteringLifetime?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                    </div>
                                                    <p className="priceCard__desc">{t('ExtraMasteringTextDescription')}</p>
                                                    <div className='priceCard__bullets-wrapper pcbw-credit'>
                                                        <ul className="priceCard__bullets flowPriceCard">
                                                            <li>{t('pro_list_ex_1')}</li>
                                                            <li>{t('pro_list_ex_2')}</li>
                                                            <li>{t('pro_list_ex_3')}</li>
                                                            <li>{t('pro_list_ex_4')}</li>
                                                            <li>{t('pro_list_ex_5')}</li>
                                                        </ul>
                                                        <div className="priceCard__bullets-seeall" onClick={seeAllBulletsCredit}>
                                                            <span>{t('See all features')}</span>
                                                        </div>
                                                        <ul className="priceCard__bullets flowPriceCard pcb-seconds">
                                                            <li>{t('pro_list_ex_6')}</li>
                                                            <li>{t('pro_list_ex_7')}</li>
                                                            <li>{t('pro_list_ex_8')}</li>
                                                        </ul>
                                                        <div className="priceCard__bullets-seeless" onClick={seeAllBulletsCredit}>
                                                            <span>{t('See less')}</span>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="priceCard__price">
                                                        <div className='priceCard__old-price_section'>
                                                            {
                                                                /* (singleMastering?.orj_price !== singleMastering?.price) &&
                                                                <span className='priceCard-price-badges'>{formatProcent(singleMastering?.price, singleMastering?.orj_price)}% {t('OFF')}</span> */
                                                            }
                                                            <span className='priceCard-price-badges pcpb_alternate'>
                                                                {t('1 CREDIT')}
                                                            </span>
                                                        </div>
                                                        ${formatPrice(singleMastering?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                    </div>
                                                    <p className="priceCard__desc">{t('SingleMasteringTextDescription')}</p>
                                                    <div className='priceCard__bullets-wrapper pcbw-credit'>
                                                        <ul className="priceCard__bullets flowPriceCard priceCard__bullets-short">
                                                            <li>{t('regular_list_ex_1')}</li>
                                                            <li>{t('regular_list_ex_2')}</li>
                                                            <li>{t('regular_list_ex_3')}</li>
                                                            <li>{t('regular_list_ex_4')}</li>
                                                            <li>{t('regular_list_ex_5')}</li>
                                                        </ul>
                                                    </div>
                                                </>
                                            }

                                            {!userInfo ?
                                                <div onClick={() => navigate("/register", { replace: true })} className="priceCard__cta cta pointer cta_quantity">{t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}</div>
                                                : ProCheck && !userAccDetails?.is_active_subscriber ?
                                                    <>
                                                        <div disabled className="priceCard__cta priceCard__cta-disabled cta cta_quantity">{t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}</div>
                                                        <span className='priceCard__cta_info'>{t('PRO Membership Required')}</span>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='quantity-input'>
                                                            <button onClick={handleDecrement}><img src="/icons/minus.svg" alt="Minus" /></button>
                                                            <input type="text" value={quantity} readOnly />
                                                            <button onClick={handleIncrement}><img src="/icons/plus.svg" alt="Minus" /></button>
                                                        </div>
                                                        <div onClick={createCheckoutSessionFunction(singleMastering?.id, quantity)} className="priceCard__cta cta pointer cta_quantity">{t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}</div>
                                                    </>
                                            }
                                        </div>
                                    </>

                                    :
                                    <>
                                        { /* credit card */}
                                        <div className="priceCards__card priceCard">
                                            <div className='priceCard__header'>
                                                <div>
                                                    <span className="priceCard__TopHeading">{t('extraMasteringTopTitle')}</span>
                                                    <h2 className="priceCard__heading">{t('Extra Mastering')}</h2>
                                                </div>
                                                <div className="pch_account-type">
                                                    <div className="priceCard__TopHeading">{t('Your account type')}</div>
                                                    <div className="priceCard__heading">{accountType} {isEarlyBird && '(Early Bird)'}</div>
                                                </div>
                                            </div>

                                            <div className="priceCard__price">
                                                {isEarlyBird ?
                                                    <>
                                                        <div className='priceCard__old-price_section'>
                                                            {
                                                                (extraMasteringEarlyBird?.orj_price !== extraMasteringEarlyBird?.price) ?
                                                                    <span className='priceCard-price-badges'>{formatProcent(extraMasteringEarlyBird?.price, extraMasteringEarlyBird?.orj_price)}% {t('OFF')}</span>
                                                                    : <span className='priceCard-price-badges'>{formatProcent(extraMasteringEarlyBird?.price, singleMastering?.price)}% {t('OFF')}</span>
                                                            }
                                                            <span className='priceCard-price-badges pcpb_alternate'>
                                                                {t('1 CREDIT')}
                                                            </span>
                                                        </div>
                                                        ${formatPrice(extraMasteringEarlyBird?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                    </>
                                                    : userAccDetails.status === "Pro Lifetime" ?
                                                        <>
                                                            <div className='priceCard__old-price_section'>
                                                                {
                                                                    (extraMasteringLifetime?.orj_price !== extraMasteringLifetime?.price) ?
                                                                        <span className='priceCard-price-badges'>{formatProcent(extraMasteringLifetime?.price, extraMasteringLifetime?.orj_price)}% {t('OFF')}</span>
                                                                        : <span className='priceCard-price-badges'>{formatProcent(extraMasteringLifetime?.price, singleMastering?.price)}% {t('OFF')}</span>
                                                                }
                                                                <span className='priceCard-price-badges pcpb_alternate'>
                                                                    {t('1 CREDIT')}
                                                                </span>
                                                            </div>
                                                            ${formatPrice(extraMasteringLifetime?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                        </>
                                                        : userAccDetails.status === "Pro Annual" ?
                                                            <>
                                                                <div className='priceCard__old-price_section'>
                                                                    {
                                                                        (extraMasteringYearly?.orj_price !== extraMasteringYearly?.price) ?
                                                                            <span className='priceCard-price-badges'>{formatProcent(extraMasteringYearly?.price, extraMasteringYearly?.orj_price)}% {t('OFF')}</span>
                                                                            : <span className='priceCard-price-badges'>{formatProcent(extraMasteringYearly?.price, singleMastering?.price)}% {t('OFF')}</span>
                                                                    }
                                                                    <span className='priceCard-price-badges pcpb_alternate'>
                                                                        {t('1 CREDIT')}
                                                                    </span>
                                                                </div>
                                                                ${formatPrice(extraMasteringYearly?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                            </>
                                                            : userAccDetails.status === "Pro Monthly" ?
                                                                <>
                                                                    <div className='priceCard__old-price_section'>
                                                                        {
                                                                            (extraMasteringMonthly?.orj_price !== extraMasteringMonthly?.price) &&
                                                                            <span className='priceCard-price-badges'>{formatProcent(extraMasteringMonthly?.price, extraMasteringMonthly?.orj_price)}% {t('OFF')}</span>
                                                                        }
                                                                        <span className='priceCard-price-badges pcpb_alternate'>
                                                                            {t('1 CREDIT')}
                                                                        </span>
                                                                    </div>
                                                                    ${formatPrice(extraMasteringMonthly?.price)} <span className="price__prefix">/ {t('per Track')}</span>
                                                                </>
                                                                : null
                                                }
                                            </div>

                                            <p className="priceCard__desc">{t('ExtraMasteringTextDescription')}</p>

                                            <div className='priceCard__bullets-wrapper pcbw-credit'>
                                                <ul className="priceCard__bullets flowPriceCard">
                                                    <li>{t('pro_list_ex_1')}</li>
                                                    <li>{t('pro_list_ex_2')}</li>
                                                    <li>{t('pro_list_ex_3')}</li>
                                                    <li>{t('pro_list_ex_4')}</li>
                                                    <li>{t('pro_list_ex_5')}</li>
                                                </ul>
                                                <div className="priceCard__bullets-seeall" onClick={seeAllBulletsCredit}>
                                                    <span>{t('See all features')}</span>
                                                </div>
                                                <ul className="priceCard__bullets flowPriceCard pcb-seconds">
                                                    <li>{t('pro_list_ex_6')}</li>
                                                    <li>{t('pro_list_ex_7')}</li>
                                                    <li>{t('pro_list_ex_8')}</li>
                                                </ul>
                                                <div className="priceCard__bullets-seeless" onClick={seeAllBulletsCredit}>
                                                    <span>{t('See less')}</span>
                                                </div>
                                            </div>

                                            <div className='quantity-input'>
                                                <button onClick={handleDecrement}><img src="/icons/minus.svg" alt="Minus" /></button>
                                                <input type="text" value={quantity} readOnly />
                                                <button onClick={handleIncrement}><img src="/icons/plus.svg" alt="Minus" /></button>
                                            </div>
                                            <div onClick={createCheckoutSessionFunction(
                                                isEarlyBird ? extraMasteringEarlyBird?.id
                                                    : userAccDetails.status === "Pro Lifetime" ? extraMasteringLifetime?.id
                                                        : userAccDetails.status === "Pro Annual" ? extraMasteringYearly?.id
                                                            : userAccDetails.status === "Pro Monthly" ? extraMasteringMonthly?.id
                                                                : null,
                                                quantity
                                            )} className="priceCard__cta cta pointer cta_quantity">{t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}</div>
                                        </div>
                                    </>

                                }
                            </div>
                            <div className="overlay priceCards__inner"></div>
                        </div>
                    </div>
                </>
            }

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    );
}

export default CreditsSubscriptionsPage;
