import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap'
import { login, resendMailConfirmation } from '../actions/userActions'
import Message from '../components/Message'

function LoginPage() {

    useEffect(() => {
        document.title = "Sign In - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // reducer
    const userLoginReducer = useSelector(state => state.userLoginReducer)
    const { loading: loginLoading, userInfo, error: loginError } = userLoginReducer
    useEffect(() => {
        if (userInfo) {
            navigate('/', { replace: true }) // homepage
        }
    }, [navigate, userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        if (email.length === 0) {
            setMessage(t('Please enter your email address!'))
        } else if (password.length === 0) {
            setMessage(t('Please enter your password!'))
        } else {
            dispatch(login(email, password))
        }
    }
    useEffect(() => {
        if (loginError) {
            setMessage(loginError)
        }
    }, [loginError])
    const [resendMail, setResendMail] = useState(false);
    useEffect(() => {
        // if login error contains "Email is not verified" then show resend mail button
        if (loginError && loginError.includes("Email is not verified")) {
            setResendMail(true);
        }
    }, [loginError])


    const resendMailConfirmationReducer = useSelector(state => state.resendMailConfirmationReducer)
    const { loading: resendMailConfirmationLoading, success: resendMailConfirmationSuccess, error: resendMailConfirmationError } = resendMailConfirmationReducer
    const resendMailConfirmationHandler = () => {
        dispatch(resendMailConfirmation(email))
    }


    return (

        <div className="form-card mt-2 mt-sm-5">

            <Row className='justify-content-md-center'>

                {
                    resendMail ?
                        <Col xs={12}>
                            <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                            <div className='fs-5 text-center'>{t('Welcome Musician!')}</div>
                            <h2 className='text-center mb-4'>{t('Mail Confirmation')}</h2>
                            {
                                resendMailConfirmationLoading ?
                                    <>
                                        {message && <Message variant='danger'>{message}</Message>}
                                        <Button variant='primary' className='w-100 mt-3'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                                    </>
                                    : resendMailConfirmationSuccess ?
                                        <>
                                            {message && <Message variant='success'>{t('Mail Sent')}</Message>}
                                            <Button variant='primary' className='w-100 mt-3' onClick={() => window.location.reload()}>{t('Login')}</Button>
                                        </>
                                        :
                                        <>
                                            {message && <Message variant='danger'>{message}</Message>}
                                            <Button variant='primary' className='w-100 mt-3' onClick={resendMailConfirmationHandler}>{t('Resend Mail')}</Button>
                                        </>
                            }
                        </Col>
                        :
                        <Col xs={12}>
                            <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                            <div className='fs-5 text-center'>{t('Welcome Musician!')}</div>
                            <h2 className='text-center mb-4'>{t('Login')}</h2>
                            {message && <Message variant='danger'>{message}</Message>}

                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId='email'>
                                    <Form.Label>
                                        {t('E-mail')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('enter your email')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete="email"
                                    >
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId='password'>
                                    <Form.Label>
                                        {t('Password')}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('enter your password')}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="current-password"
                                    >
                                    </Form.Control>
                                </Form.Group>

                                {
                                    loginLoading ?
                                        <Button variant='primary' className='w-100 my-3'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                                        :
                                        <Button type="submit" variant='primary' className='w-100 my-3'>
                                            {t('Login')}
                                        </Button>
                                }

                            </Form>

                            <div className="form-extra-actions my-3 d-flex justify-content-between">
                                <Link
                                    to={`/register`}
                                > {t('Sign Up')}</Link>
                                <Link
                                    to={`/forgot-password`}
                                > {t('Forgot password')}</Link>
                            </div>
                        </Col>
                }
            </Row>
        </div>

    )
}

export default LoginPage