import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    MAIL_CONFIRMATION_REQUEST,
    MAIL_CONFIRMATION_SUCCESS,
    MAIL_CONFIRMATION_FAIL,

    RESEND_MAIL_CONFIRMATION_REQUEST,
    RESEND_MAIL_CONFIRMATION_SUCCESS,
    RESEND_MAIL_CONFIRMATION_FAIL,

    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_FORGOT_PASSWORD_FAIL,

    PASSWORD_CONFIRM_RESET_REQUEST,
    PASSWORD_CONFIRM_RESET_SUCCESS,
    PASSWORD_CONFIRM_RESET_FAIL,

    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    CHECK_TOKEN_VALID_REQUEST,
    CHECK_TOKEN_VALID_SUCCESS,
    CHECK_TOKEN_VALID_FAIL,

    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,

    UPDATE_USER_DETAILS_REQUEST,
    UPDATE_USER_DETAILS_SUCCESS,
    UPDATE_USER_DETAILS_FAIL,

    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,

    USER_DELETE_ACCOUNT_REQUEST,
    USER_DELETE_ACCOUNT_SUCCESS,
    USER_DELETE_ACCOUNT_FAIL,

    CREATE_CHECKOUT_SESSION_REQUEST,
    CREATE_CHECKOUT_SESSION_SUCCESS,
    CREATE_CHECKOUT_SESSION_FAIL,

    UPLOAD_MUSIC_REQUEST,
    UPLOAD_MUSIC_SUCCESS,
    UPLOAD_MUSIC_FAIL,

    UPLOAD_TRACK_NEW_REQUEST,
    UPLOAD_TRACK_NEW_SUCCESS,
    UPLOAD_TRACK_NEW_FAIL,

    S3_UPLOAD_REQUEST,
    S3_UPLOAD_SUCCESS,
    S3_UPLOAD_FAIL,

    RECEIVER_UPLOAD_NEW_REQUEST,
    RECEIVER_UPLOAD_NEW_SUCCESS,
    RECEIVER_UPLOAD_NEW_FAIL,

    CELERY_STATUS_REQUEST,
    CELERY_STATUS_SUCCESS,
    CELERY_STATUS_FAIL,

    CROP_TRACK_REQUEST,
    CROP_TRACK_SUCCESS,
    CROP_TRACK_FAIL,

    MASTER30_TRACK_REQUEST,
    MASTER30_TRACK_SUCCESS,
    MASTER30_TRACK_FAIL,

    MASTER30_STATUS_REQUEST,
    MASTER30_STATUS_SUCCESS,
    MASTER30_STATUS_FAIL,

    MASTERMAIN_TRACK_REQUEST,
    MASTERMAIN_TRACK_SUCCESS,
    MASTERMAIN_TRACK_FAIL,

    MAINMASTER_STATUS_REQUEST,
    MAINMASTER_STATUS_SUCCESS,
    MAINMASTER_STATUS_FAIL,

    MUSICIAN_TRACK_LIST_REQUEST,
    MUSICIAN_TRACK_LIST_SUCCESS,
    MUSICIAN_TRACK_LIST_FAIL,

    MUSICIAN_TRACK_DETAILS_REQUEST,
    MUSICIAN_TRACK_DETAILS_SUCCESS,
    MUSICIAN_TRACK_DETAILS_FAIL,

    MUSICIAN_TRACK_QUICK_REQUEST,
    MUSICIAN_TRACK_QUICK_SUCCESS,
    MUSICIAN_TRACK_QUICK_FAIL,

    MUSICIAN_TRACK_UPDATE_REQUEST,
    MUSICIAN_TRACK_UPDATE_SUCCESS,
    MUSICIAN_TRACK_UPDATE_FAIL,

    ORIGINAL_TRACK_DELETE_REQUEST,
    ORIGINAL_TRACK_DELETE_SUCCESS,
    ORIGINAL_TRACK_DELETE_FAIL,

    MASTERED_TRACK_DELETE_REQUEST,
    MASTERED_TRACK_DELETE_SUCCESS,
    MASTERED_TRACK_DELETE_FAIL,

} from '../constants/index'

import React, { useState } from "react";
import { apiInstance as backend, setEncryptedUserCookies, clearEncryptedUserCookies } from '../utils';
import axios from 'axios';

// register
export const register = (email, password, referralCode) => async (dispatch) => {
    try {
        dispatch({ type: USER_REGISTER_REQUEST })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const requestData = {
            email: email,
            password: password
        }
        if (referralCode) {
            requestData.reference_code = referralCode
        }
        const { data } = await backend.post(
            `/api/user/register`,
                requestData,
            config
        )
        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: errorString
        })
    }
}

// mail confirmation
export const mailConfirmation = (token) => async (dispatch) => {
    try {
        dispatch({
            type: MAIL_CONFIRMATION_REQUEST
        })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await backend.get(
            `/api/user/verify-email/?${token}`,
            config
        )
        dispatch({
            type: MAIL_CONFIRMATION_SUCCESS,
            payload: data
        })
        if (data.user_id) {
            data.id = data.user_id
            delete data.user_id
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data
            })
            localStorage.setItem('userInfo', JSON.stringify(data))
            setEncryptedUserCookies({
                id: data.id,
                email: data.email,
                access_token: data.access_token
                /* refresh_token: data.refresh_token */
            });
        }
    }
    catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MAIL_CONFIRMATION_FAIL,
            payload: errorString
        })
    }
}

// resend mail confirmation
export const resendMailConfirmation = (email) => async (dispatch) => {
    try {
        dispatch({
            type: RESEND_MAIL_CONFIRMATION_REQUEST
        })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await backend.post(
            `/api/user/resend-confirmation-email`,
            { 'email': email },
            config
        )
        dispatch({
            type: RESEND_MAIL_CONFIRMATION_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: RESEND_MAIL_CONFIRMATION_FAIL,
            payload: errorString
        })
    }
}

// forgot password
export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGOT_PASSWORD_REQUEST
        })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await backend.post(
            `/api/user/request-reset-password`,
            { 'email': email },
            config
        )
        dispatch({
            type: USER_FORGOT_PASSWORD_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: USER_FORGOT_PASSWORD_FAIL,
            payload: errorString
        })
    }
}

// password confirm reset
export const passwordConfirm = (token, uidb64, password) => async (dispatch) => {
    try {
        dispatch({
            type: PASSWORD_CONFIRM_RESET_REQUEST
        })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await backend.patch(
            `/api/user/reset-password-complete`,
            {
                'password': password,
                'token': token,
                'uidb64': uidb64,
            },
            config
        )
        dispatch({
            type: PASSWORD_CONFIRM_RESET_SUCCESS,
            payload: data
        })
    }
    catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: PASSWORD_CONFIRM_RESET_FAIL,
            payload: errorString
        })
    }
}

// Login
export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }
        const { data } = await backend.post(`/api/user/login`,
                {
                    'email': email,
                    'password': password
                },
            config
        )
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        if (data.id) {
            localStorage.setItem('userInfo', JSON.stringify(data))
            setEncryptedUserCookies({
                id: data.id,
                email: data.email,
                access_token: data.access_token
                /* refresh_token: data.refresh_token */
            });
        }

    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: errorString
        })
    }
}

// Logout
export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    clearEncryptedUserCookies(['_d_i', '_d_e', '_d_a']);
    dispatch({
        type: USER_LOGOUT
    })
}

// check token validation
export const checkTokenValidation = () => async (dispatch, getState) => {
    try {

        dispatch({
            type: CHECK_TOKEN_VALID_REQUEST
        })

        const {
            userLoginReducer: { userInfo }
        } = getState()

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }

        // call api
        const { data } = await backend.post(
            "/api/token/check/",
            {
                'refresh': userInfo.access_token,
                'user_id': userInfo.id
            },
        config)

        dispatch({
            type: CHECK_TOKEN_VALID_SUCCESS,
            payload: data
        })

    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: CHECK_TOKEN_VALID_FAIL,
            payload: errorString
        })
        /* dispatch(logout()) */
    }
}

// user details
export const userDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        const { data } = await backend.get(
            `/api/musicians/${id}`,
            config
        )
        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: USER_DETAILS_FAIL,
            payload: errorString
        })
        /* dispatch(logout())
        localStorage.removeItem('userInfo')
        window.location.href = "/" */
    }
}

// user update details
export const userUpdateDetails = (id, artist_name, name ,surname) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_USER_DETAILS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/musicians/update/${id}`,
            {
                "name": name,
                "surname": surname,
                "artist_name": artist_name,
            },
            config
        )
        dispatch({
            type: UPDATE_USER_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: UPDATE_USER_DETAILS_FAIL,
            payload: errorString
        })
    }
}

// user update password
export const userUpdatePassword = (id, old_password, new_password) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_USER_PASSWORD_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/user/${id}/change-password`,
            {
                "current_password": old_password,
                "new_password": new_password,
            },
            config
        )
        dispatch({
            type: UPDATE_USER_PASSWORD_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: UPDATE_USER_PASSWORD_FAIL,
            payload: errorString
        })
    }
}

// user delete account
export const userDeleteAccount = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DELETE_ACCOUNT_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        const { data } = await backend.delete(
            `/api/user/delete/${id}`,
            config
        )
        dispatch({
            type: USER_DELETE_ACCOUNT_SUCCESS,
            payload: data
        })
        dispatch(logout())
        localStorage.removeItem('userInfo')
        clearEncryptedUserCookies(['_d_i', '_d_e', '_d_a']);
        window.location.href = "/"
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: USER_DELETE_ACCOUNT_FAIL,
            payload: errorString
        })
    }
}

// create checkout session
export const createCheckoutSession = (id, quantity) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CREATE_CHECKOUT_SESSION_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/create-checkout-session`,
            {
                'product_id': id,
                'user_id': userInfo.id,
                'quantity': quantity
            },
            config
        )
        dispatch({
            type: CREATE_CHECKOUT_SESSION_SUCCESS,
            payload: data
        })
        localStorage.setItem('checkoutSession', JSON.stringify(data))
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: CREATE_CHECKOUT_SESSION_FAIL,
            payload: errorString
        })
    }
}

// upload music
export const uploadMusic = (musicData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPLOAD_MUSIC_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/upload-track/${userInfo.id}`,
            musicData,
            config
        )
        dispatch({
            type: UPLOAD_MUSIC_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: UPLOAD_MUSIC_FAIL,
            payload: errorString
        })
    }
}

// upload track new
export const uploadTrackNew = (type) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPLOAD_TRACK_NEW_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/upload-new/${userInfo.id}`,
            {
                "file_extension": type
            },
            config
        )
        dispatch({
            type: UPLOAD_TRACK_NEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: UPLOAD_TRACK_NEW_FAIL,
            payload: errorString
        })
    }
}

// s3 upload
export const s3Upload = (presignedUrl, file) => async (dispatch, getState) => {
    try {
        dispatch({
            type: S3_UPLOAD_REQUEST
        })
        const response = await axios.put(presignedUrl, file, {
            headers: {
                "Content-Type": "binary/octet-stream",
            },
            /* onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(`Upload Progress: ${percentCompleted}%`);
            }, */
        });
        if (response.status) {
            dispatch({
                type: S3_UPLOAD_SUCCESS,
                payload: await response.statusText
            });
        } else {
            dispatch({
                type: S3_UPLOAD_FAIL,
                payload: response.status
            })
            throw new Error('S3 upload failed with status: ' + response.status);
        }
    } catch (error) {
        dispatch({
            type: S3_UPLOAD_FAIL,
            payload: error
        })
    }
}

// receiver upload new
export const receiverUploadNew = (trackId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RECEIVER_UPLOAD_NEW_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/receiver-upload-new/${userInfo.id}`,
            {
                "status": "success",
                "musician_file_id" : trackId
            },
            config
        )
        dispatch({
            type: RECEIVER_UPLOAD_NEW_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            }
            else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: RECEIVER_UPLOAD_NEW_FAIL,
            payload: errorString
        })
    }
}

// celery status
export const celeryStatus = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CELERY_STATUS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.get(
            `/api/get_celery_task_status/${id}`,
            config
        )
        dispatch({
            type: CELERY_STATUS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            //type: CELERY_STATUS_SUCCESS, // cfggeçici
            type: CELERY_STATUS_FAIL,
            payload: errorString
        })
    }
}

// musician track update
export const musicianTrackUpdate = (id, trackData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MUSICIAN_TRACK_UPDATE_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/musicians/${userInfo.id}/tracks/${id}/update-track`,
            trackData,
            config
        )
        dispatch({
            type: MUSICIAN_TRACK_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MUSICIAN_TRACK_UPDATE_FAIL,
            payload: errorString
        })
    }
}

// select 30 seconds of music
export const cropTrack = (cropData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CROP_TRACK_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/crop-track`,
            cropData[0],
            config
        )
        dispatch({
            type: CROP_TRACK_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: CROP_TRACK_FAIL,
            payload: errorString
        })
    }
}

// master30Track
export const master30Track = (master30Data) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MASTER30_TRACK_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/sqs-master-30/${userInfo.id}`,
            //`/api/master-30/${userInfo.id}`,
            master30Data[0],
            config
        )
        dispatch({
            type: MASTER30_TRACK_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MASTER30_TRACK_FAIL,
            //type: MASTER30_TRACK_SUCCESS, // cfggeçici
            payload: errorString
        })
    }
}

// master30 status
export const master30Status = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MASTER30_STATUS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`
            }
        };
        // call api
        const { data } = await backend.post(`/api/receive-data`,
            {
                'task_id': id,
                "location" : "mastered_30"
            },
        config)
        dispatch({
            type: MASTER30_STATUS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MASTER30_STATUS_FAIL,
            payload: errorString
        })
    }
}

// masterMainTrack
export const masterMainTrack = (masterMainData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MASTERMAIN_TRACK_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.post(
            `/api/sqs-master/${userInfo.id}`,
            //`/api/master/${userInfo.id}`,
            masterMainData[0],
            config
        )
        dispatch({
            type: MASTERMAIN_TRACK_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MASTERMAIN_TRACK_FAIL,
            payload: errorString
        })
    }
}

// mainmaster status
export const mainmasterStatus = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MAINMASTER_STATUS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`
            }
        };
        // call api
        const { data } = await backend.post(`/api/receive-data`,
            {
                'task_id': id,
                "location" : "mastered"
            },
        config)
        dispatch({
            type: MAINMASTER_STATUS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MAINMASTER_STATUS_FAIL,
            payload: errorString
        })
    }
}

// musician track list
export const musicianTrackList = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: MUSICIAN_TRACK_LIST_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.get(
            `/api/musicians/${userInfo.id}/tracks`,
            config
        )
        dispatch({
            type: MUSICIAN_TRACK_LIST_SUCCESS,
            payload: data.filter((item) => item.revision_count !== 0).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MUSICIAN_TRACK_LIST_FAIL,
            payload: errorString
        })
    }
}

// musician track details
export const musicianTrackDetails = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MUSICIAN_TRACK_DETAILS_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.get(
            `/api/musicians/${userInfo.id}/tracks/${id}/info`,
            config
        )
        const filterStatus = (item) => item.status !== 'fail' && item.status !== 'timeout';
        if (data.masteredfiles && Array.isArray(data.masteredfiles)) {
            data.masteredfiles = data.masteredfiles.filter(filterStatus);
        }
        if (data.masteredfiles_30 && Array.isArray(data.masteredfiles_30)) {
            data.masteredfiles_30 = data.masteredfiles_30.filter(filterStatus);
        }
        if (data.masteredfiles_30 && Array.isArray(data.masteredfiles_30)) {
            data.masteredfiles_30.sort((a, b) => a.id - b.id);
        }
        dispatch({
            type: MUSICIAN_TRACK_DETAILS_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MUSICIAN_TRACK_DETAILS_FAIL,
            payload: errorString
        })
    }
}

// musician track quick
export const musicianTrackQuick = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MUSICIAN_TRACK_QUICK_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.get(
            `/api/musicians/${userInfo.id}/tracks/${id}`,
            config
        )
        dispatch({
            type: MUSICIAN_TRACK_QUICK_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MUSICIAN_TRACK_QUICK_FAIL,
            payload: errorString
        })
    }
}

// original track delete
export const originalTrackDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ORIGINAL_TRACK_DELETE_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.delete(
            `/api/delete-track/${userInfo.id}/${id}`,
            config
        )
        dispatch({
            type: ORIGINAL_TRACK_DELETE_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: ORIGINAL_TRACK_DELETE_FAIL,
            payload: errorString
        })
    }
}

// mastered track delete
export const masteredTrackDelete = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: MASTERED_TRACK_DELETE_REQUEST
        })
        const {
            userLoginReducer: { userInfo }
        } = getState()
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.access_token}`
            }
        }
        // call api
        const { data } = await backend.delete(
            `/api/delete-mastered/${userInfo.id}/${id}`,
            config
        )
        dispatch({
            type: MASTERED_TRACK_DELETE_SUCCESS,
            payload: data
        })
    } catch (error) {
        let errorString = "";
        const errorData = error.response && error.response.data;
        if (typeof errorData === 'string') {
            if (errorData.startsWith("<!DOCTYPE html>")) {
                errorString = "Server Error";
            } else {
                errorString = errorData;
            }
        } else if (errorData) {
            for (const [key, value] of Object.entries(errorData)) {
                errorString += `${key}: ${Array.isArray(value) ? value[0] : value}\n`;
            }
        }
        dispatch({
            type: MASTERED_TRACK_DELETE_FAIL,
            payload: errorString
        })
    }
}
