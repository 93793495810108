import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { userUpdatePassword } from '../actions/userActions'
import Message from '../components/Message'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UPDATE_USER_PASSWORD_RESET } from '../constants'
import useAuth from '../hooks/useAuth';


function PasswordResetPage() {

    useEffect(() => {
        document.title = "Password Reset - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userInfo, userAccDetails, userAccError, userAccLoading } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);


    // user update password reducer
    const userPasswordUpdateReducer = useSelector(state => state.userPasswordUpdateReducer)
    const { loading: userPasswordUpdateLoading, success: userPasswordUpdateSuccess, error: userPasswordUpdateError } = userPasswordUpdateReducer

    const [oldPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [message, setMessage] = useState("")
    const [countdown, setCountdown] = useState(5);

    const onSubmit = (e) => {
        e.preventDefault()
        if (password !== "" && password.length < 8) {
            setMessage(t('Password must be at least 8 characters!'))
        } else if (password !== confirmPassword) {
            setMessage(t('Passwords do not match!'))
        } else {
            dispatch(userUpdatePassword(userAccDetails.id, oldPassword, confirmPassword))
        }
    }
    useEffect(() => {
        if (userPasswordUpdateError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userPasswordUpdateError);
            setNotificationType("danger");
            setShowNotification(true);
            setMessage(null)
        } else if (userPasswordUpdateSuccess) {
            setNotificationTitle(t('SUCCESS'));
            setNotificationTime(t('Now'));
            setNotificationDesc(`${t('Password successfully updated, you will be redirected to account page in')} ${countdown} ${t('seconds')}`);
            setNotificationType("success");
            setShowNotification(true);
            setMessage(null)
            const timerId = setInterval(() => {
                setCountdown((currentCountdown) => {
                    if (currentCountdown <= 1) {
                        clearInterval(timerId);
                        navigate("/account");
                        return () => clearInterval(timerId); // Cleanup function
                    }
                    setNotificationDesc(`${t('Password successfully updated, you will be redirected to account page in')} ${currentCountdown - 1} ${t('seconds')}`);
                    return currentCountdown - 1;
                });
            }, 1000);
            dispatch({
                type: UPDATE_USER_PASSWORD_RESET
            })
            /* dispatch(userDetails(userInfo.id)) */
        }
    }, [userPasswordUpdateError, userPasswordUpdateSuccess])


    return (
        <>
            {(userAccLoading || userPasswordUpdateLoading) && <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'><span className="align-self-center"><Spinner animation="border" /></span></div>}

            <div className="form-card mt-2 mt-sm-5">

                {userAccDetails &&
                    <Row className='justify-content-md-center'>
                        <Col xs={12}>
                            <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                            <h2 className='text-center mb-4'>{t('Password Update')}</h2>
                            {message && <Message variant='danger'>{message}</Message>}
                            <Form onSubmit={onSubmit}>

                                <Form.Group controlId='oldPassword'>
                                    <Form.Label>
                                        {t('Old Password')}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('enter your old password')}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        autoComplete="current-password"
                                    >
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId='password'>
                                    <Form.Label>
                                        {t('New Password')}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('enter your new password')}
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="new-password"
                                    >
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId='confirmPassword'>
                                    <Form.Label>
                                        {t('Confirm Password')}
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder={t('confirm new password')}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        autoComplete="new-password"
                                    >
                                    </Form.Control>
                                </Form.Group>

                                <div className="my-3 d-flex justify-content-between">
                                    <Link to={`/account`}>
                                        <button className="btn btn-link" type="button">
                                            {t('Cancel')}
                                        </button>
                                    </Link>
                                    <Button type="submit" variant='primary'>{t('Save Changes')}</Button>
                                </div>
                            </Form>

                        </Col>
                    </Row>
                }

            </div>

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )
}

export default PasswordResetPage
