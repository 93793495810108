import React, { useState, useEffect, useRef } from 'react';

const TypingText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    const requestRef = useRef();
    const indexRef = useRef(0);
    const typingSpeed = 10;

    useEffect(() => {
        const writeText = (now) => {
            if (indexRef.current >= text.length) {
                if (!isFinished) {
                    setIsFinished(true);
                }
                cancelAnimationFrame(requestRef.current);
                return;
            }

            if (!lastTimeRef.current || now - lastTimeRef.current > typingSpeed) {
                setDisplayedText(text.substring(0, indexRef.current + 1));
                indexRef.current++;
                lastTimeRef.current = now;
            }
            requestRef.current = requestAnimationFrame(writeText);
        };

        let lastTimeRef = { current: null };
        requestRef.current = requestAnimationFrame(writeText);
        return () => cancelAnimationFrame(requestRef.current);
    }, [text]);

    return (
        <div className='assistant-answer-wrapper'>
            <div className='assistant-answer-container'>
                <div className='aa-icon'>
                    <img className="icon aa-image-icon" src="/logo.png" alt="Assistant" />
                </div>
                <div className='aa-text'>
                    {displayedText}
                    <div className={`aa-glow ${isFinished ? 'aa-g-hide' : ''}`}></div>
                </div>
            </div>
        </div>
    );
};

export default TypingText;
