import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const QuotesComponent = () => {

    const { t } = useTranslation();

    const [index, setIndex] = useState(0);

    const updateIndex = () => {
        const usedIndexes = Cookies.get('usedIndexes') ? JSON.parse(Cookies.get('usedIndexes')) : [];
        let newIndex;
        do {
            newIndex = Math.floor(Math.random() * 16) + 1;
        } while (usedIndexes.includes(newIndex));
        setIndex(newIndex);
        if (usedIndexes.length >= 15) {
            Cookies.set('usedIndexes', JSON.stringify([newIndex]));
        } else {
            Cookies.set('usedIndexes', JSON.stringify([...usedIndexes, newIndex]));
        }
    };
    let firstTimeUseEffect = true;
    useEffect(() => {
        if (firstTimeUseEffect) {
            firstTimeUseEffect = false;
            updateIndex();
        }
        const intervalId = setInterval(() => {
            document.querySelector('.mast__header').classList.add('d-none');
            updateIndex();
            setTimeout(() => {
                document.querySelector('.mast__header').classList.remove('d-none');
            }, 100);
        }, 15000);
        return () => clearInterval(intervalId);
    }, []);

    const spanizeText = (text) => {
        return text.split('').map((char, index) => <span key={index}>{char}</span>);
    };

    const renderQuoteText = index => {
        const quoteKey = `quote__text_${index}`;
        const quoteText = t(quoteKey);
        return <p className="mast__text">{spanizeText(quoteText)}</p>;
    };

    const quotesArray = [
        t("quote__text_1"),
        t("quote__text_2"),
        t("quote__text_3"),
        t("quote__text_4"),
        t("quote__text_5"),
        t("quote__text_6"),
        t("quote__text_7"),
        t("quote__text_8"),
        t("quote__text_9"),
        t("quote__text_10"),
        t("quote__text_11"),
        t("quote__text_12"),
        t("quote__text_13"),
        t("quote__text_14"),
        t("quote__text_15"),
        t("quote__text_16")
    ];

    return (
        <main className="main-quotes">
            <section className="mast">
                <header className="mast__header">
                    <hr className="sep" />
                    {index >= 1 && index <= 16 ? renderQuoteText(index) : null}
                </header>
            </section>
        </main>
    );
};

export default QuotesComponent;
