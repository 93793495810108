import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import '../styles/language-switcher.scss'

function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [userChangedLanguage, setUserChangedLanguage] = useState(false);

    const languages = [
        { code: 'en-US', short: 'en' },
        /* { code: 'de-DE', short: 'de' }, */
        /* { code: 'ru-RU', short: 'ru' }, */
        /* { code: 'fr-FR', short: 'fr' }, */
        { code: 'es-ES', short: 'es' },
        /* { code: 'it-IT', short: 'it' }, */
        { code: 'tr-TR', short: 'tr' }
    ];

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');

        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            setUserChangedLanguage(true);
        } else if (!userChangedLanguage) {
            //let detectedLanguage = window.navigator.language || 'en-US';
            let detectedLanguage = 'en-US';
            //console.log('detectedLanguage: ', detectedLanguage);
            const matchingLanguage = languages.find(lang => detectedLanguage.includes(lang.short)) || { code: 'en-US' };
            i18n.changeLanguage(matchingLanguage.code);
        }

    }, [userChangedLanguage, i18n]);

    const changeLanguage = (event) => {
        const selectedLanguage = event.target.value;
        i18n.changeLanguage(selectedLanguage);
        setUserChangedLanguage(true);
        localStorage.setItem('selectedLanguage', selectedLanguage);
    }

    return (
        <div className="LanguageSwitcher d-none">
            <Form.Select size="sm" onChange={changeLanguage} value={i18n.language}>
                {languages.map(lang => (
                    <option key={lang.code} value={lang.code}>{lang.short}</option>
                ))}
            </Form.Select>
        </div>
    );
}

export default LanguageSwitcher;
