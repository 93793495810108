// AccordionComponent.js
import React from 'react';
import { ButtonGroup, ToggleButton, Accordion } from 'react-bootstrap';

const AccordionComponent = ({ category, icon, radiosState, changeRadioSelections, vertical = false }) => {

    const revisionFooterButton = document.querySelector('.revision-footer-button');
    const revisionMobileButton = document.querySelector('.mmb-revision');
    const radioChangeButtonEffect = () => {
        if (revisionFooterButton) {
            revisionFooterButton.classList.add('bouncee-btn');
            revisionMobileButton.classList.add('bouncee-btn');
        }
    }

    function handleOnChange(optionValue) {
        changeRadioSelections(category, optionValue);
        radioChangeButtonEffect();
    }

    return (
        <>
            <Accordion.Item eventKey={category}>
                <span className='suite-badge'>Suite</span>
                <Accordion.Header>
                    <img src={`/icons/${icon}.svg`} alt='Mastering' className='icon' />
                    {category.replace(/([A-Z])/g, ' $1').trim()} { }
                </Accordion.Header>
                <Accordion.Body>
                    <ButtonGroup vertical={vertical} className="p-2 my-2 w-100 gap-2">
                        {radiosState[category].value !== undefined ? radiosState[category].options.map((optionObj, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`radio-${category}-${idx}`}
                                type="radio"
                                variant="primary"
                                name={`radio-${category}`}
                                size='sm'
                                value={optionObj.value}
                                checked={radiosState[category].value === optionObj.value}
                                className={radiosState[category].value === optionObj.value ? 'selected-toggle' : null}
                                onChange={() => handleOnChange(optionObj.value)}
                                disabled={optionObj.disabled}
                            >
                                {optionObj.value}
                            </ToggleButton>
                        )) : null}
                    </ButtonGroup>
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
};

export default AccordionComponent;
