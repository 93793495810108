import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CREATE_CHECKOUT_SESSION_RESET } from '../constants'
import { useTranslation } from "react-i18next";
import { usePostHog } from 'posthog-js/react';


const Success = () => {

    useEffect(() => {
        document.title = "Order Success - Diktatorial Suite";
    }, []);
    
    
    const {t} = useTranslation();
    const posthog = usePostHog();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState([]);
    useEffect(() => {
        return () => {
            localStorage.removeItem("checkoutSession")
            dispatch({
                type: CREATE_CHECKOUT_SESSION_RESET
            })
        }
    }, []);

    //get purchaseData from cookie
    useEffect(() => {
        const LastPurchasedPrice = document.cookie.split(';').find(cookie => cookie.includes('LastPurchasedPrice'))
        if (LastPurchasedPrice) {
            const priceValue = LastPurchasedPrice.split('=')[1]
            posthog?.capture('Purchase Price', {
                price: priceValue
            })
            setTimeout(() => {
                document.cookie = "LastPurchasedPrice=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }, 1000)
        }
    }, []);

    return (
        <>
            <div className='accountPage p-5 m-px-0'>
                <Container>
                    <Card className='border'>

                        <Card.Header>
                            {t('Payment Status')}
                        </Card.Header>

                        <Card.Body>
                            <div className='d-flex flex-column justify-content-center account-card-body'>
                                <div className='fs-1 mb-4 text-center'><img className="icon payment-success-icon" src="/icons/done.svg" alt="user" /></div>
                                <div className='fs-6 text-center'>{t('Your payment has been made')}</div>
                                <div className='fs-3 mb-5 text-center text-white'><strong>{t('Successfully')}</strong></div>
                                <div className='fs-4 mb-4 text-center'>
                                    <Row className='account-action-buttons'>
                                        <Col xs={6}><Button className='w-100' onClick={() => navigate("/upload-track", { replace: true })}>{t('Upload New Track')}</Button></Col>
                                        <Col xs={6}><Button className='w-100' onClick={() => navigate("/account", { replace: true })}>{t('My Account')}</Button></Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>

                </Container>
            </div>
        </>
    )
}

export default Success