import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function NotFoundPage() {

    useEffect(() => {
        document.title = "Not Found - Diktatorial Suite";
    }, []);
    
    
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
        <div>
            <div className="error-page">
                <div className="error-page-image">
                    <img src="/icons/404.svg" alt="404" />
                </div>
                <div className="error-page-text">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </div>
                <div className="error-page-terminal">
                    <div>$ {t('server response')}</div>
                    <div>$ {t('something went wrong')}</div>
                    <div>$ {t('or you lost your way')}</div>
                    <div>$ {t('if we have a mistake')}</div>
                    <div>$ {t('rest assured that we are working on fixing it right now')}</div>
                    <div>$ {t('please try again in a moment')}</div>
                    <div onClick={() => navigate("/")}>$ {t('exit')}</div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage
