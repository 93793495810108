import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Form,  } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { logout, userDeleteAccount } from '../actions/userActions'
import Message from '../components/Message'
import { Spinner, Modal } from 'react-bootstrap'
import { useNavigate, Link } from "react-router-dom";
import { USER_DELETE_ACCOUNT_RESET } from '../constants'
import useAuth from '../hooks/useAuth';


function DeleteUserAccount() {

    useEffect(() => {
        document.title = "Delete Account - Diktatorial Suite";
    }, []);
    
    const {t} = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userInfo } = useAuth()

    // delete user account reducer
    const userDeleteAccountReducer = useSelector(state => state.userDeleteAccountReducer)
    const { loading: userDeleteLoading, success: userDeleteSuccess, error: userDeleteError } = userDeleteAccountReducer


    const [DeleteUserModal, setDeleteUserModal] = useState(false);
    const handleDeleteUserModalClose = () => setDeleteUserModal(false);
    const handleDeleteUserModalShow = () => setDeleteUserModal(true);


    const onSubmit = (e) => {
        e.preventDefault()
        handleDeleteUserModalShow()
    }

    const deleteUserAccount = () => {
        handleDeleteUserModalClose()
        dispatch(userDeleteAccount(userInfo.id))
    }

    if(userDeleteSuccess) {
        alert("Account successfully deleted.")
        dispatch({
            type: USER_DELETE_ACCOUNT_RESET
        })
        dispatch(logout()) // action
        setTimeout(() => {
            navigate("/login", {replace: true})
        }, 1000)
        window.location.reload()
    }

    return (
        <>

            <div className="form-card mt-2 mt-sm-5">
                {userDeleteLoading && <div className='fullPageLoading d-flex justify-content-center align-items-stretch'><span className="align-self-center"><Spinner animation="border" /></span></div>}

                <Row className='justify-content-md-center'>
                    <Col xs={12}>
                        <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                            <h2 className='text-center mb-4'>{t('Delete Account')}</h2>
                        {userDeleteError && <Message variant='danger'>{userDeleteError}</Message>}
                        {userDeleteSuccess && <Message variant='success'>{t('Account successfully deleted')}</Message>}
                        <p className='text-center mb-4'>{t('deleteaccountmessage')}</p>
                        <Form onSubmit={onSubmit}>
                            <div className="my-3 d-flex justify-content-between">
                                <Link to={`/account`}>
                                    <button className="btn btn-link" type="button">
                                        {t('Cancel')}
                                    </button>
                                </Link>
                                <Button type="submit" variant="danger">{t('Confirm Delete')}</Button>
                            </div>

                        </Form>

                    </Col>
                </Row>
            </div>

            <Modal
                show={DeleteUserModal}
                onHide={handleDeleteUserModalClose}
                keyboard={false}
                size="md"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Delete account')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-5 mb-4 text-center'>
                        <Col>
                            <h3>{t('Are you sure?')}</h3>
                            <p className='mt-3'>{t('We are going to delete your account')}</p>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center my-5'>
                        <div className='px-3'>
                            <Button className="w-100" variant="primary" onClick={deleteUserAccount}>{t('Delete it')}</Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default DeleteUserAccount
