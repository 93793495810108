import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { musicianTrackDetails, master30Track, masterMainTrack, master30Status, mainmasterStatus } from '../actions/userActions';
import { Button, Row, Col, Accordion, Modal, Form, ListGroup, Spinner } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import WaveSurferPlayer from '../components/WaveSurferPlayer';
import AccordionComponent from '../components/AccordionComponent';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.js';
import QuotesComponent from '../components/QuotesComponent';
import TypingText from '../components/TypingText';
import Pricing from '../components/Pricing';
import { MASTER30_TRACK_RESET, MASTERMAIN_TRACK_RESET, MASTER30_STATUS_RESET, MAINMASTER_STATUS_RESET } from '../constants';
import useAuth from '../hooks/useAuth';
import { usePostHog } from 'posthog-js/react';


function MasteringPage() {

    useEffect(() => {
        document.title = "Mastering - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const posthog = usePostHog();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userAccDetails, userAccError, userAccDetailsLoading, userInfo } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);



    const location = useLocation();
    const [data, setData] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [revisionLimit, setRevisionLimit] = useState(3);
    useEffect(() => {
        setIsSubscribed(userAccDetails?.is_active_subscriber);
        if (!userAccDetails?.is_active_subscriber) {
            //setRevisionLimit(3 - userAccDetails?.total_revisions)
            setRevisionLimit(4)
            /* if (userAccDetails?.is_ever_paid === false && localStorage.getItem('iep_pw') !== '1') {
                setTimeout(() => {
                    handlePayWallModalShow()
                    localStorage.setItem('iep_pw', '1');
                }, 5000);
            } */
        } else {
            setRevisionLimit(11)
        }
    }, [userAccDetails, userAccDetailsLoading]);


    const resetMasteringData = () => {
        localStorage.removeItem('Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA');
        dispatch({ type: MASTER30_TRACK_RESET });
        dispatch({ type: MASTER30_STATUS_RESET });
        dispatch({ type: MASTERMAIN_TRACK_RESET });
        dispatch({ type: MAINMASTER_STATUS_RESET });
        setShowNotification(false);
    };
    useEffect(() => {
        resetMasteringData();
        return () => {
            setData([]);
            resetMasteringData();
        }
    }, []);

    // check if mastering is on other page
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA' && event.newValue === '0NArODpbEWEqNZQqPehSWQ') {
                navigate("/my-tracks", { replace: true });
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [navigate]);



    // musician track details reducer
    const musicianTrackDetailsReducer = useSelector(state => state.musicianTrackDetailsReducer)
    const { music: musicDetails, loading: musicDetailsLoading, success: musicDetailsSuccess, error: musicDetailsError } = musicianTrackDetailsReducer
    const [isPodcast, setIsPodcast] = useState(false);
    let musicianTrackDetailsFirstTime = true;
    useEffect(() => {
        if (musicianTrackDetailsFirstTime) {
            if (location.state?.source_track_id !== undefined && location.state?.source_track_id !== null) {
                dispatch(musicianTrackDetails(location.state?.source_track_id))
                musicianTrackDetailsFirstTime = false;
            }
        }
    }, [])
    useEffect(() => {
        setIsPodcast(musicDetails?.musicianfile?.is_podcast);
    }, [musicDetails]);



    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);

    const [MasteringModal, setMasteringModal] = useState(false);
    const handleMasteringModalClose = () => setMasteringModal(false);
    const handleMasteringModalShow = () => setMasteringModal(true);

    const [PayWallModal, setPayWallModal] = useState(false);
    const handlePayWallModalClose = () => setPayWallModal(false);
    const handlePayWallModalShow = () => setPayWallModal(true);

    const revisionFooterButton = document.querySelector('.revision-footer-button');
    const revisionMobileButton = document.querySelector('.mmb-revision');



    const initialRadiosState = {
        ConversationCleaning: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Subtle', disabled: false },
                { value: 'Clean A', disabled: false },
                { value: 'Clean B', disabled: false }
            ]
        },
        AnalogWarmth: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Warm', disabled: false },
                { value: 'Hot as Hell', disabled: false }
            ]
        },
        Saturation: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Color', disabled: false },
                { value: 'Heavy', disabled: false },
                { value: 'None', disabled: false }
            ]
        },
        Loudness: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Smoother', disabled: false },
                { value: 'Crunchy', disabled: false },
                { value: 'Loud', disabled: false },
                { value: 'Aggressive', disabled: false }
            ]
        },
        StereoWidth: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Subtle', disabled: false },
                { value: 'Wide', disabled: false }
            ]
        },
        CompressorStyle: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Compressed', disabled: false },
                { value: 'None', disabled: false }
            ]
        },
        MonoCapability: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'Club Special', disabled: false },
                { value: 'Mono', disabled: false }
            ]
        },
        DeEsser: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 'None', disabled: false },
                { value: 'Heavy', disabled: false }
            ]
        },
        TruePeak: {
            value: 'Balanced',
            options: [
                { value: 'Balanced', disabled: false },
                { value: 0, disabled: false },
                { value: -1, disabled: false },
                { value: -2, disabled: false }
            ]
        }
    }
    const allCategories = Object.keys(initialRadiosState);
    const categoryValuesInitialState = Object.fromEntries(Object.entries(initialRadiosState).map(([key, obj]) => [key, obj.value]));
    const [categoryValues, setCategoryValues] = useState(categoryValuesInitialState);
    const [radiosState, setRadiosState] = useState(initialRadiosState);
    const changeRadioSelections = (category, newValue) => {
        setRadiosState(prevState => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                value: newValue
            }
        }));
        setCategoryValues(prevValues => ({
            ...prevValues,
            [category]: newValue
        }));

        /* if (revisionFooterButton) {
            revisionFooterButton.classList.add('bouncee-btn');
        } */
    }



    const [isLeftPanelActive, setisLeftPanelActive] = useState(true);
    const changeLeftPanelStatus = () => {
        const leftPanel = document.querySelector('.mastering-left-panel');
        leftPanel.classList.toggle('mastering-left-panel-passive');
        setisLeftPanelActive(!isLeftPanelActive);
    };



    const [MasteringEnabled, setMasteringEnabled] = useState(true);
    const [MonoCheck, setMonoCheck] = useState(false);



    function scrollToBottom() {
        const container = document.querySelector('.cf-container');
        const screenWidth = window.innerWidth;
        if (screenWidth > 990) {
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: 'smooth'
                });
            }
        } else {
            const scrollPosition = container.scrollWidth - container.clientWidth;
            container.scrollTo({
                left: scrollPosition,
                behavior: 'smooth'
            });
        }
    }


    const originalVersionText = t('Original Version');
    const diktatorialVersionText = t('Diktatorial Version');
    const PromptedVersionText = t('Prompted Version');
    const RevisedVersionText = t('Revised Version');
    const PromptText = t('Prompt');
    const PromptAlias = t('prompt');



    const [trackList, setTrackList] = useState([]);
    // get track list from music details
    useEffect(() => {
        if (musicDetails?.masteredfiles?.length > 0) {
            navigate("/my-tracks", { replace: true });
        } else if (musicDetails?.musicianfile) {
            const newTrackList = [
                {
                    key: '0',
                    id: musicDetails?.musicianfiles_30[0].id,
                    type: 'original',
                    name: originalVersionText,
                    url: musicDetails?.musicianfiles_30[0].presigned_url,
                    classes: ['original-track'],
                    conversationCleaning: null,
                    analogWarmth: null,
                    saturation: null,
                    loudness: null,
                    stereoWidth: null,
                    compressorStyle: null,
                    monoCapability: null,
                    deesser: null,
                    truePeak: null,
                },
                {
                    key: '1',
                    id: musicDetails?.masteredfiles_30[0].id,
                    type: 'mastered',
                    name: diktatorialVersionText,
                    url: musicDetails?.masteredfiles_30[0].presigned_url,
                    classes: ['mastered-track'],
                    conversationCleaning: 'Balanced',
                    analogWarmth: 'Balanced',
                    saturation: 'Balanced',
                    loudness: 'Balanced',
                    stereoWidth: 'Balanced',
                    compressorStyle: 'Balanced',
                    monoCapability: 'Balanced',
                    deesser: 'Balanced',
                    truePeak: 'Balanced',
                },
            ];
            setTrackList(newTrackList);
        }
    }, [musicDetails]);
    let multiMastered30TrackFirstTime = true;
    // get multiple track list from music details
    useEffect(() => {
        let timeoutId_gmtl = null;
        if (musicDetails?.masteredfiles_30?.length > 1 && multiMastered30TrackFirstTime) {
            const newTrackList = [];
            musicDetails?.masteredfiles_30.forEach((track, index) => {
                if (index === 0) {
                    return;
                }
                const newRevision = {
                    key: (index + 1).toString(),
                    id: track.id,
                    type: 'mastered',
                    name: track.is_prompt ? `${track.prompt_input}` : `${RevisedVersionText} ${index + 1}`,
                    url: track.presigned_url,
                    classes: ['revised-track'],
                    conversationCleaning: track.is_prompt ? PromptAlias : track.conversation_cleaning,
                    analogWarmth: track.is_prompt ? PromptAlias : track.analog_warmth,
                    saturation: track.is_prompt ? PromptAlias : track.saturation,
                    loudness: track.is_prompt ? PromptAlias : track.loudness,
                    stereoWidth: track.is_prompt ? PromptAlias : track.stereo_width,
                    compressorStyle: track.is_prompt ? PromptAlias : track.compressor_style,
                    monoCapability: track.is_prompt ? PromptAlias : track.mono_capability,
                    deesser: track.is_prompt ? PromptAlias : track.de_esser,
                    truePeak: track.is_prompt ? PromptAlias : track.true_peak,
                };
                newTrackList.push(newRevision);
            });
            setTrackList(prevTracks => [...prevTracks, ...newTrackList]);
            multiMastered30TrackFirstTime = false;
            timeoutId_gmtl = setTimeout(() => {
                scrollToBottom();
                if (musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.is_prompt === false) {
                    changeRadioSelections("ConversationCleaning", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.conversationCleaning);
                    changeRadioSelections("AnalogWarmth", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.analog_warmth);
                    changeRadioSelections("Saturation", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.saturation);
                    changeRadioSelections("Loudness", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.loudness);
                    changeRadioSelections("StereoWidth", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.stereo_width);
                    changeRadioSelections("CompressorStyle", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.compressor_style);
                    changeRadioSelections("MonoCapability", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.mono_capability);
                    changeRadioSelections("DeEsser", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.de_esser);
                    changeRadioSelections("TruePeak", musicDetails?.masteredfiles_30[musicDetails?.masteredfiles_30?.length - 1]?.true_peak);
                }
            }, 500);
        }
        return () => {
            if (timeoutId_gmtl) {
                clearTimeout(timeoutId_gmtl);
            }
        };
    }, [musicDetails]);



    // if trackList lenghth is bigger then 0
    // const currentLastKey = parseInt(trackList[trackList.length - 1].key, 10);
    const currentLastKey = trackList.length > 0 ? parseInt(trackList[trackList.length - 1].key, 10) : 0;



    // create revision with options
    const createRevision = () => {
        let timeoutId_crwo = null;
        pauseAllTracks();
        if (currentLastKey >= revisionLimit) {
            setNotificationTitle(t('Limit reached'));
            setNotificationTime(t('warning'));
            if (!userAccDetails?.is_active_subscriber) {
                setNotificationDesc(t('You have reached the revision limit defined for your account, whic is {{revisionLimit}}, You should get Suite Membership to do new revisions for this project', { revisionLimit: revisionLimit - 1 }));
                handlePayWallModalShow();
            } else {
                setNotificationDesc(t('You have reached the revision limit defined for your account, whic is {{revisionLimit}}', { revisionLimit: revisionLimit - 1 }));
            }
            setNotificationType("warning");
            setShowNotification(true);
            timeoutId_crwo = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return;
        }
        if (!MasteringEnabled) {
            setMasteringEnabled(true)
            onMasteringChange(true);
        }
        const master30Data = [{
            prompt: false,
            revize: true,
            track: musicDetails.musicianfiles_30[0].id,
            options: {
                "Conversation Cleaning": categoryValues.ConversationCleaning !== 'prompt' ? categoryValues.ConversationCleaning : "Balanced",
                "Analog Warmth": categoryValues.AnalogWarmth !== 'prompt' ? categoryValues.AnalogWarmth : "Balanced",
                "Saturation": categoryValues.Saturation !== 'prompt' ? categoryValues.Saturation : "Balanced",
                "Loudness": isSubscribed ? categoryValues.Loudness !== 'prompt' ? categoryValues.Loudness : "Balanced" : "Balanced",
                "Stereo Width": isSubscribed ? categoryValues.StereoWidth !== 'prompt' ? categoryValues.StereoWidth : "Balanced" : "Balanced",
                "Compressor Style": isSubscribed ? categoryValues.CompressorStyle !== 'prompt' ? categoryValues.CompressorStyle : "Balanced" : "Balanced",
                "Mono Capability": isSubscribed ? categoryValues.MonoCapability !== 'prompt' ? categoryValues.MonoCapability : "Balanced" : "Balanced",
                "De-Esser": isSubscribed ? categoryValues.DeEsser !== 'prompt' ? categoryValues.DeEsser : "Balanced" : "Balanced",
                "True Peak": isSubscribed ? categoryValues.TruePeak !== 'prompt' ? categoryValues.TruePeak : "Balanced" : "Balanced",
            }
        }];
        if (master30Data) {
            dispatch(master30Track(master30Data))
            const data = master30Data?.[0] || {};
            posthog?.capture('Revision Requested', {
                prompt: data.prompt,
                revision: data.revize,
                track: data.track,
                ...data.options,
            })
        }
        return () => {
            if (timeoutId_crwo) {
                clearTimeout(timeoutId_crwo);
            }
        };
    }


    const [promptValue, setPromptValue] = useState(null);
    const suggestions = [t('Suggestion_1'), t('Suggestion_2'), t('Suggestion_3'), t('Suggestion_4'), t('Suggestion_5'), t('Suggestion_6'), t('Suggestion_7'), t('Suggestion_8'), t('Suggestion_9'), t('Suggestion_10'), t('Suggestion_11'), t('Suggestion_12'), t('Suggestion_13') ];
    const getRandomSuggestion = () => {
        const randomIndex = Math.floor(Math.random() * suggestions.length);
        return suggestions[randomIndex];
    }
    const handleRandomSuggestionClick = () => {
        const randomSuggestion = getRandomSuggestion();
        handleSuggestionClick(randomSuggestion);
    }
    const handleSuggestionClick = (suggestionText) => {
        document.getElementById('prompt-input').value = suggestionText;
    }
    const createPromptRevision = () => {
        pauseAllTracks();
        const inputValue = document.getElementById('prompt-input').value.trim();
        setPromptValue(inputValue);
    }
    // if promptValue is not null create revision
    useEffect(() => {
        let timeoutId_ipvnn = null;
        if (promptValue === null || promptValue === undefined) {
            return;
        }
        if ((promptValue === '') || (promptValue.length < 5 || promptValue.length > 240) ) {
            setNotificationTitle(t('Prompt Size'));
            setNotificationTime(t('warning'));
            setNotificationDesc(t('Please enter a prompt between 5 and 240 characters'));
            setNotificationType("warning");
            setShowNotification(true);
            timeoutId_ipvnn = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return;
        }
        const currentLastKey = parseInt(trackList[trackList.length - 1].key, 10);
        if (currentLastKey >= revisionLimit) {
            setNotificationTitle(t('Limit reached'));
            setNotificationTime(t('warning'));
            if (!userAccDetails?.is_active_subscriber) {
                setNotificationDesc(t('You have reached the revision limit defined for your account, whic is {{revisionLimit}}, You should get Suite Membership to do new revisions for this project', { revisionLimit: revisionLimit - 1 }));
                handlePayWallModalShow()
            } else {
                setNotificationDesc(t('You have reached the revision limit defined for your account, whic is {{revisionLimit}}', { revisionLimit: revisionLimit - 1 }));
            }
            setNotificationType("warning");
            setShowNotification(true);
            timeoutId_ipvnn = setTimeout(() => {
                setShowNotification(false);
            }, 5000);
            return;
        }
        if (!MasteringEnabled) {
            setMasteringEnabled(true)
            onMasteringChange(true);
        }
        const master30Data = [{
            prompt: true,
            revize: true,
            track: musicDetails.musicianfiles_30[0].id,
            prompt_input: promptValue,
        }];
        if (master30Data) {
            dispatch(master30Track(master30Data))
            document.getElementById('prompt-input').value = '';
        }
        /* handlePromptModalClose(); */
        return () => {
            if (timeoutId_ipvnn) {
                clearTimeout(timeoutId_ipvnn);
            }
        };
    }, [promptValue]);



    const master30TrackReducer = useSelector(state => state.master30TrackReducer)
    const { music: mastered30Track, loading: master30TrackLoading, success: master30TrackSuccess, error: master30TrackError } = master30TrackReducer
    // get mastered30 response and start masteredstatus
    useEffect(() => {
        if (master30TrackLoading) {
            localStorage.setItem('Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA', '0NArODpbEWEqNZQqPehSWQ');
            setNotificationTitle(t('Revisioning'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is proccessing'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (master30TrackSuccess) {
            if (mastered30Track) {
                if (mastered30Track?.task_id) {
                    dispatch(master30Status(mastered30Track.task_id))
                }
            }
        } else if (master30TrackError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [master30TrackLoading, master30TrackSuccess, master30TrackError]);



    const [master30StatusReallySuccess, setmaster30StatusReallySuccess] = useState(false);
    const master30StatusReducer = useSelector(state => state.master30StatusReducer)
    const { status: master30CurrentStatus, loading: master30StatusLoading, success: master30StatusSuccess, error: master30StatusError } = master30StatusReducer
    const [assistantTextShow, setAssistantTextShow] = useState(false);
    const [assistantTextValue, setAssistantTextValue] = useState(null);
    // check revision master30 status and update track list
    useEffect(() => {
        let timeoutId_crmsutl = null;
        if (master30StatusLoading) {
            setShowNotification(true);
        } else if (master30StatusSuccess) {
            if (master30CurrentStatus?.status === "processed") {
                setmaster30StatusReallySuccess(true);
                if (revisionFooterButton) {
                    revisionFooterButton.classList.remove('bouncee-btn');
                }
                if (revisionMobileButton) {
                    revisionMobileButton.classList.remove('bouncee-btn');
                }
                if (master30CurrentStatus) {
                    const newRevision = {
                        key: (currentLastKey + 1).toString(),
                        id: master30CurrentStatus.mastered_file_id,
                        type: 'mastered',
                        name: promptValue != null ? `${promptValue}` : `${RevisedVersionText} ${currentLastKey + 1}`,
                        url: master30CurrentStatus.presigned_url,
                        classes: promptValue != null ? ['prompted-track'] : ['revised-track'],
                        conversationCleaning: promptValue != null ? 'prompt' : categoryValues.ConversationCleaning !== 'prompt' ? categoryValues.ConversationCleaning : "Balanced",
                        analogWarmth: promptValue != null ? 'prompt' : categoryValues.AnalogWarmth !== 'prompt' ? categoryValues.AnalogWarmth : "Balanced",
                        saturation: promptValue != null ? 'prompt' : categoryValues.Saturation !== 'prompt' ? categoryValues.Saturation : "Balanced",
                        loudness: promptValue != null ? 'prompt' : categoryValues.Loudness !== 'prompt' ? categoryValues.Loudness : "Balanced",
                        stereoWidth: promptValue != null ? 'prompt' : categoryValues.StereoWidth !== 'prompt' ? categoryValues.StereoWidth : "Balanced",
                        compressorStyle: promptValue != null ? 'prompt' : categoryValues.CompressorStyle !== 'prompt' ? categoryValues.CompressorStyle : "Balanced",
                        monoCapability: promptValue != null ? 'prompt' : categoryValues.MonoCapability !== 'prompt' ? categoryValues.MonoCapability : "Balanced",
                        deesser: promptValue != null ? 'prompt' : categoryValues.DeEsser !== 'prompt' ? categoryValues.DeEsser : "Balanced",
                        truePeak: promptValue != null ? 'prompt' : categoryValues.TruePeak !== 'prompt' ? categoryValues.TruePeak : "Balanced",
                    };
                    setTrackList(prevTracks => [...prevTracks, newRevision]);
                    setPromptValue(null);
                    setTimeout(() => {
                        scrollToBottom();
                        centerPanelButton();
                        if (mastered30Track?.dikta_assistant !== null && mastered30Track?.dikta_assistant !== undefined && mastered30Track?.dikta_assistant !== "") {
                            setAssistantTextValue(mastered30Track?.dikta_assistant);
                            setAssistantTextShow(true);
                        }
                    }, 500);
                }
                dispatch({
                    type: MASTER30_TRACK_RESET
                })
                dispatch({
                    type: MASTER30_STATUS_RESET
                })
                setShowNotification(false);
            } else if (master30CurrentStatus?.status === "processing" || master30CurrentStatus?.status === "pending") {
                timeoutId_crmsutl = setTimeout(() => {
                    if (mastered30Track?.task_id) {
                        dispatch(master30Status(mastered30Track.task_id))
                    }
                }, 5000);
            } else if (master30CurrentStatus?.status === "timeout" || master30CurrentStatus?.status === "failed") {
                setNotificationTitle(t('ERROR'));
                setNotificationTime(t('Try later'));
                setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
                setNotificationType("danger");
                setShowNotification(true);
                timeoutId_crmsutl = setTimeout(() => {
                    RedirectToMyTracksPage();
                }, 5000);
            }
        } else if (master30StatusError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(master30StatusError);
            setNotificationType("danger");
            setShowNotification(true);
        }
        return () => {
            if (timeoutId_crmsutl) {
                clearTimeout(timeoutId_crmsutl);
            }
        };
    }, [master30StatusLoading, master30StatusSuccess, master30StatusError]);



    const startToMastering = () => {
        if (userAccDetails?.credits < 1) {
            handlePayWallModalShow();
        } else {
            handleMasteringModalShow();
        }
    }
    const RedirectToCreditsPage = () => {
        navigate("/credits-subscriptions")
    }



    const masterMainTrackReducer = useSelector(state => state.masterMainTrackReducer)
    const { music: masteredMainTrack, loading: masterMainTrackLoading, success: masterMainTrackSuccess, error: masterMainTrackError } = masterMainTrackReducer
    const MasterMainTrackAction = () => {
        if (trackList[activeItemIndex].classes[0] === 'prompted-track') {
            const masterMainData = [{
                prompt: true,
                revize: false,
                track: musicDetails.musicianfile.id,
                mastered_track_30_id: trackList[activeItemIndex].id,
                prompt_input: trackList[activeItemIndex].name.replace("Prompt: ", ""),
            }];
            if (masterMainData) {
                dispatch(masterMainTrack(masterMainData))
                posthog?.capture('Mastering Requested', {
                    prompt: masterMainData[0].prompt,
                    revision: masterMainData[0].revize,
                    track: masterMainData[0].track,
                    prompt_input: masterMainData[0].prompt_input,
                })
            }
        } else {
            const masterMainData = [{
                prompt: false,
                revize: false,
                track: musicDetails.musicianfile.id,
                mastered_track_30_id: trackList[activeItemIndex].id,
                options: {
                    "Conversation Cleaning": categoryValues.ConversationCleaning,
                    "Analog Warmth": trackList[activeItemIndex].analogWarmth,
                    "Saturation": trackList[activeItemIndex].saturation,
                    "Loudness": trackList[activeItemIndex].loudness,
                    "Stereo Width": trackList[activeItemIndex].stereoWidth,
                    "Compressor Style": trackList[activeItemIndex].compressorStyle,
                    "Mono Capability": trackList[activeItemIndex].monoCapability,
                    "De-Esser": trackList[activeItemIndex].deesser,
                    "True Peak": trackList[activeItemIndex].truePeak,
                }
            }];
            if (masterMainData) {
                dispatch(masterMainTrack(masterMainData))
                posthog?.capture('Mastering Requested', {
                    prompt: masterMainData[0].prompt,
                    revision: masterMainData[0].revize,
                    track: masterMainData[0].track,
                    ...masterMainData[0].options,
                })
            }
        }
    }
    // get main master response and start main master status
    useEffect(() => {
        if (masterMainTrackLoading) {
            localStorage.setItem('Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA', '0NArODpbEWEqNZQqPehSWQ');
            setNotificationTitle(t('Mastering'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is proccessing'));
            setNotificationType("dark");
            setShowNotification(true);
        } if (masterMainTrackSuccess) {
            dispatch(mainmasterStatus(masteredMainTrack.task_id))
        } else if (masterMainTrackError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [masterMainTrackLoading, masterMainTrackSuccess, masterMainTrackError]);



    const [mainMasterStatusReallySuccess, setmainMasterStatusReallySuccess] = useState(false);
    const mainMasterStatusReducer = useSelector(state => state.masterMainStatusReducer)
    const { status: mainMasterCurrentStatus, loading: mainMasterStatusLoading, success: mainMasterStatusSuccess, error: mainMasterStatusError } = mainMasterStatusReducer
    // check main master status
    useEffect(() => {
        let timeoutId_cmms = null;
        if (mainMasterStatusLoading) {
            setNotificationTitle(t('Mastering'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('You will be notified you by e-mail when it is finished'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (mainMasterStatusSuccess) {
            if (mainMasterCurrentStatus?.status === "processed") {
                setmainMasterStatusReallySuccess(true);
                if (mainMasterCurrentStatus) {
                    setShowNotification(false);
                    RedirectToMyTracksPage();
                }
                dispatch({
                    type: MAINMASTER_STATUS_RESET
                })
            } else if (mainMasterCurrentStatus?.status === "processing" || mainMasterCurrentStatus?.status === "pending") {
                timeoutId_cmms = setTimeout(() => {
                    dispatch(mainmasterStatus(masteredMainTrack.task_id))
                }, 5000);
            } else if (mainMasterCurrentStatus?.status === "timeout" || mainMasterCurrentStatus?.status === "failed") {
                setNotificationTitle(t('ERROR'));
                setNotificationTime(t('Try later'));
                setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
                setNotificationType("danger");
                setShowNotification(true);
                timeoutId_cmms = setTimeout(() => {
                    RedirectToMyTracksPage();
                }, 5000);
            }
        } else if (mainMasterStatusError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(mainMasterStatusError);
            setNotificationType("danger");
            setShowNotification(true);
        }
        return () => {
            if (timeoutId_cmms) {
                clearTimeout(timeoutId_cmms);
            }
        };
    }, [mainMasterStatusLoading, mainMasterStatusSuccess, mainMasterStatusError]);



    const RedirectToMyTracksPage = () => {
        if ((mainMasterCurrentStatus !== null && mainMasterCurrentStatus !== undefined)) {
            dispatch({
                type: MASTERMAIN_TRACK_RESET
            })
            navigate("/my-tracks", { replace: true })
        }
    }



    const waveSurferPlayerRef0 = useRef([null]);
    const waveSurferPlayerRef1 = useRef([null]);
    const waveSurferPlayerRef2 = useRef([null]);
    const waveSurferPlayerRef3 = useRef([null]);
    const waveSurferPlayerRef4 = useRef([null]);
    const waveSurferPlayerRef5 = useRef([null]);
    const waveSurferPlayerRef6 = useRef([null]);
    const waveSurferPlayerRef7 = useRef([null]);
    const waveSurferPlayerRef8 = useRef([null]);
    const waveSurferPlayerRef9 = useRef([null]);
    const waveSurferPlayerRef10 = useRef([null]);
    const waveSurferPlayerRef11 = useRef([null]);

    const startTime = 0.01;
    const [currentTime, setCurrentTime] = useState(0);
    const [playStatus, setPlayStatus] = useState(false);
    const [regionStart, setRegionStart] = useState(0);
    const [trackDuration, setTrackDuration] = useState(0);
    const [bgCoverImage, setBgCoverImage] = useState(1);



    const handleCurrentTimeUpdate = (time) => {
        setCurrentTime(time);
    };
    const handlePlayStatusUpdate = (status) => {
        setPlayStatus(status);
    };
    const handleRegionStart = (time) => {
        setRegionStart(time);
    };
    const handleTrackDuration = (duration) => {
        setTrackDuration(duration);
    };
    const waveSurferPlayerRefs = [
        waveSurferPlayerRef0,
        waveSurferPlayerRef1,
        waveSurferPlayerRef2,
        waveSurferPlayerRef3,
        waveSurferPlayerRef4,
        waveSurferPlayerRef5,
        waveSurferPlayerRef6,
        waveSurferPlayerRef7,
        waveSurferPlayerRef8,
        waveSurferPlayerRef9,
        waveSurferPlayerRef10,
        waveSurferPlayerRef11
    ];
    const handlePlayClick = () => {
        //waveSurferPlayerRef1.current.play();
        const activeWaveSurferPlayerRef = waveSurferPlayerRefs[activeItemIndex].current;
        if (activeWaveSurferPlayerRef && activeWaveSurferPlayerRef.play) {
            activeWaveSurferPlayerRef.play();
        }
    };
    const handlePauseClick = () => {
        //waveSurferPlayerRef1.current.pause();
        const activeWaveSurferPlayerRef = waveSurferPlayerRefs[activeItemIndex].current;
        if (activeWaveSurferPlayerRef && activeWaveSurferPlayerRef.pause) {
            activeWaveSurferPlayerRef.pause();
        }
    };
    const handleStartFrom = () => {
        waveSurferPlayerRef1.current.setTime(currentTime);
    };
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === "Space" && !['INPUT', 'TEXTAREA', 'SELECT'].includes(event.target.tagName) && !event.target.isContentEditable) {
                event.preventDefault();
                if (!playStatus) {
                    handlePlayClick();
                } else {
                    handlePauseClick();
                }
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [playStatus, handlePlayClick, handlePauseClick]);
    


    const [thereIsNoUrl, setThereIsNoUrl] = useState(false);
    const [activeItemIndex, setActiveItemIndex] = useState(1);
    const handleItemClick = (index) => {
        let timeoutId_hic = null;
        setActiveItemIndex(index);
        onRevisionChange(index);
        if (revisionFooterButton) {
            revisionFooterButton.classList.remove('bouncee-btn');
        }
        if (revisionMobileButton) {
            revisionMobileButton.classList.remove('bouncee-btn');
        }
        setAssistantTextShow(false);
        setAssistantTextValue(null);
        if (trackList[index] && trackList[index].url === null) {
            setThereIsNoUrl(true);
            timeoutId_hic = setTimeout(() => {
                dispatch(musicianTrackDetails(location.state?.source_track_id));
            }, 5000);
            setNotificationTitle(t('Revisioning'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is proccessing'));
            setNotificationType("dark");
            setShowNotification(true);
        } else {
            setThereIsNoUrl(false);
            setShowNotification(false);
            setmaster30StatusReallySuccess(true);
        }
        return () => {
            if (timeoutId_hic) {
                clearTimeout(timeoutId_hic);
            }
        };
    }



    const pauseAllTracks = useCallback(() => {
        const waveSurferPlayerRefCurrent = (activeItemIndex === 1) ? waveSurferPlayerRef1.current :
            (activeItemIndex === 2) ? waveSurferPlayerRef2.current :
                (activeItemIndex === 3) ? waveSurferPlayerRef3.current :
                    (activeItemIndex === 4) ? waveSurferPlayerRef4.current :
                        (activeItemIndex === 5) ? waveSurferPlayerRef5.current :
                            (activeItemIndex === 6) ? waveSurferPlayerRef6.current :
                                (activeItemIndex === 7) ? waveSurferPlayerRef7.current :
                                    (activeItemIndex === 8) ? waveSurferPlayerRef8.current :
                                        (activeItemIndex === 9) ? waveSurferPlayerRef9.current :
                                            (activeItemIndex === 10) ? waveSurferPlayerRef10.current : waveSurferPlayerRef11.current;
        waveSurferPlayerRefCurrent.pause();
        waveSurferPlayerRef0.current.pause();
    }, [activeItemIndex])
    const onMasteringChange = useCallback((enabled) => {
        const trackPlayer = document.querySelector(`.track-player-${activeItemIndex}`);
        const waveSurferPlayerRefCurrent = (activeItemIndex === 1) ? waveSurferPlayerRef1.current :
            (activeItemIndex === 2) ? waveSurferPlayerRef2.current :
                (activeItemIndex === 3) ? waveSurferPlayerRef3.current :
                    (activeItemIndex === 4) ? waveSurferPlayerRef4.current :
                        (activeItemIndex === 5) ? waveSurferPlayerRef5.current :
                            (activeItemIndex === 6) ? waveSurferPlayerRef6.current :
                                (activeItemIndex === 7) ? waveSurferPlayerRef7.current :
                                    (activeItemIndex === 8) ? waveSurferPlayerRef8.current :
                                        (activeItemIndex === 9) ? waveSurferPlayerRef9.current :
                                            (activeItemIndex === 10) ? waveSurferPlayerRef10.current : waveSurferPlayerRef11.current;
        const trackPlayerOriginal = document.querySelector('.original-track');
        const footerList = document.querySelector('.cover-footer');
        let latestPlayerStatus = false;
        if (playStatus) {
            latestPlayerStatus = true;
        }
        if (enabled) {
            setBgCoverImage((activeItemIndex === 1) ? 1 : 2);
            footerList.classList.remove('cf-footer-passive');
            trackPlayerOriginal.classList.toggle('active');
            trackPlayer.classList.toggle('active');
            waveSurferPlayerRefCurrent.setTime(currentTime);
            if (latestPlayerStatus) {
                pauseAllTracks();
                waveSurferPlayerRefCurrent.play();
            }
        } else {
            setBgCoverImage(0);
            footerList.classList.add('cf-footer-passive');
            trackPlayerOriginal.classList.toggle('active');
            trackPlayer.classList.toggle('active');
            waveSurferPlayerRef0.current.setTime(currentTime);
            if (latestPlayerStatus) {
                pauseAllTracks();
                waveSurferPlayerRef0.current.play();
            }
        }
    }, [playStatus, currentTime, activeItemIndex, pauseAllTracks])
    const onRevisionChange = useCallback((index) => {
        setBgCoverImage((index === 1) ? 1 : 2);
        const waveSurferPlayerRefCurrent = (index === 1) ? waveSurferPlayerRef1.current :
            (index === 2) ? waveSurferPlayerRef2.current :
                (index === 3) ? waveSurferPlayerRef3.current :
                    (index === 4) ? waveSurferPlayerRef4.current :
                        (index === 5) ? waveSurferPlayerRef5.current :
                            (index === 6) ? waveSurferPlayerRef6.current :
                                (index === 7) ? waveSurferPlayerRef7.current :
                                    (index === 8) ? waveSurferPlayerRef8.current :
                                        (index === 9) ? waveSurferPlayerRef9.current :
                                            (index === 10) ? waveSurferPlayerRef10.current : waveSurferPlayerRef11.current;
        let latestPlayerStatus = false;
        if (playStatus) {
            latestPlayerStatus = true;
        }
        if (waveSurferPlayerRefCurrent.length === undefined && waveSurferPlayerRefCurrent !== null && waveSurferPlayerRefCurrent !== undefined) {
            waveSurferPlayerRefCurrent.setTime(currentTime);
        }
        if (latestPlayerStatus) {
            pauseAllTracks();
            waveSurferPlayerRefCurrent.play();
        }
        if (index !== 0 && trackList.length > index && !trackList[index].name.startsWith("Prompt")) {
            changeRadioSelections("ConversationCleaning", trackList[index].conversationCleaning);
            changeRadioSelections("AnalogWarmth", trackList[index].analogWarmth);
            changeRadioSelections("Saturation", trackList[index].saturation);
            changeRadioSelections("Loudness", trackList[index].loudness);
            changeRadioSelections("StereoWidth", trackList[index].stereoWidth);
            changeRadioSelections("CompressorStyle", trackList[index].compressorStyle);
            changeRadioSelections("MonoCapability", trackList[index].monoCapability);
            changeRadioSelections("DeEsser", trackList[index].deesser);
            changeRadioSelections("TruePeak", trackList[index].truePeak);
        }
    }, [playStatus, currentTime, pauseAllTracks])


    const [isMobile, setIsMobile] = useState(false);
    const [barWidth, setBarWidth] = useState(6);
    const [barGap, setBarGap] = useState(12);
    const [waveHeight, setWaveHeight] = useState(150);
    const updateBarProps = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 767) {
            setIsMobile(true);
            setWaveHeight(70);
            setBarWidth(2);
            setBarGap(4);
        } else {
            setIsMobile(false);
            setWaveHeight(120);
            setBarWidth(6);
            setBarGap(12);
        }
    };
    useEffect(() => {
        updateBarProps();
        window.addEventListener('resize', updateBarProps);
        return () => {
            window.removeEventListener('resize', updateBarProps);
        };
    }, []);
    const waveSurferPlayers = trackList.map((track, index) => {
        const isActive = activeItemIndex === index;
        const key = `${track.id}-${track.url}`;
        return (
            <div
                key={key}
                className={`track-player track-player-${track.key} ${track.classes.join(' ')} ${isActive ? 'active' : ''}`}
            >
                <WaveSurferPlayer
                    ref={
                        track.key === '0' ? waveSurferPlayerRef0 :
                            track.key === '1' ? waveSurferPlayerRef1 :
                                track.key === '2' ? waveSurferPlayerRef2 :
                                    track.key === '3' ? waveSurferPlayerRef3 :
                                        track.key === '4' ? waveSurferPlayerRef4 :
                                            track.key === '5' ? waveSurferPlayerRef5 :
                                                track.key === '6' ? waveSurferPlayerRef6 :
                                                    track.key === '7' ? waveSurferPlayerRef7 :
                                                        track.key === '8' ? waveSurferPlayerRef8 :
                                                            track.key === '9' ? waveSurferPlayerRef9 :
                                                                track.key === '10' ? waveSurferPlayerRef10 : waveSurferPlayerRef11
                    }
                    height={waveHeight}
                    waveColor="rgb(100, 100, 100)"
                    progressColor="rgb(250, 250, 250)"
                    normalize={true}
                    url={track.url}
                    barWidth={barWidth}
                    barGap={barGap}
                    barRadius={10}
                    barHeight={0.9}
                    cursorWidth={1}
                    interact={true}
                    hideScrollbar={true}
                    autoScroll={true}
                    autoCenter={true}
                    autoplay={false}
                    startTime={startTime}
                    dragSelection={false}
                    onCurrentTimeUpdate={handleCurrentTimeUpdate}
                    onPlayStatusUpdate={handlePlayStatusUpdate}
                    onRegionStartUpdate={handleRegionStart}
                    onTrackDuration={handleTrackDuration}
                    plugins={[Timeline.create({
                        height: 10,
                        timeInterval: 5,
                        primaryLabelInterval: 10,
                        style: {
                            fontSize: '8px',
                            color: 'rgb(100, 100, 100)',
                        },
                    })]}
                />
            </div>
        );
    });



    useEffect(() => {
        if (trackList.length > 2 && waveSurferPlayers.length > 2) {
            handleItemClick(trackList.length - 1);
        }
    }, [trackList]);



    const accordionItems = document.querySelectorAll('.nsbscrbd.accordion .accordion-item');
    useEffect(() => {
        if (accordionItems.length === 0) {
            return;
        }
        const itemsToHandle = Array.from(accordionItems).slice(2);
        itemsToHandle.forEach(item => {
            const collapseElement = item.querySelector('.accordion-collapse');
            if (collapseElement) {
                collapseElement.addEventListener('click', handleCollapseClick);
            }
        });
        return () => {
            itemsToHandle.forEach(item => {
                const collapseElement = item.querySelector('.accordion-collapse');
                if (collapseElement) {
                    collapseElement.removeEventListener('click', handleCollapseClick);
                }
            });
        };
    }, [accordionItems]);
    useEffect(() => {
        if (accordionItems.length === 0) {
            return;
        }
        const itemsToHandle = Array.from(accordionItems).slice(2);
        itemsToHandle.forEach(item => {
            const collapseElement = item.querySelector('.suite-badge');
            if (collapseElement) {
                collapseElement.addEventListener('click', handleCollapseClick);
            }
        });
        return () => {
            itemsToHandle.forEach(item => {
                const collapseElement = item.querySelector('.suite-badge');
                if (collapseElement) {
                    collapseElement.removeEventListener('click', handleCollapseClick);
                }
            });
        };
    }, [accordionItems]);
    const handleCollapseClick = () => {
        //RedirectToCreditsPage();
        handlePayWallModalShow();
    };


    // mobile navigation buttons
    const [isMobileLeftPanelActive, setisMobileLeftPanelActive] = useState(false);
    const [isCenterPanelActive, setisCenterPanelActive] = useState(true);
    const [isMobileRightPanelActive, setisMobileRightPanelActive] = useState(false);
    const leftPanelButton = () => {
        const leftPanel = document.querySelector('.mastering-left-panel');
        const rightPanel = document.querySelector('.mastering-right-panel');
        if (isMobileLeftPanelActive) {
            centerPanelButton();
        } else {
            leftPanel.classList.toggle('mastering-left-panel-mobile-show');
            rightPanel.classList.remove('mastering-right-panel-mobile-show');
            setisMobileLeftPanelActive(!isMobileLeftPanelActive);
            setisCenterPanelActive(false);
            setisMobileRightPanelActive(false);
        }
    };
    const centerPanelButton = () => {
        const leftPanel = document.querySelector('.mastering-left-panel');
        const rightPanel = document.querySelector('.mastering-right-panel');
        leftPanel.classList.remove('mastering-left-panel-mobile-show');
        rightPanel.classList.remove('mastering-right-panel-mobile-show');
        setisMobileLeftPanelActive(false);
        setisCenterPanelActive(true);
        setisMobileRightPanelActive(false);
    };
    const rightPanelButton = () => {
        const leftPanel = document.querySelector('.mastering-left-panel');
        const rightPanel = document.querySelector('.mastering-right-panel');
        if (isMobileRightPanelActive) {
            centerPanelButton();
        } else {
            leftPanel.classList.remove('mastering-left-panel-mobile-show');
            rightPanel.classList.toggle('mastering-right-panel-mobile-show');
            setisMobileLeftPanelActive(false);
            setisCenterPanelActive(false);
            setisMobileRightPanelActive(!isMobileRightPanelActive);
        }
    };



    /* const asistanText = () => {
        if (assistantTextShow) {
            setAssistantTextShow(false);
            setAssistantTextValue(null);
        } else {
            setAssistantTextValue("I've adjusted the EQ to cut the highs as you requested. Your electronic track is now balanced and ready to shine! I've adjusted the EQ to cut the highs as you requested. Your electronic track is now balanced and ready to shine! I've adjusted the EQ");
            setAssistantTextShow(true);
        }
    } */


    return (
        <>

            {
                location.state == null &&
                <div className='fullPageWarning fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'>
                    <span className="align-self-center">
                        {t('You can not directly access this page')}
                    </span>
                </div>
            }

            {
                (
                    master30TrackLoading || master30TrackSuccess || master30StatusLoading || (master30StatusSuccess && !master30StatusReallySuccess)
                    ||
                    masterMainTrackLoading || masterMainTrackSuccess || mainMasterStatusLoading || (mainMasterStatusSuccess && !mainMasterStatusReallySuccess)
                    ||
                    thereIsNoUrl
                )
                &&
                <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'>
                    <span className="align-self-center">
                        <Spinner animation="border" />
                    </span>
                    <QuotesComponent />
                </div>
            }


            {
                (/* musicDetails != {} &&  */trackList.length > 0) &&
                <div className={`mastering-page d-flex justify-content-center mpc-background-${bgCoverImage}`}>
                    <Row className="w-100 d-flex justify-content-center align-self-stretch mastering-page-row">

                        <Col className="mastering-panels mastering-left-panel align-self-stretch">
                            <div className="mastering-left-panel-header">
                                <div className="mlph-content d-flex justify-content-between">
                                    <div className="mlphc-text">{t('Details')}</div>
                                    <div className="mlphc-icon">
                                        {
                                            isLeftPanelActive ? <img src='/icons/left.svg' alt='left-menu' className='icon' onClick={changeLeftPanelStatus} />
                                                : <img src='/icons/details.svg' alt='left-menu' className='icon' onClick={changeLeftPanelStatus} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="mastering-left-panel-content">
                                <div className="mastering-left-panel-content-inner">
                                    <Accordion defaultActiveKey={allCategories} alwaysOpen className={isSubscribed ? "sbscrbdy" : "nsbscrbd"}>
                                        {
                                            isPodcast === true ?
                                                <>
                                                    <AccordionComponent
                                                        category="ConversationCleaning"
                                                        icon="Loudness"
                                                        vertical={true}
                                                        radiosState={radiosState}
                                                        changeRadioSelections={changeRadioSelections} />
                                                </>
                                                :
                                                <>
                                                    <AccordionComponent category="AnalogWarmth" icon="Analog-Warmth" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="Saturation" icon="Saturation" vertical={true} radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="Loudness" icon="Loudness" vertical={true} radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="StereoWidth" icon="Stereo-Width" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="CompressorStyle" icon="Compressor-Style" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="MonoCapability" icon="Mono-Capability" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="DeEsser" icon="DeEsser" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                    <AccordionComponent category="TruePeak" icon="TruePeak" radiosState={radiosState} changeRadioSelections={changeRadioSelections} />
                                                </>
                                        }

                                    </Accordion>

                                </div>
                            </div>
                            <div className='mastering-footer d-flex flex-column justify-content-center'>
                                <Button variant="dark" className='w-100 mt-2 mb-1 revision-footer-button' onClick={createRevision}>{t('Create New Revision')}</Button>
                            </div>
                        </Col>

                        <Col className={`mastering-page-container align-self-stretch`}>
                            <div className='cover-fg'>
                                <div className='prompt-to-master-wrapper'>
                                    <div className='prompt-to-master-container'>
                                        <Button className='ptm-random-suggestion' onClick={handleRandomSuggestionClick}><img src='/icons/suggestions.svg' alt='Suggestions' className='icon' /> </Button>
                                        <div className='ptm-divider'></div>
                                        <Form.Control id="prompt-input" size="lg" className="ptm-input" placeholder={t('Feeling wet under the rain')} maxLength="240"/>
                                        <Button className='ptm-apply-btn' onClick={createPromptRevision}><img src='/icons/send.svg' alt='Suggestions' className='icon m-icon' /><span>{t('Apply Prompt')}</span></Button>
                                    </div>
                                </div>
                                <Row className='revision-title'>
                                    <Col>
                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                            <>
                                                {
                                                    (assistantTextShow && assistantTextValue && MasteringEnabled && !isMobile) ?
                                                        <>
                                                            <div className="assistan-prompted-wrapper">
                                                                <div className="assistan-prompted">{trackList[activeItemIndex].name}</div>
                                                                <div className={`${(userAccDetails?.is_active_subscriber ? 'pro-user assistant-prompted-icon' : 'assistant-prompted-icon')}`}>{userInfo?.email[0]}</div>
                                                            </div>
                                                            <TypingText text={assistantTextValue} />
                                                        </>
                                                    :
                                                        <div className="version-title">{!MasteringEnabled ? trackList[0].name : trackList[activeItemIndex].name}</div>
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row className='revision-soundwave'>
                                    <Col className='track-player-container-col'>

                                        <div className='track-player-container'>
                                            {waveSurferPlayers}
                                        </div>

                                        <div className='d-none'>
                                            <Button onClick={handlePlayClick}>Play</Button>
                                            <Button onClick={handlePauseClick}>Pause</Button>
                                            <Button onClick={handleStartFrom}>Start from</Button>
                                            <p>Current Time: {currentTime.toFixed(1)} seconds</p>
                                            <p>Play/Puse Status: {playStatus ? 'now playing' : 'stopped'}</p>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center mastering-radios'>
                                                <Form.Check
                                                    type="switch"
                                                    id="mastering-enabled-switch"
                                                    label={t('Mastering Enabled')}
                                                    className="lg-switch"
                                                    onChange={(e) => {
                                                        const isEnabled = e.target.checked;
                                                        setMasteringEnabled(isEnabled);
                                                        onMasteringChange(isEnabled);
                                                        setAssistantTextShow(false);
                                                        setAssistantTextValue(null);
                                                    }}
                                                    checked={MasteringEnabled}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="mono-check-switch"
                                                    label={t('Mono Check')}
                                                    className="lg-switch d-none"
                                                    onChange={() => setMonoCheck(!MonoCheck)}
                                                    checked={MonoCheck}
                                                />
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                                    {
                                        (assistantTextShow && assistantTextValue && MasteringEnabled && isMobile) &&
                                        <Row className='assistant-text-mobile'>
                                            <Col>
                                                <TypingText text={assistantTextValue} />
                                            </Col>
                                        </Row> 
                                    }
                            </div>
                            <div className='cover-footer'>
                                <div className='cf-header'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='w-30'>{t('REVISIONS')}</div>
                                        {isPodcast === true ?
                                            <div className='w-70'><img src='/icons/Loudness.svg' alt='Mastering' className='icon' /></div>
                                            :
                                            <>
                                                <div className='w-10'><img src='/icons/Analog-Warmth.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/Saturation.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/Loudness.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/Stereo-Width.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/Compressor-Style.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/Mono-Capability.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/DeEsser.svg' alt='Mastering' className='icon' /></div>
                                                <div className='w-10'><img src='/icons/TruePeak.svg' alt='Mastering' className='icon' /></div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className='cf-container'>
                                    {trackList.map((track, index) => {
                                        if (index === 0) return null;
                                        const isActive = activeItemIndex === index;
                                        return (
                                            <div
                                                key={index}
                                                className={`cf-item d-flex justify-content-between cf-item-${track.key} ${isActive ? 'cf-item-active' : ''}`}
                                                onClick={() => handleItemClick(index)}
                                            >
                                                <div className='w-30 cf-track-name'>{track.name}</div>
                                                {isPodcast === true ?
                                                    <div className='w-70'>{track.conversationCleaning}</div>
                                                    :
                                                    <>
                                                        <div className='w-10'>{track.analogWarmth}</div>
                                                        <div className='w-10'>{track.saturation}</div>
                                                        <div className='w-10'>{track.loudness}</div>
                                                        <div className='w-10'>{track.stereoWidth}</div>
                                                        <div className='w-10'>{track.compressorStyle}</div>
                                                        <div className='w-10'>{track.monoCapability}</div>
                                                        <div className='w-10'>{track.deesser}</div>
                                                        <div className='w-10'>{track.truePeak}</div>
                                                    </>
                                                }
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        </Col>

                        <Col className="mastering-panels mastering-right-panel align-self-stretch">
                            {/* <Button onClick={asistanText}>Test Text</Button> */}
                            <div className="mastering-right-panel-content">
                                <div className="mastering-right-panel-content-inner">
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <div>{t('Track Name')}</div>
                                            <h6>
                                                {
                                                    thereIsNoUrl || !musicDetails?.musicianfile?.track_name
                                                        ? t('LOADING')
                                                        : musicDetails?.musicianfile?.track_name
                                                }
                                            </h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div className='cover-image'>
                                                <img 
                                                    src={thereIsNoUrl || !musicDetails?.musicianfile?.image_presigned_url ? '/icons/mastering-cover-x.png' : musicDetails?.musicianfile?.image_presigned_url} 
                                                    alt='cover' 
                                                    onError={(e) => { e.target.src = '/icons/mastering-cover-x.png'; }}
                                                />
                                            </div>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div>{t('Genres')}</div>
                                            {thereIsNoUrl || !musicDetails?.musicianfile?.genre_mini
                                                ? <h6>{t('LOADING')}</h6>
                                                : musicDetails &&
                                                <>
                                                    <h6>
                                                        <span>
                                                            {musicDetails?.musicianfile?.genre_mini}
                                                            {musicDetails?.musicianfile?.genre_full && <span className='mx-2'>|</span>}
                                                        </span>
                                                        <span>
                                                            {musicDetails?.musicianfile?.genre_full}
                                                            {musicDetails?.musicianfile?.genre_secondary && <span className='mx-2'>|</span>}
                                                        </span>
                                                        <span>
                                                            {musicDetails?.musicianfile?.genre_secondary}
                                                        </span>
                                                    </h6>
                                                </>
                                            }
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <div>{t('Selected')}</div>
                                            <h6>
                                                {trackList.length > 0 && trackList[activeItemIndex] &&
                                                    <strong>{trackList[activeItemIndex].name}</strong>
                                                }
                                            </h6>
                                        </ListGroup.Item>
                                        {isPodcast === true ?
                                            <ListGroup.Item>
                                                <div>Conversation Cleaning</div>
                                                <h6>
                                                    {trackList.length > 0 && trackList[activeItemIndex] &&
                                                        trackList[activeItemIndex].conversationCleaning
                                                    }
                                                </h6>
                                            </ListGroup.Item>
                                            :
                                            <>
                                                <ListGroup.Item>
                                                    <div>Analog Warmth</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].analogWarmth
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>Saturation</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].saturation
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>Loudness</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].loudness
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>Stereo Width</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].stereoWidth
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>Compressor Style</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].compressorStyle
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>Mono Capability</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].monoCapability
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>De Esser</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].deesser
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <div>True Peak</div>
                                                    <h6>
                                                        {trackList.length > 0 && trackList[activeItemIndex] &&
                                                            trackList[activeItemIndex].truePeak
                                                        }
                                                    </h6>
                                                </ListGroup.Item>
                                            </>
                                        }
                                    </ListGroup>
                                </div>
                            </div>
                            <div className='mastering-footer d-flex flex-column justify-content-center'>
                                <small className='mfooter-desc text-center'>
                                    {t('All other revisions will be deleted')}
                                </small>
                                <Button variant="primary" className='w-100 mt-2 mb-1 mastering-footer-button' onClick={startToMastering}>{t('Master track')}</Button>
                            </div>
                        </Col>

                    </Row>

                    <div className='mastering-page-mobile-navigator'>
                        <div className='mpmn-container'>
                            <div className='mpmn-top'>
                                <div className={`mpmnt-content mpmnt-left ${isMobileLeftPanelActive ? "mpmnt-active" : "mpmnt-passive"}`} onClick={leftPanelButton}>
                                    <img src='/icons/revision-suite.svg' alt='Revision Suite' className='icon mx-0 mb-1' />
                                    <span>{t('Revision Suite')}</span>
                                </div>
                                <div className={`mpmnt-content mpmnt-center ${isCenterPanelActive ? "mpmnt-active" : "mpmnt-passive"}`} onClick={centerPanelButton}>
                                    <img src='/icons/ab-test.svg' alt='Prompt A/B Test' className='icon mx-0 mb-1' />
                                    <span>{t('Prompt A/B Test')}</span>
                                </div>
                                <div className={`mpmnt-content mpmnt-right ${isMobileRightPanelActive ? "mpmnt-active" : "mpmnt-passive"}`}  onClick={rightPanelButton}>
                                    <img src='/icons/revision-info.svg' alt='Revision Info' className='icon mx-0 mb-1' />
                                    <span>{t('Revision Info')}</span>
                                </div>
                            </div>
                            <div className='mpmn-bottom'>
                                <Button variant="secondary" className={`w-100 mastering-mobile-button mmb-revision ${isMobileLeftPanelActive ? "mmb-active" : "mmb-passive"}`} onClick={createRevision}>{t('Create New Revision')}</Button>
                                <Button variant="primary" className={`w-100 mastering-mobile-button mmb-master-now ${isMobileLeftPanelActive ? "mmb-passive" : "mmb-active"}`} onClick={startToMastering}>{t('Master track')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <Modal
                show={MasteringModal}
                onHide={handleMasteringModalClose}
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Applying  mastering to whole track')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-5 mb-4 text-center'>
                        <Col>
                            <h3>{t('Are you sure?')}</h3>
                            <p className='mt-3'>{t('We are going to master your track with selected settings')}</p>
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-4'>
                        <Col>
                            <img src='/icons/wave.svg' alt='Mastering' className='icon w-100 icon-wave' />
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center align-items-center my-5'>
                        <div className='px-3'>
                            <Button className="w-100" variant="primary" onClick={MasterMainTrackAction}>{t('Master it')}</Button>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={PayWallModal}
                onHide={handlePayWallModalClose}
                onShow={() => {
                    posthog?.capture('Paywalled While Mastering', {
                        track: musicDetails?.musicianfiles_30?.[0]?.id,
                    })
                }}
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Credit Warning')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='mt-3 mb-5 m-mb-3 text-center'>
                        <Col>
                            <h2 className='mobile-paywall-title'><b>{t('Insufficient Credit')}</b></h2>
                            <p>{t('You do not have sufficient credits to continue the process')}</p>
                        </Col>
                    </Row>
                    <Pricing userAccDetails={userAccDetails ?? userAccDetails} /> 
                </Modal.Body>
            </Modal>


            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )
}
export default MasteringPage;
