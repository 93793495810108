import { combineReducers } from "redux";

import {
    userRegisterReducer,
    mailConfirmationReducer,
    resendMailConfirmationReducer,
    userForgotPasswordReducer,
    passwordConfirmResetReducer,
    userLoginReducer,
    checkTokenValidationReducer,
    userDetailsReducer,
    userDetailsUpdateReducer,
    userPasswordUpdateReducer,
    userDeleteAccountReducer,
    createCheckoutSessionReducer,
    uploadMusicReducer,
    uploadTrackNewReducer,
    s3UploadReducer,
    receiverUploadNewReducer,
    celeryStatusReducer,
    musicianTrackUpdateReducer,
    cropTrackReducer,
    master30TrackReducer,
    master30StatusReducer,
    masterMainTrackReducer,
    masterMainStatusReducer,
    musicianTrackListReducer,
    musicianTrackDetailsReducer,
    musicianTrackQuickReducer,
    originalTrackDeleteReducer,
    masteredTrackDeleteReducer,
} from "./userReducers";

const allReducers = combineReducers({
    userRegisterReducer,
    mailConfirmationReducer,
    resendMailConfirmationReducer,
    userForgotPasswordReducer,
    passwordConfirmResetReducer,
    userLoginReducer,
    checkTokenValidationReducer,
    userDetailsReducer,
    userDetailsUpdateReducer,
    userPasswordUpdateReducer,
    userDeleteAccountReducer,
    createCheckoutSessionReducer,
    uploadMusicReducer,
    uploadTrackNewReducer,
    s3UploadReducer,
    receiverUploadNewReducer,
    celeryStatusReducer,
    musicianTrackUpdateReducer,
    cropTrackReducer,
    master30TrackReducer,
    master30StatusReducer,
    masterMainTrackReducer,
    masterMainStatusReducer,
    musicianTrackListReducer,
    musicianTrackDetailsReducer,
    musicianTrackQuickReducer,
    originalTrackDeleteReducer,
    masteredTrackDeleteReducer,
})


export default allReducers