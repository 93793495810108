import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useLogin } from '../hooks/useLogin';
import Cookies from 'js-cookie';
import { Form, Button, Row, Col, Card, Spinner } from 'react-bootstrap'
import { resendMailConfirmation } from '../actions/userActions'
import Message from '../components/Message'

function MagicLinkPage() {

    useEffect(() => {
        document.title = "Login | Register - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const loginMutation = useLogin();
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [referralMessage, setReferralMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const refCode = searchParams.get('ref');
        if (refCode) {
            Cookies.set('referralCode', refCode, { expires: 365 });
        }
    }, []);

    //const userRegisterReducer = useSelector(state => state.userRegisterReducer)
    //const { loading: registerLoading, success: registerSuccess, error: registerError } = userRegisterReducer
    const submitHandler = (e) => {
        e.preventDefault()
        if (email === "") {
            setMessage(t('Please enter your email'))
        } else {
            const requestData = {
                email: email,
            }
            const referralCode = Cookies.get('referralCode');
            if (referralCode) {
                requestData.reference_code = referralCode
            }
            loginMutation.mutate(requestData);
        }
        //dispatch(register(email, password, referralCode))
    }
    const [resendTime, setResendTime] = useState(30);
    const [allowSend, setAllowSend] = useState(true);
    const [isResend, setIsResend] = useState(false);
    useEffect(() => {
        if (loginMutation?.isSuccess || loginMutation?.isError) {
            setAllowSend(false);
			const timer = setInterval(() => {
				setResendTime(prev => prev - 1);
			}, 1000);
            if (loginMutation?.isError) {
                setMessage(t('Oops Proccess Failed'))
            }
			return () => clearInterval(timer);
        }
    }, [loginMutation?.isSuccess, loginMutation?.isError])
    useEffect(() => {
		if (resendTime === 0) {
			setResendTime(30);
			setAllowSend(true);
			setIsResend(true);
			loginMutation.reset();
            setMessage("");
		}
	}, [resendTime, loginMutation]);

    useEffect(() => {
        const referralCode = Cookies.get('referralCode');
        if (referralCode) {
            setReferralMessage(t('You are registering with a referral code'));
        }
    }, []);

    /* const resendMailConfirmationReducer = useSelector(state => state.resendMailConfirmationReducer)
    const { loading: resendMailConfirmationLoading, success: resendMailConfirmationSuccess, error: resendMailConfirmationError } = resendMailConfirmationReducer
    const resendMailConfirmationHandler = () => {
        dispatch(resendMailConfirmation(email))
    } */

    return (

        <>
            <div className="form-card mt-2 mt-sm-5">

                {
                    loginMutation?.isSuccess ?
                        <Row className='justify-content-md-center'>
                            <Col xs={12}>
                                <div className='d-flex flex-column justify-content-center pt-4'>
                                    <div className='fs-1 mb-4 text-center'><img className="icon payment-success-icon" src="/icons/done.svg" alt="user" /></div>
                                    <div className='fs-6 mb-4 text-center'>{t('Confirmation email has been sent')}</div>
                                    {
                                        !allowSend ? 
                                            <Button className='w-100' disabled="true">{t('Resend in {{resendTime}} seconds', {resendTime: resendTime})}</Button>
                                            :
                                            <Button className='w-100' onClick={submitHandler}>{t('Resend Magic Link')}</Button>
                                    }
                                </div>
                                <div className="form-extra-actions text-center my-3 d-flex justify-content-center">
                                    {t('MagicLinkPageAfterInfo')}
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row className='justify-content-md-center'>
                            <Col xs={12}>
                                <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                                <div className='fs-5 text-center'>{t('Welcome Musician!')}</div>
                                <h2 className='text-center mb-4'>{t('Login')}</h2>
                                {message && <Message variant='danger'>{message}</Message>}
                                {/* referralMessage && <Message variant='info'>{referralMessage}</Message> */}

                                <Form onSubmit={submitHandler}>

                                    <Form.Group controlId='email'>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder={t('enter your email')}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            autoComplete="email"
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    {
                                        loginMutation.isPending ?
                                            <Button variant='primary' className='w-100 my-3'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                                            : !allowSend ? 
                                                <Button className='w-100' disabled="true">{t('Resend in {{resendTime}} seconds', {resendTime: resendTime})}</Button>
                                                :
                                                    <Button type="submit" className='w-100'>{isResend ? t('Resend Magic Link') : t('Send Magic Link')}</Button>
                                    }
                                </Form>

                                <div className="form-extra-actions text-center my-3 d-flex justify-content-center">
                                    {t('MagicLinkPageInfo')}
                                </div>

                            </Col>
                        </Row>
                }

            </div>

            <div className="register-extra-infos mb-3">
                <p className="text-center mb-0 no-wrap m-wrap">
                    {t('signupAgreementBefore')} <Link to={`https://diktatorial.com/terms-of-use`} target='_blank'>{t('Terms of Service')}</Link>{t(' signupAgreementBetween ')}<Link to={`https://diktatorial.com/privacy-policy`} target='_blank'>{t('Privacy Policy')}</Link> {t('signupAgreementAfter')}
                </p>
            </div>
        </>


    )
}

export default MagicLinkPage