import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { getDecryptedCookieValue, setEncryptedUserCookies, clearEncryptedUserCookies } from '../utils';

export default function useUser() {
  const userLoginReducer = useSelector(state => state.userLoginReducer)
  const { userInfo } = userLoginReducer

  const userDetailsReducer = useSelector(state => state.userDetailsReducer)
  const { user: userAccDetails, loading: userAccLoading, error: userAccError } = userDetailsReducer

  useEffect(() => {
    if (userInfo) {
      setEncryptedUserCookies({
        id: userInfo.id || '',
        email: userInfo.email || '',
        access_token: userInfo.access_token || ''
        /* refresh_token: userInfo.refresh_token || '', */
      });
    } else {
      clearEncryptedUserCookies(['_d_i', '_d_e', '_d_a']);
    }
  }, [userInfo]);

  return {
    userInfo,
    userAccDetails,
    userAccLoading,
    userAccError
  }
}