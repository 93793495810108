import axios from 'axios';
import CryptoJS from 'crypto-js';

export const apiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

apiInstance.interceptors.request.use((config) => {
  if (!config.url.endsWith('/')) {
    if (!config.url.includes('verify-email')) {
      config.url += '/';
    }
  }
  return config;
});

//const domain = 'localhost';
const domain = window.location.hostname === 'localhost' ? 'localhost' : '.diktatorial.com';

export const setEncryptedUserCookies = (data) => {
  const yokboylebisey = "inanilmaz_gizli_bir_anahtar";
  const token = data.access_token;
  const [, payload] = token.split('.');
  const decodedPayload = JSON.parse(window.atob(payload));
  const exp = decodedPayload.exp;
  const currentTime = Math.floor(Date.now() / 1000);
  const timeDifference = 3 * 3600;
  const maxAge = exp - currentTime + timeDifference;
  Object.entries(data).forEach(([key, value]) => {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), yokboylebisey).toString();
    const cookieKey = `_d_${key[0]}`;
    const cookieValue = encodeURIComponent(encryptedValue);

    const path = '/';
    document.cookie = `${cookieKey}=${cookieValue};domain=${domain};path=${path};max-age=${maxAge};secure`;
  });
};

export const getDecryptedCookieValue = (cookieKey) => {
  const yokboylebisey = "inanilmaz_gizli_bir_anahtar";
  const encryptedValue = decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${cookieKey}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1"));
  if (!encryptedValue) return null;
  const bytes = CryptoJS.AES.decrypt(encryptedValue, yokboylebisey);
  const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedValue;
};

export const clearEncryptedUserCookies = (cookieKeys) => {
  cookieKeys.forEach(cookieKey => {
    document.cookie = `${cookieKey}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;secure`;
  });
};