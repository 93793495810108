import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";

import i18n from "./i18n";
import MagicLinkPage from "./pages/MagicLinkPage";
import RegisterPage from "./pages/RegisterPage";
import MailConfirmationPage from "./pages/MailConfirmationPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PasswordConfirm from "./pages/PasswordConfirm";
import Login from "./pages/LoginPage";
import UploadPage from "./pages/UploadPage";
import MasteringPage from "./pages/MasteringPage";
import MyTracksPage from "./pages/MyTracksPage";
import AccountPage from "./pages/AccountPage";
import AccountUpdatePage from "./pages/AccountUpdatePage";
import PasswordResetPage from "./pages/PasswordResetPage";
import DeleteUserAccountPage from "./pages/DeleteUserAccountPage";
import NavBar from "./components/Navbar";
import CreditsSubscriptionsPage from "./pages/CreditsSubscriptionsPage";
import OrderSuccessPage from "./pages/OrderSuccessPage";
import OrderErrorPage from "./pages/OrderErrorPage";
import OrderCancelPage from "./pages/OrderCancelPage";
import NotFound from "./pages/NotFoundPage";

const queryClient = new QueryClient();

function TrackPageView() {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    posthog?.capture('$pageview')
  }, [location]);

  return null
}

const App = () => {
  // login reducer
  const userLoginReducer = useSelector((state) => state.userLoginReducer);
  const { userInfo } = userLoginReducer;

  const posthog = usePostHog();

  useEffect(() => {
    if (!posthog) return;
    if (!userInfo) {
      posthog.reset();
      return;
    }

    posthog.identify(`${userInfo.id}`, {
      email: userInfo.email,
      fullName: [userInfo.name, userInfo.surname, userInfo.artist_name].filter(Boolean).join(" "),
      userStatus: userInfo.status,
      isEverPaid: userInfo.is_ever_paid,
      isActiveSubscriber: userInfo.is_active_subscriber,
      activeSubscriptionEndDate: userInfo.active_subscription_end_date,
      latestCredits: userInfo.credits,
      totalUploads: userInfo.total_uploads,
      totalMastered: userInfo.total_mastered,
      totalPrompt: userInfo.total_prompt,
      totalRevisions: userInfo.total_revisions,
      totalDefaultMastered: userInfo.total_default_mastered,
      isTeamMember: userInfo.team_member,
      isMailConfirmed: userInfo.mail_confirmed,
      referencedBy: userInfo.referenced_by || 'direct',
    }, {
      createdAt: userInfo.created_at,
    });
  }, [userInfo, posthog]);

  const [mouseDot, setMouseDot] = useState(null);
  useEffect(() => {
    setMouseDot(document.querySelector(".mouse-dot"));
  }, []);
  let isDotInitialized = false;
  useEffect(() => {
    if (mouseDot) {
      isDotInitialized = true;
    }
  }, [mouseDot]);
  window.addEventListener("mousemove", (e) => {
    if (isDotInitialized) {
      mouseDot.style.visibility = `visible`;
      mouseDot.style.top = `${e.clientY - 36}px`;
      mouseDot.style.left = `${e.clientX - 36}px`;
    }
  });

  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <>
          <div className="main-bg-right-top"></div>

          <div className="mouse-dot"></div>
          <Router>
            <TrackPageView />
            <NavBar />

            <div className="main-content m-px-2">
              {userInfo ? (
                <Routes>
                  <Route path="/" element={<UploadPage />} exact />
                  <Route path="/register" element={<UploadPage />} exact />
                  <Route path="/mail-confirmation/*" element={<MailConfirmationPage />} exact />
                  <Route path="/forgot-password" element={<UploadPage />} exact />
                  <Route path="/password-confirm" element={<UploadPage />} exact />
                  <Route path="/login" element={<UploadPage />} exact />
                  <Route path="/upload-track" element={<UploadPage />} exact />
                  <Route path="/mastering" element={<MasteringPage />} exact />
                  <Route path="/my-tracks" element={<MyTracksPage />} exact />
                  <Route path="/account" element={<AccountPage />} exact />
                  <Route path="/account/update" element={<AccountUpdatePage />} exact />
                  <Route path="/account/password-reset" element={<PasswordResetPage />} exact />
                  <Route path="/account/delete" element={<DeleteUserAccountPage />} exact />
                  <Route path="/credits-subscriptions" element={<CreditsSubscriptionsPage />} exact />
                  <Route path="/order-success/" element={<OrderSuccessPage />} exact />
                  <Route path="/order-error/" element={<OrderErrorPage />} exact />
                  <Route path="/payment-cancelled/" element={<OrderCancelPage />} exact />

                  <Route path="/*" element={<NotFound />} exact />
                </Routes>
              ) : (
                <Routes>
                  <Route path="/" element={<MagicLinkPage />} exact />
                  <Route path="/register" element={<MagicLinkPage />} exact />
                  <Route path="/mail-confirmation/*" element={<MailConfirmationPage />} exact />
                  <Route path="/forgot-password" element={<MagicLinkPage />} exact />
                  <Route path="/password-confirm/*" element={<MagicLinkPage />} exact />
                  <Route path="/login" element={<MagicLinkPage />} exact />
                  <Route path="/upload-track" element={<MagicLinkPage />} exact />
                  <Route path="/mastering" element={<MagicLinkPage />} exact />
                  <Route path="/my-tracks" element={<MagicLinkPage />} exact />
                  <Route path="/account" element={<MagicLinkPage />} exact />
                  <Route path="/account/update/" element={<MagicLinkPage />} exact />
                  <Route path="/account/password-reset/" element={<MagicLinkPage />} exact />
                  <Route path="/account/delete/" element={<MagicLinkPage />} exact />
                  <Route path="/credits-subscriptions/" element={<MagicLinkPage />} exact />
                  <Route path="/order-success/" element={<MagicLinkPage />} exact />
                  <Route path="/order-error/" element={<MagicLinkPage />} exact />
                  <Route path="/payment-cancelled/" element={<MagicLinkPage />} exact />

                  <Route path="/*" element={<NotFound />} exact />
                </Routes>
              )}
            </div>
          </Router>
        </>

        {/* <div className="mobile-warning">
          <div className="mobile-warning-text">
            <div className="mobile-warning-text-1">For the Best Experience, Please Use on Desktop</div>
            <div className="mobile-warning-text-2">mobile-friendly update coming soon!</div>
          </div>
        </div> */}
      </QueryClientProvider>
    </I18nextProvider>
  );
};

export default App;
