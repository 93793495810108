// src/hooks/useAuth.js
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userDetails, logout, checkTokenValidation } from '../actions/userActions'
import useUser from './useUser';
import { use } from 'i18next';

export default function useAuth() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo, userAccDetails, userAccError, userAccLoading } = useUser();

    const checkTokenValidationReducer = useSelector(state => state.checkTokenValidationReducer);
    const { error: tokenError } = checkTokenValidationReducer;

    const [readyForUserDetails, setReadyForUserDetails] = useState(false);
    useEffect(() => {
        if (!userInfo) {
            navigate("/login", { replace: true });
        } else {
            setReadyForUserDetails(true);
        }
    }, [userInfo, navigate]);
    useEffect(() => {
        if (readyForUserDetails) {
            dispatch(checkTokenValidation());
            dispatch(userDetails(userInfo.id));
        }
    }, [readyForUserDetails, dispatch]);


    useEffect(() => {
        if (tokenError) {
            dispatch(logout());
            navigate("/login", { replace: true });
            window.location.reload();
        }
    }, [tokenError, navigate, dispatch]);

    return {
        userInfo,
        userAccDetails,
        userAccLoading,
        userAccError
    }
}
