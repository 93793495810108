import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { userUpdateDetails } from '../actions/userActions'
import Message from '../components/Message'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UPDATE_USER_DETAILS_RESET } from '../constants'
import useAuth from '../hooks/useAuth';


function AccountUpdatePage() {

    useEffect(() => {
        document.title = "Account Update - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userAccDetails, userAccError, userInfo, userAccLoading } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);


    const [artistName, setArtistName] = useState(userAccDetails?.artist_name)
    const [name, setName] = useState(userAccDetails?.name)
    const [surname, setSurname] = useState(userAccDetails?.surname)
    const [message, setMessage] = useState("")
    const [countdown, setCountdown] = useState(5);

    // user update details reducer
    const userDetailsUpdateReducer = useSelector(state => state.userDetailsUpdateReducer)
    const { loading: userDetailsUpdateLoading, success: userDetailsUpdateSuccess, error: userDetailsUpdateError } = userDetailsUpdateReducer
    const onSubmit = (e) => {
        e.preventDefault()

        if (artistName === "") {
            setMessage(t('Artist name cannot be empty!'))
            return
        } else if (name === "") {
            setMessage(t('Name cannot be empty!'))
            return
        } else if (surname === "") {
            setMessage(t('Surname cannot be empty!'))
            return
        }
        dispatch(userUpdateDetails(userInfo.id, artistName, name, surname))
    }
    useEffect(() => {
        if (userDetailsUpdateError) {
            //setMessage(userDetailsUpdateError)
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userDetailsUpdateError);
            setNotificationType("danger");
            setShowNotification(true);
            setMessage(null)
        } else if (userDetailsUpdateSuccess) {
            //setMessage(t('Account successfully updated.'))
            setNotificationTitle(t('SUCCESS'));
            setNotificationTime(t('Now'));
            setNotificationDesc(`${t('Account successfully updated, you will be redirected to account page in')} ${countdown} ${t('seconds')}`);
            setNotificationType("success");
            setShowNotification(true);
            setMessage(null)
            const timerId = setInterval(() => {
                setCountdown((currentCountdown) => {
                    if (currentCountdown <= 1) {
                        clearInterval(timerId);
                        navigate("/account");
                        return () => clearInterval(timerId); // Cleanup function
                    }
                    setNotificationDesc(`${t('Account successfully updated, you will be redirected to account page in')} ${currentCountdown - 1} ${t('seconds')}`);
                    return currentCountdown - 1;
                });
            }, 1000);
            dispatch({
                type: UPDATE_USER_DETAILS_RESET
            })
            /* return () => clearInterval(timerId); */
            /* dispatch(userDetails(userInfo.id)) */
        }
    }, [userDetailsUpdateError, userDetailsUpdateSuccess, navigate, dispatch, t])

    return (
        <>
            {(userAccLoading || userDetailsUpdateLoading) && <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'><span className="align-self-center"><Spinner animation="border" /></span></div>}

            <div className="form-card mt-2 mt-sm-5">

                {userAccDetails &&
                    <Row className='justify-content-md-center'>
                        <Col xs={12}>
                            <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                            <h2 className='text-center mb-4'>{t('Update Account')}</h2>
                            {message && <Message variant='danger'>{message}</Message>}
                            <Form onSubmit={onSubmit}>

                                <Form.Group controlId='artist_name'>
                                    <Form.Label>
                                        {t('Artist Name')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('enter your nickname')}
                                        defaultValue={userAccDetails.artist_name}
                                        onChange={(e) => setArtistName(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId='name'>
                                    <Form.Label>
                                        {t('Name')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('enter your name')}
                                        defaultValue={userAccDetails.name}
                                        onChange={(e) => setName(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId='surname'>
                                    <Form.Label>
                                        {t('Surname')}
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('enter your surname')}
                                        defaultValue={userAccDetails.surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>

                                <div className="my-3 d-flex justify-content-between">
                                    <Link to={`/account`}><button className="btn btn-link" type="button">{t('Cancel')}</button></Link>
                                    <Button type="submit" variant='primary'>{t('Save Changes')}</Button>
                                </div>
                            </Form>

                        </Col>
                    </Row>
                }

            </div>

            <div className='delete-account-button'>
                <Link to={`/account/delete`}><button className="btn btn-link text-danger" type="button">{t('Delete Account')}</button></Link>
            </div>

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )
}

export default AccountUpdatePage
