import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { userDetails, logout, checkTokenValidation, uploadTrackNew, s3Upload, receiverUploadNew, musicianTrackUpdate, celeryStatus, musicianTrackQuick, cropTrack, master30Track, master30Status } from '../actions/userActions'
import { Card, Button, Row, Col, Modal, Spinner, Form } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import WaveSurferPlayer from '../components/WaveSurferPlayer';
import Timeline from 'wavesurfer.js/dist/plugins/timeline.js'
import QuotesComponent from '../components/QuotesComponent'
import { UPLOAD_MUSIC_RESET, CELERY_STATUS_RESET, MUSICIAN_TRACK_QUICK_RESET, CROP_TRACK_RESET, MASTER30_TRACK_RESET, MUSICIAN_TRACK_DETAILS_RESET, MUSICIAN_TRACK_UPDATE_RESET, MASTER30_STATUS_RESET, MAINMASTER_STATUS_RESET } from '../constants'
import useAuth from '../hooks/useAuth';

function UploadPage() {

    useEffect(() => {
        document.title = "Upload Page - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // user check and details
    const { userAccError } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);

    // reset all reducers
    const [data, setData] = useState([]);
    const resetComponentState = () => {
        setData([]);
        localStorage.removeItem('Rm6P8YFwxBWTHg8hJypHwTVjBJTEtZJSiLkA');
        dispatch({ type: UPLOAD_MUSIC_RESET });
        dispatch({ type: CROP_TRACK_RESET });
        dispatch({ type: MASTER30_TRACK_RESET });
        dispatch({ type: CELERY_STATUS_RESET });
        dispatch({ type: MUSICIAN_TRACK_QUICK_RESET });
        dispatch({ type: MUSICIAN_TRACK_UPDATE_RESET });
        dispatch({ type: MUSICIAN_TRACK_DETAILS_RESET });
        dispatch({ type: MASTER30_STATUS_RESET });
        dispatch({ type: MAINMASTER_STATUS_RESET });
        setShowNotification(false);
    };

    useEffect(() => {
        resetComponentState();
        return resetComponentState;
    }, []);



    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => {
        setShowModal(false);
        setShowNotification(false);
        setSelectedFile(null);
        resetComponentState();
        setData([]);
        window.location.reload();
    };
    const handleModalShow = () => setShowModal(true);

    const [PayWallModal, setPayWallModal] = useState(false);
    const handlePayWallModalClose = () => setPayWallModal(false);
    const handlePayWallModalShow = () => setPayWallModal(true);

    const [NoCreditModal, setNoCreditModal] = useState(false);
    const handleNoCreditClose = () => setNoCreditModal(false);
    const handleNoCreditShow = () => setNoCreditModal(true);

    const [TrackTitleModal, setTrackTitleModal] = useState(false);
    const handleTrackTitleModalClose = () => setTrackTitleModal(false);
    const handleTrackTitleModalShow = () => setTrackTitleModal(true);



    const uploadMusicReducer = useSelector(state => state.uploadMusicReducer)
    const { music: uploadedMusic, loading: uploadMusicLoading, success: uploadMusicSuccess, error: uploadMusicError } = uploadMusicReducer
    const [selectedFile, setSelectedFile] = useState(null);
    const [tempMusicURL, setTempMusicURL] = useState(null);
    // start to upload music and show track title modal
    //let formData = new FormData();
    useEffect(() => {
        if (selectedFile) {
            const objectURL = URL.createObjectURL(selectedFile);
            const allowedTypes = [
                'audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/wave', 'audio/x-pn-wav',
                /* 'audio/aiff', 'audio/x-aiff', 'audio/aac', 'audio/flac', 
                'audio/alac', 'audio/x-alac', 'audio/ogg', 'audio/vorbis', 'application/ogg' */
            ];
            if (!allowedTypes.includes(selectedFile.type)) {
                setNotificationTitle(t('Warning'));
                setNotificationTime(t('Try another'));
                setNotificationDesc(t('Please select a mp3 or wav file to upload'));
                setNotificationType("warning");
                setShowNotification(true);
                return;
            } else if (selectedFile.size > 367001600) {
                setNotificationTitle(t('Warning'));
                setNotificationTime(t('Try another'));
                setNotificationDesc(t('Please select a file smaller than 1GB'));
                setNotificationType("warning");
                setShowNotification(true);
                return;
            } else if (selectedFile.name.length > 100) {
                setNotificationTitle(t('Warning'));
                setNotificationTime(t('Try another'));
                setNotificationDesc(t('Please select a file with a name shorter than 100 characters'));
                setNotificationType("warning");
                setShowNotification(true);
                return;
            } else if (selectedFile !== null) {
                /* if (userAccDetails?.is_ever_paid === false && userAccDetails?.total_revisions >= 3) {
                    handlePayWallModalShow();
                    return;
                } else {
                    setTempMusicURL(objectURL);
                    handleTrackTitleModalShow();
                    formData.append('file', selectedFile);
                    dispatch(uploadMusic(formData))
                } */
                /* if (userAccDetails?.credits < 1) {
                    handleNoCreditShow();
                    return;
                } else {
                    setTempMusicURL(objectURL);
                    handleTrackTitleModalShow();
                    formData.append('file', selectedFile);
                    dispatch(uploadMusic(formData))
                } */
                setTempMusicURL(objectURL);
                handleTrackTitleModalShow();
                //formData.append('file', selectedFile);
                //dispatch(uploadMusic(formData))
                dispatch(uploadTrackNew(selectedFile?.type === "audio/mpeg" ? "mp3" : "wav" ))
            }
            return () => {
                URL.revokeObjectURL(objectURL);
            };
        }
    }, [selectedFile]);



    const uploadTrackNewReducer = useSelector(state => state.uploadTrackNewReducer)
    const { music: uploadedTrackNew, loading: uploadTrackNewLoading, success: uploadTrackNewSuccess, error: uploadTrackNewError } = uploadTrackNewReducer
    const [uploadedMusicID, setUploadedMusicID] = useState(null);
    // check upload for presigned url and start to upload music to s3
    useEffect(() => {
        if (uploadTrackNewLoading) {
            setNotificationTitle(t('Uploading'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is uploading to our server, you can select LUFS while waiting'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (uploadTrackNewSuccess && (showModal || TrackTitleModal) && uploadedTrackNew?.presigned_url) {
            setUploadedMusicID(uploadedTrackNew.id);
            dispatch(s3Upload(uploadedTrackNew?.presigned_url, selectedFile))
        } else if (uploadTrackNewError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(uploadTrackNewError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [uploadTrackNewLoading, uploadTrackNewSuccess, uploadTrackNewError]);



    const s3UploadReducer = useSelector(state => state.s3UploadReducer)
    const { success: s3UploadSuccess, error: s3UploadError } = s3UploadReducer
    // check s3 upload
    useEffect(() => {
        if (s3UploadSuccess && uploadedMusicID && (showModal || TrackTitleModal)) {
            dispatch(receiverUploadNew(uploadedMusicID))
        } else if (s3UploadError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(t('There is an error while uploading your track to our server') + ': ' + s3UploadError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [s3UploadSuccess, s3UploadError]);



    const receiverUploadNewReducer = useSelector(state => state.receiverUploadNewReducer)
    const { data: receiverUploadedData, loading: receiverUploadNewLoading, success: receiverUploadNewSuccess, error: receiverUploadNewError } = receiverUploadNewReducer
    // check receiver upload and start celery status
    useEffect(() => {
        if (receiverUploadNewSuccess) {
            if (receiverUploadedData?.message === "Track upload successful. Celery task started!") {
                dispatch(celeryStatus(receiverUploadedData?.task_id))
            }
        } else if (receiverUploadNewError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(receiverUploadNewError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [receiverUploadNewLoading, receiverUploadNewSuccess, receiverUploadNewError]);



    const celeryStatusReducer = useSelector(state => state.celeryStatusReducer)
    const { status: celeryCurrentStatus, loading: celeryStatusLoading, success: celeryStatusSuccess, error: celeryStatusError } = celeryStatusReducer
    const [celerySuccess, setCelerySuccess] = useState(null);
    // check celery status in loop and start quick track info
    useEffect(() => {
        let timeoutId_ccsil = null;
        if (celeryStatusSuccess && (showModal || TrackTitleModal)) {
            if (celeryCurrentStatus.status === "completed") {
                dispatch(musicianTrackQuick(uploadedMusicID))
            } else {
                timeoutId_ccsil = setTimeout(() => {
                    dispatch(celeryStatus(receiverUploadedData?.task_id))
                }, 5000);
            }
        } else if (celeryStatusError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(celeryStatusError);
            setNotificationType("danger");
            setShowNotification(true);
            timeoutId_ccsil = setTimeout(() => {
                dispatch(celeryStatus(receiverUploadedData?.task_id))
            }, 5000);
        }
        return () => {
            if (timeoutId_ccsil) {
                clearTimeout(timeoutId_ccsil);
            }
        };
    }, [celeryStatusLoading, celeryStatusSuccess, celeryStatusError]);



    // check quick track response in loop and ready to master
    const musicianTrackQuickReducer = useSelector(state => state.musicianTrackQuickReducer)
    const { music: musicianTrackQuickMusic, loading: musicianTrackQuickLoading, success: musicianTrackQuickSuccess, error: musicianTrackQuickError } = musicianTrackQuickReducer
    useEffect(() => {
        let timeoutId_cmtq = null;
        if (musicianTrackQuickSuccess && (showModal || TrackTitleModal)) {
            if (musicianTrackQuickMusic?.bit_depth !== null &&
                musicianTrackQuickMusic?.sample_rate !== null &&
                musicianTrackQuickMusic?.integrated_lufs !== null &&
                musicianTrackQuickMusic?.genre_mini !== null &&
                musicianTrackQuickMusic?.chain_id !== null) {
                    setCelerySuccess(true);
                    setNotificationTitle(t('Processed'));
                    setNotificationTime(t('just now'));
                    setNotificationDesc(t('Your track processed, you can master it now'));
                    setNotificationType("dark");
                    setShowNotification(true);
            } else {
                timeoutId_cmtq = setTimeout(() => {
                    dispatch(musicianTrackQuick(uploadedMusicID))
                }, 5000);
            }
        } else if (musicianTrackQuickError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(musicianTrackQuickError);
            setNotificationType("danger");
            setShowNotification(true);
        }
        return () => {
            if (timeoutId_cmtq) {
                clearTimeout(timeoutId_cmtq);
            }
        };
    }, [musicianTrackQuickMusic, musicianTrackQuickLoading, musicianTrackQuickSuccess, musicianTrackQuickError]);


    const [trackTitleText, setTrackTitleText] = useState("");
    const [trackTitleTextCheck, setTrackTitleTextCheck] = useState(false);
    // set track title
    const setTrackTitle = () => {
        const trackTitle = document.getElementById("track-title");
        setTrackTitleText(trackTitle.value)
        setTrackTitleTextCheck(true);
    }
    // check track title and show main modal
    useEffect(() => {
        if (trackTitleText === "" && trackTitleTextCheck) {
            setTrackTitleTextCheck(false);
            setNotificationTitle(t('Warning'));
            setNotificationTime(t('Try another'));
            setNotificationDesc(t('Please enter a title for your track'));
            setNotificationType("warning");
            setShowNotification(true);
        } else if (trackTitleText.length < 1 && trackTitleTextCheck) {
            setTrackTitleTextCheck(false);
            setNotificationTitle(t('Warning'));
            setNotificationTime(t('Try another'));
            setNotificationDesc(t('Please enter a title longer than 1 characters'));
            setNotificationType("warning");
            setShowNotification(true);
        } else if (trackTitleText.length > 50 && trackTitleTextCheck) {
            setTrackTitleTextCheck(false);
            setNotificationTitle(t('Warning'));
            setNotificationTime(t('Try another'));
            setNotificationDesc(t('Please enter a title shorter than 50 characters'));
            setNotificationType("warning");
            setShowNotification(true);
        } else if (trackTitleText !== "" && trackTitleTextCheck) {
            handleTrackTitleModalClose();
            handleModalShow();
        }
    }, [trackTitleText, trackTitleTextCheck]);



    const musicianTrackUpdateReducer = useSelector(state => state.musicianTrackUpdateReducer)
    const { loading: musicianTrackUpdateLoading, success: musicianTrackUpdateSuccess, error: musicianTrackUpdateError } = musicianTrackUpdateReducer
    // update track title
    useEffect(() => {
        if (trackTitleText !== "" && trackTitleTextCheck && celerySuccess && trackTitleText.length > 0) {
            dispatch(musicianTrackUpdate(uploadedMusicID, { track_name: trackTitleText }))
        }
    }, [trackTitleText, trackTitleTextCheck, celerySuccess]);
    // check update track title
    useEffect(() => {
        if (musicianTrackUpdateLoading && showModal) {
            setNotificationTitle(t('Updating'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is updating'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (musicianTrackUpdateSuccess && showModal) {
            setNotificationTitle(t('Updated'));
            setNotificationTime(t('just now'));
            setNotificationDesc(t('Your track updated'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (musicianTrackUpdateError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(musicianTrackUpdateError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [musicianTrackUpdateLoading, musicianTrackUpdateSuccess, musicianTrackUpdateError]);



    const [croppedStartTime, setcroppedStartTime] = useState(0);
    // set cropped start time
    const handleRegionStart = (time) => {
        setcroppedStartTime(time);
    };
    const [cropData, setCropData] = useState([]);
    // set crop data with main track id and start time
    useEffect(() => {
        setCropData([
            { track_id: uploadedMusicID, start_time: croppedStartTime, end_time: trackDuration > 30 ? croppedStartTime + 30 : trackDuration },
        ]);
    }, [uploadedMusicID, croppedStartTime]);



    // start crop track with crop data
    const StartMastering = () => {
        waveSurferPlayerRef.current.pause();
        if (cropData.length === 0 && showModal) {
            setNotificationTitle(t('Warning'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(t('Please select a part of your track to master'));
            setNotificationType("warning");
            setShowNotification(true);
            return;
        } else if (croppedMusicID !== null) {
            const master30Data = [{ prompt: false, revize: true, track: croppedMusicID }];
            ContinueMastering(master30Data);
        } else {
            dispatch(cropTrack(cropData))
        }
    }



    const cropTrackReducer = useSelector(state => state.cropTrackReducer)
    const { music: croppedTrack, loading: cropTrackLoading, success: cropTrackSuccess, error: cropTrackError } = cropTrackReducer
    const [croppedMusicID, setCroppedMusicID] = useState(null);
    // check crop track and get cropped track id for mastering
    useEffect(() => {
        if (cropTrackLoading && showModal) {
            setNotificationTitle(t('Cropping'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is proccessing'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (cropTrackSuccess && showModal) {
            setNotificationTitle(t('Cropped'));
            setCroppedMusicID(croppedTrack.track_id)
        } else if (cropTrackError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(cropTrackError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [cropTrackLoading, cropTrackSuccess, cropTrackError]);
    // set mastering options and cropped track id
    useEffect(() => {
        if (!croppedMusicID) return;
        const master30Data = [{ 
            prompt: false,
            revize: true,
            track: croppedMusicID
        }];
        ContinueMastering(master30Data);
    }, [croppedMusicID]);



    // continue to master with cropped track id and mastering options
    const ContinueMastering = (e) => {
        if (e) {
            dispatch(master30Track(e))
        }
    }
    const master30TrackReducer = useSelector(state => state.master30TrackReducer)
    const { music: mastered30Track, loading: master30TrackLoading, success: master30TrackSuccess, error: master30TrackError } = master30TrackReducer
    // check master response and start master status
    useEffect(() => {
        if (master30TrackLoading && showModal) {
            setNotificationTitle(t('Mastering'));
            setNotificationTime(t('started'));
            setNotificationDesc(t('Your track is proccessing'));
            setNotificationType("dark");
            setShowNotification(true);
        } else if (master30TrackSuccess) {
            dispatch(master30Status(mastered30Track.task_id))
        } else if (master30TrackError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [master30TrackLoading, master30TrackSuccess, master30TrackError]);


    const [master30StatusReallySuccess, setmaster30StatusReallySuccess] = useState(false);
    const master30StatusReducer = useSelector(state => state.master30StatusReducer)
    const { status: masterCurrentStatus, loading: master30StatusLoading, success: master30StatusSuccess, error: master30StatusError } = master30StatusReducer
    // check master status in loop and redirect to mastering page
    useEffect(() => {
        let timeoutId_cms = null;
        if (master30StatusSuccess && showModal) {
            if (masterCurrentStatus?.status === "processed") {
                setmaster30StatusReallySuccess(true);
                setNotificationTitle(t('Redirecting'));
            } else if (masterCurrentStatus?.status === "processing" || masterCurrentStatus?.status === "pending") {
                timeoutId_cms = setTimeout(() => {
                    dispatch(master30Status(mastered30Track.task_id))
                }, 5000);
            } else if (masterCurrentStatus?.status === "timeout" || masterCurrentStatus?.status === "failed") {
                setNotificationTitle(t('ERROR'));
                setNotificationTime(t('Try later'));
                setNotificationDesc(t('Our Mastering Engine is currently in high demand'));
                setNotificationType("danger");
                setShowNotification(true);
                timeoutId_cms = setTimeout(() => {
                    handleModalClose();
                }, 5000);
            }
        } else if (master30StatusError) {
            handleModalClose();
            handleTrackTitleModalClose();
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(master30StatusError);
            setNotificationType("danger");
            setShowNotification(true);
        }
        return () => {
            if (timeoutId_cms) {
                clearTimeout(timeoutId_cms);
            }
        };
    }, [master30StatusLoading, master30StatusSuccess, master30StatusError]);



    const [collectedData, setCollectedData] = useState();
    // collect data with source uploaded music id
    useEffect(() => {
        if (!master30StatusReallySuccess) {
            return;
        } else if (master30StatusReallySuccess) {
            setCollectedData({
                source_track_id: uploadedMusicID,
            });
        }
    }, [uploadedMusic, croppedTrack, master30StatusReallySuccess]);



    // check collected data and start redirecting to mastering page
    useEffect(() => {
        if (
            /* collectedData?.uploadedMusic?.file_url && */ //cfggeçici
            /* collectedData?.croppedTrack?.file_url && */
            //mastered30Track?.file &&
            master30StatusReallySuccess &&
            collectedData?.source_track_id
        ) {
            RedirectToMasteringPage();
        }
    }, [collectedData, master30StatusReallySuccess]);



    // redirect to mastering page with collected data
    const RedirectToMasteringPage = () => {
        resetComponentState();
        navigate("/mastering", { state: collectedData, replace: false })
    }

    // redirect to credits page
    const RedirectToCreditsPage = () => {
        navigate("/credits-subscriptions", { replace: true })
    }



    // playing functions
    const startTime = 0.001;
    const [currentTime, setCurrentTime] = useState(0);
    const [playStatus, setPlayStatus] = useState(false);
    const [trackDuration, setTrackDuration] = useState(0);
    const waveSurferPlayerRef = useRef([null]);
    const handleCurrentTimeUpdate = (time) => {
        setCurrentTime(time);
    };
    const handlePlayStatusUpdate = (status) => {
        setPlayStatus(status);
    };
    const handleTrackDuration = (duration) => {
        setTrackDuration(duration);
    };

    // autoplay manuel control
    const [isAutoPlayed, setIsAutoPlayed] = useState(false);
    useEffect(() => {
        if (trackDuration > 0 && currentTime > 0 && !isAutoPlayed) {
            waveSurferPlayerRef.current.play();
            setIsAutoPlayed(true);
        }
    }, [trackDuration, currentTime, isAutoPlayed]);



    const [barWidth, setBarWidth] = useState(3);
    const [barGap, setBarGap] = useState(10);
    const [waveHeight, setWaveHeight] = useState(80);
    const updateBarProps = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 767) {
            setWaveHeight(60);
            setBarWidth(2);
            setBarGap(4);
        } else {
            setWaveHeight(80);
            setBarWidth(3);
            setBarGap(10);
        }
    };
    useEffect(() => {
        updateBarProps();
        window.addEventListener('resize', updateBarProps);
        return () => {
            window.removeEventListener('resize', updateBarProps);
        };
    }, []);



    return (
        <>
            {(cropTrackLoading || cropTrackSuccess || master30TrackLoading || master30StatusLoading || (master30StatusSuccess && !master30StatusReallySuccess)) &&
                <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'>
                    <span className="align-self-center">
                        <Spinner animation="border" />
                    </span>
                    <QuotesComponent />
                </div>
            }

            <div className='music-card'>

                <Row className='justify-content-center mt-5 mb-1 m-mt-2'>
                    <Col className='d-none d-sm-block'>
                        <div className='d-flex justify-content-between'>
                            <p className='align-self-center m-0 px-3 m-px-0'>{t('Upload Track')}</p>
                            <div className='button-group'>
                                <Button variant="link" onClick={() => navigate("/my-tracks", { replace: true })}>
                                    <img src='/icons/track-list.svg' alt='Mastering' className='icon' />
                                    {t('My Tracks')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Card>

                    <Card.Body className='upload-body'>
                        <div className='d-flex flex-column justify-content-center music-upload-body'>
                            <div className='fs-1 my-1 text-center upload-icon'><img src="/icons/upload.svg" alt="upload" /></div>
                            <div className='fs-4 my-1 text-center upload-track'><strong>{t('Upload Track')}</strong></div>
                            <div className='fs-5 my-1 text-center upload-desc'>{t('Drag an drop a wav or mp3 file to start mastering')}</div>
                            <input className='btn-big-upload' type="file" onChange={event => { setSelectedFile(event.target.files[0]); }} />
                        </div>
                    </Card.Body>

                </Card>


                <Modal
                    show={showModal}
                    onHide={handleModalClose}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Selecting highest LUFS part')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {tempMusicURL === null ?
                            <div className='relativeLoading_modal'>
                                <div className='relativeLoading d-flex justify-content-center align-items-stretch'>
                                    <span className="align-self-center">
                                        <Spinner animation="border" />
                                    </span>
                                </div>
                            </div>
                            :
                            <>
                                <Row className='d-flex justify-content-center align-items-center'>
                                    <div className='swiper-container d-flex justify-content-center align-items-center'>
                                        <img src='/icons/swipe.png' alt='swipe' className='icon icon-swipe' />
                                    </div>
                                </Row>
                                <Row className='my-3 upload-wsp-loading'>
                                    <Col>
                                        <WaveSurferPlayer
                                            ref={waveSurferPlayerRef}
                                            height={waveHeight}
                                            waveColor="rgb(100, 100, 100)"
                                            progressColor="rgb(250, 250, 250)"
                                            normalize={true}
                                            url={tempMusicURL}
                                            barWidth={barWidth}
                                            barGap={barGap}
                                            barRadius={10}
                                            barHeight={0.9}
                                            cursorWidth={1}
                                            interact={false}
                                            hideScrollbar={true}
                                            autoScroll={true}
                                            autoCenter={true}
                                            autoplay={false}
                                            startTime={startTime}
                                            dragSelection={true}
                                            onCurrentTimeUpdate={handleCurrentTimeUpdate}
                                            onPlayStatusUpdate={handlePlayStatusUpdate}
                                            onRegionStartUpdate={handleRegionStart}
                                            onTrackDuration={handleTrackDuration}
                                            plugins={[Timeline.create({
                                                height: 10,
                                                timeInterval: 5,
                                                primaryLabelInterval: 30,
                                                style: {
                                                    fontSize: '8px',
                                                    color: 'rgb(100, 100, 100)',
                                                },
                                            })]}
                                        />
                                    </Col>
                                </Row>
                                <Row className='d-flex justify-content-center align-items-center upload-popup-mobile-info-text'>
                                    <div className='text-center'>{t('Select highest LUFS serve as reference')}</div>
                                    <div className='text-center'>{t('The selected section will be used for mastering whole track')}</div>
                                </Row>
                                <Row className='d-flex justify-content-center align-items-center my-5 relativeLoading_btn'>
                                    <div className='px-3'>
                                        {
                                            uploadMusicLoading || musicianTrackUpdateLoading || !celerySuccess || cropTrackLoading || cropTrackSuccess || master30TrackLoading || master30StatusLoading || (master30StatusSuccess && !master30StatusReallySuccess) ?
                                            <Button variant='secondary' className='w-100'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                                                : celerySuccess && musicianTrackUpdateSuccess ?
                                                    <Button variant="primary" className="w-100" onClick={StartMastering}>
                                                        {t('Start Mastering')}
                                                    </Button>
                                                    :
                                                    <Button variant='secondary' className='w-100'><span className="btn-loader">loading<Spinner animation="border" /></span></Button>
                                        }
                                    </div>
                                </Row>
                            </>
                        }
                    </Modal.Body>
                </Modal>

                <Modal
                    show={PayWallModal}
                    onHide={handlePayWallModalClose}
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Credit Warning')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-5 mb-4 text-center '>
                            <Col>
                                <img src='/icons/warning.png' alt='Warning' className='icon big-icon' />
                            </Col>
                        </Row>
                        <Row className='mt-5 mb-4 text-center'>
                            <Col>
                                <h3>{t('Limit reached')}</h3>
                                <p className='mt-3'>{t('The process cannot continue because you have reached the revision limit You must purchase credits or subscription to continue')}</p>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center align-items-center my-5'>
                            <div className='px-3'>
                                <Button className="w-100" variant="primary" onClick={RedirectToCreditsPage}>{t('Credits & Subscriptions')}</Button>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={NoCreditModal}
                    onHide={handleNoCreditClose}
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Credit Warning')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-5 mb-4 text-center '>
                            <Col>
                                <img src='/icons/warning.png' alt='Warning' className='icon big-icon' />
                            </Col>
                        </Row>
                        <Row className='mt-5 mb-4 text-center'>
                            <Col>
                                <h3>{t('No Credit')}</h3>
                                <p className='mt-3'>{t('There is no credit in your account, you should buy at least 1 credit to master your track')}</p>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center align-items-center my-5'>
                            <div className='px-3'>
                                <Button className="w-100" variant="primary" onClick={RedirectToCreditsPage}>{t('Credits & Subscriptions')}</Button>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={TrackTitleModal}
                    onHide={handleTrackTitleModalClose}
                    keyboard={false}
                    backdrop="static"
                    size="lg"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>{t('What is your tracks title?')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='my-4 px-4 m-px-0 mobile-track-title-btn-group'>
                            <Col sm={9} className='m-px-0'>
                                <Form.Control id="track-title" autoFocus size="lg" className="ap-input" placeholder={t('My awesome track')} />
                            </Col>
                            <Col sm={3} className='m-px-0'>
                                <Button variant="primary" className='w-100 colored-btn ap-btn' onClick={setTrackTitle}><span>{t('Set Title')}</span></Button>
                            </Col>
                        </Row>
                        <Row className='mb-4 px-4 m-px-0 '>
                            <div className='px-3'>
                                <small><i>{t('track_title_tip')}</i></small>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>

                <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                    <ToastContainer position="bottom-end" className="p-3">
                        <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                            <Toast.Header>
                                <strong className="me-auto">{notificationTitle}</strong>
                                <small className="text-muted">{notificationTime}</small>
                            </Toast.Header>
                            <Toast.Body>{notificationDesc}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </div>

            </div>
        </>
    );
}

export default UploadPage;
