import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CREATE_CHECKOUT_SESSION_RESET } from '../constants'
import { useTranslation } from "react-i18next";

const Error = () => {

    useEffect(() => {
        document.title = "Order Error - Diktatorial Suite";
    }, []);
    
    
    const {t} = useTranslation();

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState([]);
    useEffect(() => {
        return () => {
            localStorage.removeItem("checkoutSession")
            dispatch({
                type: CREATE_CHECKOUT_SESSION_RESET
            })
        }
    }, []);

    return (
        <>
            <div className='accountPage p-5 m-px-0'>
                <Container>
                    <Card className='border'>

                        <Card.Header>
                            {t('Payment Status')}
                        </Card.Header>

                        <Card.Body>
                            <div className='d-flex flex-column justify-content-center account-card-body'>
                                <div className='fs-1 mb-4 text-center'><img className="icon payment-error-icon" src="/icons/error.svg" alt="user" /></div>
                                <div className='fs-6 text-center'>{t('An error occurred during your payment')}</div>
                                <div className='fs-5 mb-5 text-center text-white'><strong>{t('Please try again later')}</strong></div>
                                <div className='fs-4 mb-4 text-center'>
                                    <Row className='account-action-buttons'>
                                        <Col xs={12}><Button className='w-100' onClick={() => navigate("/credits-subscriptions", { replace: true })}>{t('Credits & Subscription')}</Button></Col>
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>

                </Container>
            </div>
        </>
    )
}

export default Error