import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
//import { UPDATE_USER_ACCOUNT_RESET } from '../constants'
import { Spinner } from 'react-bootstrap'
import useAuth from '../hooks/useAuth';

function AccountPage() {

    useEffect(() => {
        document.title = "Account - Diktatorial Suite";
    }, []);

    const { t } = useTranslation();

    const navigate = useNavigate()

    // user check and details
    const { userAccDetails, userAccLoading, userAccError, userInfo } = useAuth()
    useEffect(() => {
        if (userAccError) {
            setNotificationTitle(t('ERROR'));
            setNotificationTime(t('Try later'));
            setNotificationDesc(userAccError);
            setNotificationType("danger");
            setShowNotification(true);
        }
    }, [userAccError]);

    const [showNotification, setShowNotification] = useState(false);
    const [notificationType, setNotificationType] = useState("dark");
    const [notificationTitle, setNotificationTitle] = useState(null);
    const [notificationTime, setNotificationTime] = useState(null);
    const [notificationDesc, setNotificationDesc] = useState(null);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year} / ${month} / ${day}`;
    }

    const [GiftModal, setGiftModal] = useState(false);
    const handleGiftModalClose = () => setGiftModal(false);
    const handleGiftModalShow = () => setGiftModal(true);
    const RedirectToGift = () => {
        window.open("https://celestial-trick-ead.notion.site/Song-Sanctuary-A-Logbook-for-Audio-Professionals-13da52b3dbcd4dfc9f34e2c32804d221", "_blank");
    }


    const CopyRefferralLink = () => {
        navigator.clipboard.writeText("https://diktatorial.com/?ref=" + userAccDetails?.reference_code);
        setNotificationTitle(t('Copied'));
        setNotificationTime(t('just now'));
        setNotificationDesc(t('Referral link copied to clipboard'));
        setNotificationType("success");
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 2000);
    }

    return (
        <>
            {userAccLoading && <div className='fullPageLoading fullPageLoadingBlur d-flex justify-content-center align-items-stretch'><span className="align-self-center"><Spinner animation="border" /></span></div>}

            <div className='accountPage mt-2 mt-sm-5'>

                {userAccDetails &&
                    <Card className='fit-content mb-5'>

                        <Card.Body>
                            <div className='d-flex flex-column justify-content-center account-card-body'>
                                <div className='fs-1 mb-2 text-center'><img className="icon user-account-icon" src="/logo.png" alt="user" /></div>
                                {
                                    userAccDetails.is_active_subscriber &&
                                    <div className='status-badge fit-content mb-3'>{userAccDetails.status + ' ' + t('Suite Member')}</div>
                                }

                                <div className='fs-5 text-center text-white'>{t('Welcome to diktatorial suite')}</div>
                                <div className='fs-5 mb-4 text-center text-white'><strong>{userInfo?.email}</strong></div>

                                <div className='account-stats'>
                                    <div className='as-item'>
                                        <div className='as-item-icon'><img src='/icons/ac-tracks.svg' alt='Tracks' className='icon' /></div>
                                        <div className='as-item-text'>
                                            <div className='ait-number'>{userAccDetails?.total_uploads}</div>
                                            <div className='ait-name'>{userAccDetails?.total_uploads > 1 ? t('Tracks') : t('Track')}</div>
                                        </div>
                                    </div>
                                    <div className='as-item'>
                                        <div className='as-item-icon'><img src='/icons/ac-revisions.svg' alt='Revisions' className='icon' /></div>
                                        <div className='as-item-text'>
                                            <div className='ait-number'>{userAccDetails?.total_revisions}</div>
                                            <div className='ait-name'>{userAccDetails?.total_revisions > 1 ? t('Revisions') : t('Revision')}</div>
                                        </div>
                                    </div>
                                    <div className='as-item'>
                                        <div className='as-item-icon'><img src='/icons/ac-masters.svg' alt='Mastered' className='icon ac-mastering' /></div>
                                        <div className='as-item-text'>
                                            <div className='ait-number'>{userAccDetails?.total_mastered}</div>
                                            <div className='ait-name'>{userAccDetails?.total_mastered > 1 ? t('Mastereds') : t('Mastered')}</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='fs-7 text-center'>{t('You have')}</div>
                                <div className='fs-3 text-center text-white'><strong>{userAccDetails.credits > 1 ? userAccDetails.credits + ' ' + t('Credits') : userAccDetails.credits + ' ' + t('Credit')}</strong></div>
                                <Button variant='secondary' className='fit-content mb-4 py-2 px-5' onClick={() => navigate("/credits-subscriptions")}>{t('Buy More')}</Button>

                                {
                                    userAccDetails.is_active_subscriber ?
                                        <>
                                            <div className='fs-7 text-center'>{t('Your subscription is active until')}</div>
                                            <div className='fs-4 mb-1 text-center text-white'><strong>{formatDate(userAccDetails?.active_subscription_end_date)}</strong></div>
                                            <div className='fs-6 mb-4 text-center acc-desc'><a className="text-underline pointer" href='https://billing.stripe.com/p/login/9AQaGA5vL0h5etOfYY' target='_blank' rel="noreferrer">{t('Billing Management')}</a></div>
                                            {((userAccDetails?.status === 'Pro Annual') || (userAccDetails?.status === 'Pro Lifetime')) &&
                                                <Button variant='secondary' className='fit-content mb-3 py-2 px-5' onClick={handleGiftModalShow}>{t('Claim Your Notion Template Here')}</Button>
                                            }
                                        </>
                                        :
                                        <>
                                            <div className='fs-7 mb-1 text-center acc-desc'>{t('You dont have any active subscription')}</div>
                                            {userAccDetails?.is_ever_paid &&
                                                <div className='fs-6 mb-2 text-center acc-desc'><a className="text-underline pointer" href='https://billing.stripe.com/p/login/9AQaGA5vL0h5etOfYY' target='_blank' rel="noreferrer">{t('Billing Management')}</a></div>
                                            }
                                            <div className='fs-7 mb-1 text-center acc-desc'>{t('The grass is greener, the light is brighter when you have a subscription')}</div>
                                            <div className='fs-6 mb-4 text-center'><span className="text-white text-underline pointer" onClick={() => navigate("/credits-subscriptions")}>{t('Check Suite Membership here')}</span></div>
                                        </>
                                }

                                <div className='referring-wrapper'>
                                    <div className='fs-5 text-center text-white'>{t('Refer a Friend')}</div>
                                    <div className='fs-7 text-center'>{t("Youll both earn 1 FREE CREDIT rewards")}</div>
                                    <div className='referring-infos'>
                                        <div className='referring-iitem'>
                                            <div className='referring-text'>{t('Your Invites')}</div>
                                            <div className='referring-number'>({userAccDetails?.referral_count ? userAccDetails?.referral_count : "0"})</div>
                                        </div>
                                        <div className='referring-iitem'>
                                            <div className='referring-text'>{t('Total Rewards')}</div>
                                            <div className='referring-number'>({userAccDetails?.total_rewards ? userAccDetails?.total_rewards : "0"})</div>
                                        </div>
                                    </div>
                                    <Button variant='secondary' className='w-100 mb-3 py-2 px-5 copy-btn' onClick={CopyRefferralLink}>
                                        https://diktatorial.com/?ref={userAccDetails?.reference_code}
                                        <img src='/icons/copy.svg' alt='Copy' className='icon copy-icon' />
                                    </Button>
                                </div>

                                <div className='fs-4 mt-4 text-center'>
                                    <Row className='account-action-buttons'>
                                        <Col xs={12} sm={12}><Button className='w-100 mb-3' onClick={() => navigate("/account/update")}>{t('Update Account Details')}</Button></Col>
                                        {/* <Col xs={12} sm={6}><Button className='w-100 mb-3' onClick={() => navigate("/account/password-reset")}>{t('Change Password')}</Button></Col> */}
                                    </Row>
                                </div>
                            </div>
                        </Card.Body>

                    </Card>
                }

                <Modal
                    show={GiftModal}
                    onHide={handleGiftModalClose}
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Gift')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='mt-3 text-center '>
                            <Col>
                                <img src='/icons/notion-gift.svg' alt='Notion' className='icon big-icon notion-icon' />
                            </Col>
                        </Row>
                        <Row className='mt-4 mb-3 text-center'>
                            <Col>
                                <h3>{t('Claim Your Notion Template')}</h3>
                                <p className='mt-3'>{t('Just duplicate it and start to use Notion is free to use as well')}</p>
                                <p className='mt-1'>{t('Follow 1 minute tutorial and remove dummy data Thats all')}</p>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-center align-items-center my-4'>
                            <div className='px-3'>
                                <Button className="w-100" variant="primary" onClick={RedirectToGift}>{t('Get Your Gift')}</Button>
                            </div>
                        </Row>
                    </Modal.Body>
                </Modal>

            </div>

            <div className="toast-fullPage" aria-live="polite" aria-atomic="true">
                <ToastContainer position="bottom-end" className="p-3">
                    <Toast show={showNotification} onClose={() => setShowNotification(false)} bg={notificationType}>
                        <Toast.Header>
                            <strong className="me-auto">{notificationTitle}</strong>
                            <small className="text-muted">{notificationTime}</small>
                        </Toast.Header>
                        <Toast.Body>{notificationDesc}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        </>
    )
}

export default AccountPage
