import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CREATE_CHECKOUT_SESSION_RESET } from '../constants'
import { useTranslation } from "react-i18next";
import { apiInstance } from '../utils';
import { useQuery } from '@tanstack/react-query'
import { createCheckoutSession } from '../actions/userActions'
import { Spinner, Button } from 'react-bootstrap'
import PriceCard from './PriceCard'

function Pricing({ userAccDetails }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            localStorage.removeItem("checkoutSession")
            dispatch({
                type: CREATE_CHECKOUT_SESSION_RESET
            })
        }
    }, []);

    // products query
    const { isPending: loadingProducts, error: errorProducts, data: productList } = useQuery({
        queryKey: ['products'],
        queryFn: () => apiInstance.get('/api/products').then(res => res.data?.products),
        initialData: []
    })


    // create checkout session reducer
    const createCheckoutSessionReducer = useSelector(state => state.createCheckoutSessionReducer)
    const { session: checkoutSessionData, loading: loadingCheckoutSession, success: successCheckoutSession, error: errorCheckoutSession } = createCheckoutSessionReducer
    const createCheckoutSessionFunction = (id, quantity) => async (e) => {
        e.preventDefault();
        dispatch(createCheckoutSession(id, quantity));
        setCheckoutSessionCookie(id, quantity);
    }
    useEffect(() => {
        if (successCheckoutSession) {
            window.location.href = checkoutSessionData.payment_url;
        }
    }, [successCheckoutSession])

    // set cookie for checkout session
    const setCheckoutSessionCookie = (id, quantity) => {
        const product = productList.find(product => product.id === id);
        const purchaseData = {
            id: id,
            quantity: quantity,
            price: product.price * quantity,
            name: product.name
        };
        const domain = window.location.hostname === 'localhost' ? 'localhost' : 'diktatorial.com';
        document.cookie = `LastPurchasedPrice=${JSON.stringify(purchaseData?.price)}; path=/; domain=${domain}`;
    };


    // products
    const [lifetimeMembership, setLifetimeMembership] = useState(null);
    const [yearlyMembership, setYearlyMembership] = useState(null);
    const [monthlyMembership, setMonthlyMembership] = useState(null);
    const [singleMastering, setSingleMastering] = useState(null);
    const [extraMasteringLifetime, setExtraMasteringLifetime] = useState(null);
    const [extraMasteringYearly, setExtraMasteringYearly] = useState(null);
    const [extraMasteringMonthly, setExtraMasteringMonthly] = useState(null);
    const [extraMasteringEarlyBird, setExtraMasteringEarlyBird] = useState(null);
    useEffect(() => {
        if (productList && productList.length > 0) {
            productList.forEach(product => {
                switch (product.name) {
                    case "Pro Membership Lifetime":
                        setLifetimeMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Annual":
                        setYearlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Monthly":
                        setMonthlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Single Mastering":
                        setSingleMastering({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Lifetime":
                        setExtraMasteringLifetime({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Annual":
                        setExtraMasteringYearly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Monthly":
                        setExtraMasteringMonthly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Early Bird":
                        setExtraMasteringEarlyBird({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    default:
                }
            });
        }
    }, [productList]);


    // formats
    const formatPrice = price => {
        const number = parseFloat(price);
        const rounded = number.toFixed(2);
        return Number(rounded);
    };
    const formatProcent = (price, orjPrice) => {
        const number = parseFloat(price);
        const orjNumber = parseFloat(orjPrice);
        const procent = (100 - (number / orjNumber) * 100).toFixed(0);
        const roundedProcent = Math.round(procent / 5) * 5;
        return roundedProcent;
    };


    // subscription options
    const [paymentPlan, setPaymentPlan] = useState("annual");
    const handleSubscriptionToggle = (plan) => {
        setPaymentPlan(plan);
    };


    const [accountType, setAccountType] = useState("Free");
    const [isEarlyBird, setIsEarlyBird] = useState(false);
    useEffect(() => {
        const earlyBirdList = [178, 157, 174, 213, 172, 170, 184, 169, 152, 317, 246, 214, 254, 316, 222, 239, 252, 244, 231, 297, 299, 293, 274, 265, 310, 335, 150, 355, 339, 373, 381, 383, 151, 173];
        if (userAccDetails?.status === "Pro Lifetime") {
            setAccountType(t('Pro Lifetime'));
            setPaymentPlan("single");
        } else if (userAccDetails?.status === "Pro Annual") {
            setAccountType(t('Pro Annual'));
        } else if (userAccDetails?.status === "Pro Monthly") {
            setAccountType(t('Pro Monthly'));
        }
        if (userAccDetails?.id && earlyBirdList.includes(userAccDetails?.id)) {
            setIsEarlyBird(true);
        }
    }, [userAccDetails]);


    // quantity options
    const [quantity, setQuantity] = useState(1);
    const handleIncrement = () => {
        if (quantity >= 99) {
            return;
        }
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };
    const handleDecrement = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
        }
    };


    return (
        <>
            {loadingProducts || loadingCheckoutSession ?
                <div className='d-flex justify-content-center py-5 my-5'>
                    <div className='relativeLoading d-flex justify-content-center align-items-stretch'>
                        <span className="align-self-center">
                            <Spinner animation="border" />
                        </span>
                    </div>
                </div>
                : errorProducts || errorCheckoutSession ?
                    <>
                        Error...
                    </>
                    :
                    <>
                        <div className="price-cards-container px-3 mb-4 m-mb-2">

                            {/* { (lifetimeMembership && accountType !== "Pro Lifetime") && (
                                <PriceCard
                                    plan="lifetime"
                                    title={t('LIFETIME')}
                                    price={formatPrice(lifetimeMembership.price)}
                                    pricePer={t('pay once')}
                                    creditsIncluded={t('lifetime_list_ex_1a') + ' ' + t('Credits Included')}
                                    description={t('lifetime_list_ex_2a') + ' ' + t('Credits Annually Forever!')}
                                    savePercent={formatProcent(lifetimeMembership.price, lifetimeMembership.orj_price)}
                                    bestValue
                                    isSelected={paymentPlan === 'lifetime'}
                                    onSelect={handleSubscriptionToggle}
                                />
                            )} */}

                            { (yearlyMembership && accountType !== "Pro Lifetime" && accountType !== "Pro Annual") && (
                                <PriceCard
                                    plan="annual"
                                    title={t('ANNUAL')}
                                    price={formatPrice(yearlyMembership.price / 12)}
                                    pricePer={t('per month')}
                                    creditsIncluded={t('yearly_list_ex_1a') + ' ' + t('Credits Included')}
                                    description={t('Annually Feature')}
                                    savePercent={formatProcent(yearlyMembership.price, yearlyMembership.orj_price)}
                                    isSelected={paymentPlan === 'annual'}
                                    bestValue
                                    onSelect={handleSubscriptionToggle}
                                />
                            )}

                            { (monthlyMembership && accountType !== "Pro Lifetime" && accountType !== "Pro Annual" && accountType !== "Pro Monthly" ) && (
                                <PriceCard
                                    plan="monthly"
                                    title={t('MONTHLY')}
                                    price={formatPrice(monthlyMembership.price)}
                                    pricePer={t('per month')}
                                    creditsIncluded={t('monthly_list_ex_1a') + ' ' + t('Credit Included')}
                                    description={t('Monthly Feature')}
                                    savePercent={formatProcent(monthlyMembership.price, monthlyMembership.orj_price)}
                                    isSelected={paymentPlan === 'monthly'}
                                    onSelect={handleSubscriptionToggle}
                                />
                            )}

                            {singleMastering && (
                                <PriceCard
                                    plan="single"
                                    title={t('INSTANT 1 CREDIT')}
                                    price={accountType !== "Free" && isEarlyBird ? formatPrice(extraMasteringEarlyBird.price)
                                        : accountType === "Pro Lifetime" ? formatPrice(extraMasteringLifetime.price)
                                            : accountType === "Pro Annual" ? formatPrice(extraMasteringYearly.price)
                                                : accountType === "Pro Monthly" ? formatPrice(extraMasteringMonthly.price)
                                                    : formatPrice(singleMastering.price)
                                    }
                                    pricePer={t('credit')}
                                    creditsIncluded={t('1 Credit Included')}
                                    description={t('Cheaper for Suite Members')}
                                    savePercent={accountType !== "Free" && isEarlyBird ? formatProcent(extraMasteringEarlyBird.price, extraMasteringEarlyBird.orj_price)
                                        : accountType === "Pro Lifetime" ? formatProcent(extraMasteringLifetime.price, extraMasteringLifetime.orj_price)
                                            : accountType === "Pro Annual" ? formatProcent(extraMasteringYearly.price, extraMasteringYearly.orj_price)
                                                : accountType === "Pro Monthly" ? formatProcent(extraMasteringMonthly.price, extraMasteringMonthly.orj_price)
                                                    : formatProcent(singleMastering.price, singleMastering.orj_price)
                                    }
                                    isSelected={paymentPlan === 'single'}
                                    onSelect={handleSubscriptionToggle}
                                    quantitySelector={true}
                                    quantity={quantity}
                                    onIncrement={handleIncrement}
                                    onDecrement={handleDecrement}
                                />
                            )}

                        </div>

                        <div className='my-3 m-my-1 px-3 m-px-0'>
                            {
                                paymentPlan === "lifetime" && lifetimeMembership?.id ?
                                    <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(lifetimeMembership?.id, 1)}>
                                        {t('Buy once, use FOREVER')}
                                    </Button>
                                    : paymentPlan === "annual" && yearlyMembership?.id ?
                                        <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(yearlyMembership?.id, 1)}>
                                            {t('Buy Subscription')}
                                        </Button>
                                        : paymentPlan === "monthly" && monthlyMembership?.id ?
                                            <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(monthlyMembership?.id, 1)}>
                                                {t('Buy Subscription')}
                                            </Button>
                                            : paymentPlan === "single" && accountType === "Free" && singleMastering?.id ?
                                                <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(singleMastering?.id, quantity)}>
                                                    {t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}
                                                </Button>
                                                : paymentPlan === "single" && accountType !== "Free" && isEarlyBird ?
                                                    <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(extraMasteringEarlyBird?.id, quantity)}>
                                                        {t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}
                                                    </Button>
                                                    : paymentPlan === "single" && accountType === "Pro Lifetime" && extraMasteringLifetime?.id ?
                                                        <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(extraMasteringLifetime?.id, quantity)}>
                                                            {t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}
                                                        </Button>
                                                        : paymentPlan === "single" && accountType === "Pro Annual" && extraMasteringYearly?.id ?
                                                            <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(extraMasteringYearly?.id, quantity)}>
                                                                {t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}
                                                            </Button>
                                                            : paymentPlan === "single" && accountType === "Pro Monthly" && extraMasteringMonthly?.id ?
                                                                <Button className="bigbluebutton w-100" variant="primary" onClick={createCheckoutSessionFunction(extraMasteringMonthly?.id, quantity)}>
                                                                    {t('Buy {{quantitynumber}} Credit', { quantitynumber: quantity })}
                                                                </Button>
                                                                : null
                            }
                        </div>
                    </>
            }
        </>
    );
}

export default Pricing
