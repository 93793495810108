import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navbar, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useTranslation } from "react-i18next";
import LanguageSwitcher from './LanguageSwitcher'
import { logout } from '../actions/userActions'
import { useNavigate, useLocation } from "react-router-dom"
import useUser from '../hooks/useUser';
import '../styles/navbar.scss'

function NavBar() {
    const { t } = useTranslation();

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
  
    // user details
    const { userAccDetails, userInfo } = useUser()

    // logout
    const logoutHandler = () => {
        dispatch(logout()) // action
        navigate("/login", { replace: true })
        window.location.reload()
    }

    return (
        <header>
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <div className='full-container w-100 d-flex justify-content-between px-3 m-px-2'>

                    <LinkContainer to="/">
                        <Navbar.Brand><img src='/diktatorial-logo.svg' className='applogo' alt='Diktatorial Suite' /></Navbar.Brand>
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav" className='d-flex justify-content-between'>

                        <Nav className="mr-auto">

                            <LanguageSwitcher />

                        </Nav>

                        {userInfo ?
                            <div className='navbar-right-wrapper'>

                                <div className='nb-infos'>
                                    {userAccDetails && userAccDetails.user ?
                                        <>
                                            <div className='nb-member'>
                                                <LinkContainer to="/credits-subscriptions">
                                                    {userAccDetails?.is_active_subscriber
                                                        ? <Nav.Link>{t('Suite Member')}</Nav.Link>
                                                        : <Nav.Link>{t('Get Suite')}</Nav.Link>
                                                    }
                                                </LinkContainer>
                                            </div>
                                            <div className='nb-credit'>
                                                <LinkContainer to="/credits-subscriptions">
                                                    <Nav.Link>{t('Credit')}: <span>{userAccDetails?.credits}</span></Nav.Link>
                                                </LinkContainer>
                                            </div>
                                        </>
                                        : null
                                    }
                                </div>
                                <NavDropdown className={`navbar-nav reverse-dropdown text-capitalize ${userAccDetails?.is_active_subscriber ? "pro-user" : ""}`} title={userInfo?.email[0]} id='username'>
                                    <LinkContainer to="/upload-track/">
                                        <NavDropdown.Item>{t('Upload Track')}</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/my-tracks">
                                        <NavDropdown.Item>{t('My Tracks')}</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/account">
                                        <NavDropdown.Item>{t('Account Settings')}</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/credits-subscriptions">
                                        <NavDropdown.Item>{t('Credits & Subscriptions')}</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Item as="a" href="https://diktatorial.com/release-notes" target="_blank" rel="noopener noreferrer">
                                        {t('Release Notes')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={logoutHandler}>
                                        {t('Logout')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                            :
                            <div className='d-flex'>
                                <LinkContainer to="/login" className='mx-2'>
                                    <Nav.Link>{t('Login')}</Nav.Link>
                                </LinkContainer>
                            </div>
                        }
                    </Navbar.Collapse>

                </div>
            </Navbar>
        </header>
    )
}

export default NavBar
