// user registration/signup
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

// mail confirmation
export const MAIL_CONFIRMATION_REQUEST = "MAIL_CONFIRMATION_REQUEST"
export const MAIL_CONFIRMATION_SUCCESS = "MAIL_CONFIRMATION_SUCCESS"
export const MAIL_CONFIRMATION_FAIL = "MAIL_CONFIRMATION_FAIL"

// resend mail confirmation
export const RESEND_MAIL_CONFIRMATION_REQUEST = "RESEND_MAIL_CONFIRMATION_REQUEST"
export const RESEND_MAIL_CONFIRMATION_SUCCESS = "RESEND_MAIL_CONFIRMATION_SUCCESS"
export const RESEND_MAIL_CONFIRMATION_FAIL = "RESEND_MAIL_CONFIRMATION_FAIL"

// user forgot password
export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST"
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS"
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL"

// password confirm reset
export const PASSWORD_CONFIRM_RESET_REQUEST = "CONFIRM_PASSWORD_RESET_REQUEST"
export const PASSWORD_CONFIRM_RESET_SUCCESS = "CONFIRM_PASSWORD_RESET_SUCCESS"
export const PASSWORD_CONFIRM_RESET_FAIL = "CONFIRM_PASSWORD_RESET_FAIL"

// user login/logout
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

export const USER_LOGOUT = "USER_LOGOUT"

// check token validation
export const CHECK_TOKEN_VALID_REQUEST = "CHECK_TOKEN_VALID_REQUEST"
export const CHECK_TOKEN_VALID_SUCCESS = "CHECK_TOKEN_VALID_SUCCESS"
export const CHECK_TOKEN_VALID_FAIL = "CHECK_TOKEN_VALID_FAIL"
export const CHECK_TOKEN_VALID_RESET = "CHECK_TOKEN_VALID_RESET"

// user info/details
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL"

// user update details
export const UPDATE_USER_DETAILS_REQUEST = "UPDATE_USER_DETAILS_REQUEST"
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS"
export const UPDATE_USER_DETAILS_FAIL = "UPDATE_USER_DETAILS_FAIL"
export const UPDATE_USER_DETAILS_RESET = "UPDATE_USER_DETAILS_RESET"

// user update password
export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST"
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS"
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL"
export const UPDATE_USER_PASSWORD_RESET = "UPDATE_USER_PASSWORD_RESET"

// user delete account
export const USER_DELETE_ACCOUNT_REQUEST = "USER_DELETE_ACCOUNT_REQUEST"
export const USER_DELETE_ACCOUNT_SUCCESS = "USER_DELETE_ACCOUNT_SUCCESS"
export const USER_DELETE_ACCOUNT_FAIL = "USER_DELETE_ACCOUNT_FAIL"
export const USER_DELETE_ACCOUNT_RESET = "USER_DELETE_ACCOUNT_RESET"

// create checkout session
export const CREATE_CHECKOUT_SESSION_REQUEST = "CREATE_CHECKOUT_SESSION_REQUEST"
export const CREATE_CHECKOUT_SESSION_SUCCESS = "CREATE_CHECKOUT_SESSION_SUCCESS"
export const CREATE_CHECKOUT_SESSION_FAIL = "CREATE_CHECKOUT_SESSION_FAIL"
export const CREATE_CHECKOUT_SESSION_RESET = "CREATE_CHECKOUT_SESSION_RESET"

// user upload music
export const UPLOAD_MUSIC_REQUEST = "UPLOAD_MUSIC_REQUEST"
export const UPLOAD_MUSIC_SUCCESS = "UPLOAD_MUSIC_SUCCESS"
export const UPLOAD_MUSIC_FAIL = "UPLOAD_MUSIC_FAIL"
export const UPLOAD_MUSIC_RESET = "UPLOAD_MUSIC_RESET"

// user new upload track
export const UPLOAD_TRACK_NEW_REQUEST = "UPLOAD_TRACK_NEW_REQUEST"
export const UPLOAD_TRACK_NEW_SUCCESS = "UPLOAD_TRACK_NEW_SUCCESS"
export const UPLOAD_TRACK_NEW_FAIL = "UPLOAD_TRACK_NEW_FAIL"
export const UPLOAD_TRACK_NEW_RESET = "UPLOAD_TRACK_NEW_RESET"

// s3 upload
export const S3_UPLOAD_REQUEST = "S3_UPLOAD_REQUEST"
export const S3_UPLOAD_SUCCESS = "S3_UPLOAD_SUCCESS"
export const S3_UPLOAD_FAIL = "S3_UPLOAD_FAIL"
export const S3_UPLOAD_RESET = "S3_UPLOAD"

// receiver upload new
export const RECEIVER_UPLOAD_NEW_REQUEST = "RECEIVER_UPLOAD_NEW_REQUEST"
export const RECEIVER_UPLOAD_NEW_SUCCESS = "RECEIVER_UPLOAD_NEW_SUCCESS"
export const RECEIVER_UPLOAD_NEW_FAIL = "RECEIVER_UPLOAD_NEW_FAIL"
export const RECEIVER_UPLOAD_NEW_RESET = "RECEIVER_UPLOAD_NEW_RESET"

// celery status
export const CELERY_STATUS_REQUEST = "CELERY_STATUS_REQUEST"
export const CELERY_STATUS_SUCCESS = "CELERY_STATUS_SUCCESS"
export const CELERY_STATUS_FAIL = "CELERY_STATUS_FAIL"
export const CELERY_STATUS_RESET = "CELERY_STATUS_RESET"

// musician track update
export const MUSICIAN_TRACK_UPDATE_REQUEST = "MUSICIAN_TRACK_UPDATE_REQUEST"
export const MUSICIAN_TRACK_UPDATE_SUCCESS = "MUSICIAN_TRACK_UPDATE_SUCCESS"
export const MUSICIAN_TRACK_UPDATE_FAIL = "MUSICIAN_TRACK_UPDATE_FAIL"
export const MUSICIAN_TRACK_UPDATE_RESET = "MUSICIAN_TRACK_UPDATE_RESET"

// select 30 seconds of music
export const CROP_TRACK_REQUEST = "CROP_TRACK_REQUEST"
export const CROP_TRACK_SUCCESS = "CROP_TRACK_SUCCESS"
export const CROP_TRACK_FAIL = "CROP_TRACK_FAIL"
export const CROP_TRACK_RESET = "CROP_TRACK_RESET"

// master 30
export const MASTER30_TRACK_REQUEST = "MASTER30_TRACK_REQUEST"
export const MASTER30_TRACK_SUCCESS = "MASTER30_TRACK_SUCCESS"
export const MASTER30_TRACK_FAIL = "MASTER30_TRACK_FAIL"
export const MASTER30_TRACK_RESET = "MASTER30_TRACK_RESET"

// master 30 status
export const MASTER30_STATUS_REQUEST = "MASTER30_STATUS_REQUEST"
export const MASTER30_STATUS_SUCCESS = "MASTER30_STATUS_SUCCESS"
export const MASTER30_STATUS_FAIL = "MASTER30_STATUS_FAIL"
export const MASTER30_STATUS_RESET = "MASTER30_STATUS_RESET"

// master main
export const MASTERMAIN_TRACK_REQUEST = "MASTERMAIN_TRACK_REQUEST"
export const MASTERMAIN_TRACK_SUCCESS = "MASTERMAIN_TRACK_SUCCESS"
export const MASTERMAIN_TRACK_FAIL = "MASTERMAIN_TRACK_FAIL"
export const MASTERMAIN_TRACK_RESET = "MASTERMAIN_TRACK_RESET"

// master main status
export const MAINMASTER_STATUS_REQUEST = "MAINMASTER_STATUS_REQUEST"
export const MAINMASTER_STATUS_SUCCESS = "MAINMASTER_STATUS_SUCCESS"
export const MAINMASTER_STATUS_FAIL = "MAINMASTER_STATUS_FAIL"
export const MAINMASTER_STATUS_RESET = "MAINMASTER_STATUS_RESET"

// musician track list
export const MUSICIAN_TRACK_LIST_REQUEST = "MUSICIAN_TRACK_LIST_REQUEST"
export const MUSICIAN_TRACK_LIST_SUCCESS = "MUSICIAN_TRACK_LIST_SUCCESS"
export const MUSICIAN_TRACK_LIST_FAIL = "MUSICIAN_TRACK_LIST_FAIL"
export const MUSICIAN_TRACK_LIST_RESET = "MUSICIAN_TRACK_LIST_RESET"

// musician track info details
export const MUSICIAN_TRACK_DETAILS_REQUEST = "MUSICIAN_TRACK_DETAILS_REQUEST"
export const MUSICIAN_TRACK_DETAILS_SUCCESS = "MUSICIAN_TRACK_DETAILS_SUCCESS"
export const MUSICIAN_TRACK_DETAILS_FAIL = "MUSICIAN_TRACK_DETAILS_FAIL"
export const MUSICIAN_TRACK_DETAILS_RESET = "MUSICIAN_TRACK_DETAILS_RESET"

// musician track quick
export const MUSICIAN_TRACK_QUICK_REQUEST = "MUSICIAN_TRACK_QUICK_REQUEST"
export const MUSICIAN_TRACK_QUICK_SUCCESS = "MUSICIAN_TRACK_QUICK_SUCCESS"
export const MUSICIAN_TRACK_QUICK_FAIL = "MUSICIAN_TRACK_QUICK_FAIL"
export const MUSICIAN_TRACK_QUICK_RESET = "MUSICIAN_TRACK_QUICK_RESET"

// original track delete
export const ORIGINAL_TRACK_DELETE_REQUEST = "ORIGINAL_TRACK_DELETE_REQUEST"
export const ORIGINAL_TRACK_DELETE_SUCCESS = "ORIGINAL_TRACK_DELETE_SUCCESS"
export const ORIGINAL_TRACK_DELETE_FAIL = "ORIGINAL_TRACK_DELETE_FAIL"
export const ORIGINAL_TRACK_DELETE_RESET = "ORIGINAL_TRACK_DELETE_RESET"

// mastered track delete
export const MASTERED_TRACK_DELETE_REQUEST = "MASTERED_TRACK_DELETE_REQUEST"
export const MASTERED_TRACK_DELETE_SUCCESS = "MASTERED_TRACK_DELETE_SUCCESS"
export const MASTERED_TRACK_DELETE_FAIL = "MASTERED_TRACK_DELETE_FAIL"
export const MASTERED_TRACK_DELETE_RESET = "MASTERED_TRACK_DELETE_RESET"
